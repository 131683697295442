import styled from 'styled-components';
import {Colors} from 'globalStyle'; 
import { Box, Container } from '@material-ui/core';

export const Containers = styled(Container)`
  margin-top: 1em !important;
  padding-left: 60px;
  padding-right: 60px;
  display: flex !important;
  flex-direction: row !important; 
 
  div.file-input{
    width: 64px;
    height: 64px;

    img{
      width: 64px;
      height: 64px;
      object-fit: cover;
    }

    label{
      margin: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  div.file-container{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    span {
      margin-left: 24px;
    }
  }
`;

export const DataHeader = styled.div`
   h2 {
    margin: 0;
    padding: 0;
    font-size: 20px;
  }
`;

export const DataBody = styled.div`
  display: flex; 
  flex-direction: column; 
  div { 
    margin-bottom: 0.5em; 
  }
  
` ; 

export const DataInput = styled.div`
  display: flex;
  flex-direction: column; 

  input { 
    height: 40px; 
    margin-top: 1em; 
    background: ${Colors.bgGrey} ;
    border: ${Colors.bgGrey}; 
    border-radius: 4px!important;
    width: ${props => props.width ? props.width : "360px" }
  }
`;  

export const FormBody = styled.div`
  margin-top : 1em; 
  margin-bottom: 2em; 
`; 


export const TermsBox = styled(Box)`
  background-color : #ffffff;
  border-radius: 4px;
  padding: 2em;
  h3 { 
    border-bottom: 1px solid #E7E7ED; 
    padding-bottom: 1em;
  }
  textarea { 
    width: 100%;
    font-size: 18px; 
    font-family: 'Montserrat'; 
    resize: none; 
    border: none; 
    min-height: 178px;
  }
  textarea:focus { 
    outline: none;
  }

  textarea::-webkit-scrollbar {
    width: 4px;
  }

  textarea::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px #CDCED9;
  }

  textarea::-webkit-scrollbar-thumb {
    background-color: #CDCED9;
    outline: 1px solid darkgrey;
  }
`;

export const Contract = styled.div`
  margin-top: 17px; 
  margin-bottom: 55px; 



  display: flex; 
  align-items: center;
  flex-direction: row; 
  height: 107px!important;
  max-height: 107px!important; 

  
  background: #FFFFFF;
  border-radius: 2px;

  h4 { 
    margin: 0; 
    margin-left: 52px; 

    font-family: Montserrat;
    font-size: 16px;
    color: #000000;
    font-weight: 500;
  }
  input { 
    display: none; 
  }
  input, label { 
    margin-left: 86px;  
  }

  label { 
    height: 40px;
    width: 208px;
    border-radius: 4px;
    text-align: center;
    padding-top: 6px;

    background: #F1F2F5;


    border: 1px solid #CDCED9;
    box-sizing: border-box;
    border-radius: 4px;

    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;

  }

  svg { 
    width: 14px!important; 
    height: 14px!important; 
  }


`;