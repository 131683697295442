import React, { useState } from "react";
import { Grid, Checkbox, Link, Button } from "@material-ui/core";
import { Input, InputMask } from "components/inputs";
import { cardNumberMask, cardValidityMask } from "../../../../utils/masks";
import {
  Buttons,
  FormatedRadioInput,
  PaymentFormContainer,
  Terms,
} from "./style";
import api from "services/api";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

import Modal, { ModalBody, ModalHeader, ModalFooter } from "components/modal";

export default function PaymentForm({
  period,
  plan,
  modal,
  updatePaymentType,
  actual_payment_method,
  planType,
  number_users,
  forwardedRef,
  setCardNumberError,
}) {
  var valid = require("card-validator");
  const [form, setForm] = useState({
    payment_method: "credit_card",
    card_cvv: "",
    card_expiration_date: "",
    card_number: "",
  });
  const [disableButton, setDisableButton] = useState(false);
  const [terms, setTerms] = useState(false);
  const history = useHistory();

  const [modalAlert, setModalAlert] = useState(false);

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
    if(e.target.value === 'boleto' && setCardNumberError) setCardNumberError(false)
  };

  const handleChangeCardNumber = (e) => {
    if (setCardNumberError) {
      setCardNumberError(!valid.number(e.target.value).isValid);
    }
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const verifyCreateOrUpdate = async () => {
    const res = await api.get("user-plan");
    if (res.data) return res.data;

    const dynamicRes = await api.get("dynamic-user-plan");
    if (dynamicRes.data) return dynamicRes.data;

    return {};
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setDisableButton(true);
    const userPlan = await verifyCreateOrUpdate();
    const formToSend = {
      ...form,
      card_cvv: form.card_cvv.replace(/\D+/g, ""),
      card_expiration_date: form.card_expiration_date.replace(/\D+/g, ""),
      period,
      plan_id: plan,
    };

    const apiUrl = planType === "PF" ? "user-plan" : "dynamic-user-plan";

    if (planType === "PJ" || planType === "MULTIPLAN")
      formToSend.number_users = Number(number_users);

    if (updatePaymentType) {
      try {
        if (
          actual_payment_method === "boleto" &&
          form.payment_method === "boleto"
        ) {
          setDisableButton(false);
          return toast.error(
            "O tipo de pagamento da sua assinatura já é boleto"
          );
        }
        // const userPlan = await verifyCreateOrUpdate();
        await api.put(
          "/payment-gateway/payment-method/" + userPlan.gateway_signature_id,
          formToSend
        );
        toast.success("Forma de pagamento alterada com sucesso");
        setModalAlert(true);
      } catch (err) {
        toast.error(err.response.data.message);
        setDisableButton(false);
      }
    } else if (valid.number(form.card_number).isValid || form.payment_method === 'boleto') {
      try {
        if (userPlan._id)
          await api.put("/" + apiUrl, {
            ...formToSend,
            userPlan_id: userPlan._id,
          });
        else await api.post(apiUrl, formToSend);

        toast.success("Plano contratado com sucesso");
        setModalAlert(true);
      } catch (err) {
        toast.error(
          valid.number(form.card_number).isValid
            ? err.response.data.message
            : "Erro de Preenchimento"
        );
        setDisableButton(false);
      }
    } else {
      toast.error("Erro de Preenchimento");
      setDisableButton(false);
    }
  };

  return (
    <>
      <form ref={forwardedRef} onSubmit={handleSubmit}>
        <PaymentFormContainer container>
          <h5>Selecionar forma de pagamento</h5>
          <Grid item xs={12}>
            <FormatedRadioInput
              name="payment_method"
              row
              value={form.payment_method}
              onChange={handleChange}
              radioOptions={[
                { label: "Cartão de crédito", value: "credit_card" },
                { label: "Boleto", value: "boleto" },
              ]}
            />
          </Grid>
          {form.payment_method === "credit_card" && (
            <>
              <Grid item xs={12}>
                <h5>Detalhes do pagamento</h5>
              </Grid>
              <Grid item xs={12}>
                <InputMask
                  type="text"
                  name="card_number"
                  value={form.card_number}
                  mask={cardNumberMask}
                  label="Número do cartão"
                  inputMode="numeric"
                  width="100%"
                  validate={valid.number}
                  validateText="Número do Cartão Inválido"
                  onChange={handleChangeCardNumber}
                  onBlur={() => {}}
                />
                <h5 style={{ margin: "5px", fontSize: "10px" }}>
                  *Para alterar o número do cartão, clique no dígito desejado.
                </h5>
              </Grid>
              <Grid item xs={12}>
                <Input
                  required
                  name="card_owner_name"
                  label="Nome do titular"
                  width="100%"
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <Input
                  required
                  name="card_holder_name"
                  pattern="[a-zA-Z\s]*$"
                  title="É aceito apenas letras, não é permitido números ou caracteres especiais"
                  label="Nome impresso no cartão"
                  width="100%"
                  onChange={handleChange}
                />
                 <h5 style={{ margin: "5px", fontSize: "10px" }}>
                    *É aceito apenas letras, não é permitido números ou caracteres especiais
                  </h5>
              </Grid>
              <Grid item xs={12}>
                <Grid spacing={2} container>
                  <Grid item xs={6}>
                    <InputMask
                      required
                      type="text"
                      name="card_expiration_date"
                      inputMode="numeric"
                      label="Validade"
                      width="100%"
                      onChange={handleChange}
                      onBlur={() => {}}
                      value={form.card_expiration_date}
                      mask={cardValidityMask}
                      validate={() => form.card_expiration_date.length === 5}
                      validateText="A data de validade está fora do padrão"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <InputMask
                      required
                      type="text"
                      name="card_cvv"
                      inputMode="numeric"
                      label="Código de segurança"
                      width="100%"
                      value={form.card_cvv}
                      onChange={handleChange}
                      onBlur={() => {}}
                      mask={[/\d/, /\d/, /\d/]}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}

          {!modal && (
            <>
              <Grid item xs={12}>
                <Grid spacing={2} container>
                  <Grid item xs={12}>
                    <Terms>
                      <Checkbox
                        color="primary"
                        inputProps={{ "aria-label": "secondary checkbox" }}
                        style={{ backgroundColor: "transparent" }}
                        disableRipple
                        checked={terms}
                        onChange={() => setTerms(!terms)}
                      />
                      Li, compreendi e aceito os &nbsp;
                      <Link
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          window.open(
                            "https://virtuacare.com.br/termos-de-uso",
                            "_blank"
                          )
                        }
                        variant="body2"
                      >
                        termos de uso
                      </Link>
                    </Terms>
                  </Grid>
                </Grid>
              </Grid>
              <Buttons>
                <button
                  type="button"
                  onClick={() => history.goBack()}
                  //disabled={disableButton}
                >
                  Cancelar
                </button>
                <button disabled={!terms || disableButton}>Pagar</button>
              </Buttons>
            </>
          )}
        </PaymentFormContainer>
      </form>

      <Modal show={modalAlert}>
        <ModalHeader close={() => history.push("/beneficiarios")}>
          Aviso
        </ModalHeader>
        <ModalBody>
          <p>
            É necessário adicionar os beneficiários do plano para poder
            utilizar.
          </p>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={() => history.push("/beneficiarios")}
            variant="outlined"
          >
            OK
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}
