import React from 'react'
import { FileCardContainer, StyledCard } from './style'
import DownloadIcon from '../../assets/icons/Icon-download.png'
import TrashIcon from '../../assets/icons/Icon-trash.png'
import api from 'services/api'
import { bytesToSize } from 'utils/bytes-to-size'

export default function FileCard({title, extension, size=0, name, file=""}) {

  const downloadFile = async () => {
    try{
      const fileUrl = file.match(/https:\/\/storage.googleapis.com\/(.*)/)[1]
      const [bucket, fileName] = fileUrl.split('/');

      api.get(`doctor/download/${bucket}/${fileName}`, {
        responseType: 'blob'
      }).then(({ data }) => {
        const type = data.type.split('/') 
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', name+'.'+type[1]); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
    }catch(err){
      console.log(err)
    }
  }

  return (
    <FileCardContainer>
      <h6>{title}</h6>
      <StyledCard elevation={0}>
        <div className="file-card-desc">
          <small>{name}</small>
          <p>{`${extension.toUpperCase()} • ${bytesToSize(size,1)}`}</p>
        </div>
        <div className="file-card-icons">
          <img src={TrashIcon} alt="Delete" />
          <img src={DownloadIcon} onClick={downloadFile} alt="Download"  />
        </div>
      </StyledCard>
    </FileCardContainer>
  )
}
