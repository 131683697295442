import styled from "styled-components";
import { Colors } from "globalStyle";
import { Box } from "@material-ui/core";

export const Containers = styled.div`
  margin-top: 4em !important;
  padding-left: 60px;
  padding-right: 60px;
  display: flex !important;
  flex-direction: row !important;
  max-width: 100%;

  div.file-input {
    width: 64px;
    height: 64px;

    img {
      width: 64px;
      height: 64px;
    }

    label {
      margin: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
`;

export const DataHeader = styled.div`
  h2 {
    margin: 0;
    padding: 0;
    font-size: 20px;
  }
`;

export const DataBody = styled.div`
  display: flex;
  flex-direction: column;
  div {
    margin-bottom: 0.5em;
  }
`;

export const DataInput = styled.div`
  display: flex;
  flex-direction: column;

  input {
    height: 40px;
    margin-top: 1em;
    background: ${Colors.bgGrey};
    border: ${Colors.bgGrey};
    border-radius: 4px !important;
    width: ${(props) => (props.width ? props.width : "360px")};
  }
`;

export const FormBody = styled.div`
  margin-top: 1em;
  margin-bottom: 2em;
`;
export const ContractBox = styled(Box)`
  margin-top: 1em;
  display: flex;
  align-items: center;

  p {
    padding: 0;
    margin: 0;
  }

  input {
    padding: 0;
    margin-left: 15%;
  }
`;
