import React, { useState, useEffect } from 'react';

import { Grid, Tooltip } from '@material-ui/core';

import { Bars, Legends } from './style';

export default function ProgressBar(props) {
  const { data } = props;
  const [soma, setSoma] = useState(0);
  const maxWidth = 397; //397px;
  const [first, setFirst] = useState(''); 
  const [last, setLast] = useState(''); 


  useEffect(() => {
    function toHundred() {
      let soma = 0;
      for (var i = 0; i < data.length; i++) {
        soma += data[i].value;
      }
      setSoma(soma);
    }

    function firstLast() { 
      setFirst(data[0].name);
      setLast(data[data.length -1 ].name); 
    }
    firstLast(); 
    toHundred();
  }, [data]);

  const percentWidth = (value) => {
    let porcentagem = value / soma;
    let width = maxWidth * porcentagem;
    return width;
  };

  return (
    <Grid container direction='row'>
      {data.map((bar) => (
        <Grid item key={bar.name}>
          <Tooltip title={bar.value} placement="top" >
            <Bars
              color={bar.color}
              width={`${percentWidth(bar.value)}px`}
              first={first === bar.name ?? false}
              last={last === bar.name ?? false}
            />
          </Tooltip>
          <Legends width={`${percentWidth(bar.value)}px`}>{bar.name}</Legends>
        </Grid>
      ))}
    </Grid>
  );
}
