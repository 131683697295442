import React from "react";

import {
  Papers,
  PaperMd,
  PaperLg,
  PaperXl,
  PaperXlg,
  PaperHeader,
  PaperNumber,
  PlanPaper,
  Container,
  DoubleHeader,
  PaperStatus,
  StyledPaymentCard,
} from "./style";

import { Grid } from "@material-ui/core";
import CreateIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";
/**
 *
 * @param {md} md medium size
 * @param {lg} lg large size
 * @param {xl} xl extra large size
 */
export function Card(props) {
  const { children, status, md, lg, xl, xlg, ...rest } = props;
  if (props.md)
    return (
      <PaperMd elevation={0} {...rest}>
        <Container> {children} </Container>
      </PaperMd>
    );

  if (props.lg)
    return (
      <PaperLg elevation={0} {...rest}>
        <Container>{children} </Container>
      </PaperLg>
    );

  if (props.xl)
    return (
      <PaperXl elevation={0} {...rest}>
        <Container>{children}</Container>
      </PaperXl>
    );

  if (props.xlg)
    return (
      <PaperXlg elevation={0} {...rest}>
        <Container>{children}</Container>
      </PaperXlg>
    );

  if (props.status)
    return (
      <PaperStatus elevation={0} {...rest}>
        <Container>{children}</Container>
      </PaperStatus>
    );

  return (
    <Papers elevation={0} {...rest}>
      <Container>{children} </Container>
    </Papers>
  );
}
/**
 *
 * @param {boolean} pen editable button
 * @param {label} label tiny title
 * @param {children} children Title
 * @param {double} double boolean, double header(Dashboard Ex)
 */
export function CardHeader(props) {
  const { pen, label, children, double, label2, trash } = props;
  if (double) {
    return (
      <DoubleHeader>
        <span>{label}</span>
        <span>{label2}</span>
      </DoubleHeader>
    );
  }
  return (
    <PaperHeader>
      {pen || label ? (
        <div
          style={{ display: "flex", justifyContent: "space-between" }}
          className="headerLabel"
        >
          <p> {label} </p>
          {pen && trash && (
            <div>
              <button onClick={pen} style={{ cursor: "pointer" }}>
                <CreateIcon />
              </button>
              <button onClick={trash} style={{ cursor: "pointer" }}>
                <DeleteIcon />
              </button>
            </div>
          )}
          {/* {pen && (
            <button onClick={pen}>
              <CreateIcon />
            </button>
          )} */}
        </div>
      ) : null}
      <h5> {children} </h5>
    </PaperHeader>
  );
}

/**
 *
 * @param {color} props Global color name
 */
export function CardNumber(props) {
  const { children, color, h3, ...rest } = props;
  if (h3)
    return (
      <PaperNumber color={props.color} {...rest}>
        {" "}
        <h3> {props.children} </h3>
      </PaperNumber>
    );

  return (
    <PaperNumber color={props.color} {...rest}>
      {" "}
      <p> {props.children} </p>{" "}
    </PaperNumber>
  );
}
/**
 *
 * @param {title} title Titulo do card
 * @param {value} value Valor
 * @param {valueTitle} valueTitle Titulo do valor
 * @param {quantity} quantity Quantidade
 * @param {quantityTitle} quantityTitle Titulo da quantidade
 */
export function PlanCard(props) {
  const { value, valueTitle, quantity, quantityTitle } = props;
  return (
    <PlanPaper>
      {value || quantity ? (
        <Grid container>
          {value && (
            <Grid item xs={6}>
              <h4>{valueTitle}</h4>
              <CardNumber color="darkGreen"> {value} </CardNumber>
            </Grid>
          )}
          {quantity && (
            <Grid item xs={6}>
              {quantityTitle && <h4> {quantityTitle} </h4>}
              <CardNumber color="darkGrey"> {quantity} </CardNumber>
            </Grid>
          )}
        </Grid>
      ) : null}
    </PlanPaper>
  );
}

export function PaymentCard({ status = "pago", date = "Setembro/2020" }) {
  return (
    <StyledPaymentCard status={status} elevation={0}>
      <div className="payment-card-desc">
        <small>Mês referência</small>
        <p style={{ alignSelf: "flex-start" }}>{date}</p>
      </div>
      <p className="payment-card-status">{status}</p>
    </StyledPaymentCard>
  );
}
