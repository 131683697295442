import React from "react";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import SettingsIcon from "@material-ui/icons/Settings";
import {
  Container,
  Button,
  Popper,
  Grow,
  ClickAwayListener,
  MenuItem,
  Paper,
  MenuList,
} from "@material-ui/core";

import { StatusBar, User, UserData, Status, Optionals } from "./style";
import { formatPhone, statusAlias } from "helper";

import history from "services/history";

export default function UserStatusBar(props) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);
  return (
    <>
      <StatusBar>
        <Container maxWidth="xl">
          <div className="align-center">
            <Button onClick={() => history.goBack()}>
              <ArrowBackIosIcon fontSize="small" />
            </Button>
          </div>
          <User>
            <img
              src={props.profile_pic ? props.profile_pic : "/user.png"}
              alt="aa"
            />
            <UserData>
              <h5> {props.name}</h5>
              <span> {props.tel ? formatPhone(props.tel) : ""} </span>
            </UserData>
            {props.memberDate ? (
              <UserData>
                <h6> Membro desde</h6>
                <span> {props.memberDate} </span>
              </UserData>
            ) : null}
            {props.termOfContract ? (
              <UserData>
                <h6> Vigência do Contrato</h6>
                <span>
                  {new Date(props.termOfContract).toLocaleDateString("pt-br")}
                </span>
              </UserData>
            ) : null}
          </User>
          {props.status ? (
            <div className="align-center">
              <Status status={props.status}>
                <span>{statusAlias(props.status)}</span>
              </Status>
            </div>
          ) : null}

          <Optionals>
            {props.dashboard ? (
              <div>
                <Button variant="outlined"> Dashboard </Button>
              </div>
            ) : null}
            {props.option ? (
              <div>
                <Button
                  ref={anchorRef}
                  aria-controls={open ? "menu-list-grow" : undefined}
                  aria-haspopup="true"
                  onClick={handleToggle}
                >
                  <SettingsIcon />
                </Button>
                <Popper
                  open={open}
                  anchorEl={anchorRef.current}
                  role={undefined}
                  transition
                  disablePortal
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === "bottom"
                            ? "center top"
                            : "center bottom",
                      }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={handleClose}>
                          <MenuList
                            autoFocusItem={open}
                            id="menu-list-grow"
                            onKeyDown={handleListKeyDown}
                          >
                            {props.actions &&
                              props.actions.map((action) => (
                                <MenuItem
                                  key={action.name}
                                  onClick={action.action}
                                >
                                  {action.name}
                                </MenuItem>
                              ))}
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </div>
            ) : null}
          </Optionals>
        </Container>
      </StatusBar>
    </>
  );
}
