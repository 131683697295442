import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Grid, Button } from "@material-ui/core";
import jwt_decode from "jwt-decode";

import {
  InputGroup,
  InputGroupHeader,
  Form,
  PasswordInput,
} from "components/inputs";
import Header from "components/header";
import { Containers } from "./style";

import api from "services/api";
import { toast } from "react-toastify";

export default function ForgotPassword() {
  const { token } = useParams();
  const history = useHistory();
  const [user, setUser] = useState({
    password: "",
    passwordConfirmation: "",
    email: "",
  });

  let email = "";

  try {
    email = jwt_decode(token).email;
  } catch (err) {
    history.push("/login");
  }

  const handleChange = (event) => {
    setUser({ ...user, [event.target.name]: event.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const regex = new RegExp(
      "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[.,#?!@$%^&*-]).{8,}$"
    );
    if (!regex.test(user.password)) {
      return toast.error("Senha fora do padrão");
    }

    if (user.password !== user.passwordConfirmation) {
      return toast.error("A confirmação é diferente da senha");
    }

    try {
      await api.post("/user/change-password", {
        password: user.password,
        token: token,
        email: email,
      });
      toast.success("Senha alterada com sucesso!");
      history.push("/login");
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const verificationText = () => {
    if (user.password.length >= 8)
      return user.password === user.passwordConfirmation;
  };

  return (
    <>
      <Header onlyLogo />
      <Containers maxWidth="xl">
        <Form width="100%" onSubmit={handleSubmit}>
          <Grid container justifyContent="center" alignItems="center">
            <Grid
              container
              justifyContent="center"
              direction="column"
              item
              xs={12}
              sm={4}
            >
              <h2>Recuperação de senha</h2>
              <small>
                Deve conter ao menos uma letra maiúscula, uma letra minúscula,
                um número e um caractere especial
              </small>
              <InputGroup>
                <InputGroupHeader>
                  <h2>Senha</h2>
                </InputGroupHeader>
                <PasswordInput
                  showPattern
                  defaultValue={user.password}
                  name="password"
                  type="password"
                  placeholder="Nova senha"
                  label="Nova senha"
                  onChange={handleChange}
                  verificationText={verificationText}
                  required
                />
                <PasswordInput
                  defaultValue={user.passwordConfirmation}
                  name="passwordConfirmation"
                  type="password"
                  required
                  placeholder="Confirmação de senha"
                  label="Confirmação de senha"
                  onChange={handleChange}
                  verificationText={verificationText}
                />
              </InputGroup>
              <Grid item>
                <Button type="submit" variant="outlined">
                  Finalizar
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Form>
      </Containers>
    </>
  );
}
