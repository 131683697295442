import styled from 'styled-components';
import { Grid } from '@material-ui/core';

export const Options = styled(Grid)`
  margin-top: 2em;
  margin-bottom: 2em;
  justify-content: space-between;

  button {
    text-transform: none;
    font-size: 16px;
  }
`;
