import { cpfValidator } from "./input-validators";
import * as moment from 'moment-timezone'

const defaultBeneficiaryValidator = () => {
  return {
    name: {
      error: false,
      helperText: 'Campo Obrigatório',
    },
    cpf: {
      error: false,
      helperText: 'Campo Obrigatório',
    },
    email: {
      error: false,
      helperText: 'Campo Obrigatório',
    },
    telephone: {
      error: false,
      helperText: 'Campo Obrigatório',
    },
    birth_date: {
      error: false,
      helperText: 'Campo Obrigatório',
    },
    genre: {
      error: false,
      helperText: 'Campo Obrigatório',
    },
  };
};
export default defaultBeneficiaryValidator;

export const inputValidations = (form) => {
  const error = defaultBeneficiaryValidator();
  let haveError = false;

  if (!form?.name || form?.name === '') {
    error.name = { error: true, helperText: 'Campo Obrigatório' };
    haveError = true;
  }

  if (!form?.cpf || form?.cpf === '') {
    error.cpf = { error: true, helperText: 'Campo Obrigatório' };
    haveError = true;
  }

  if (!cpfValidator(form?.cpf)) {
    error.cpf = { error: true, helperText: 'CPF inválido' };
    haveError = true;
  }

  if (!form?.email || form?.email === '') {
    error.email = { error: true, helperText: 'Campo Obrigatório' };
    haveError = true;
  }

  //if (!(new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(form.email))) {
  if (!(new RegExp(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/).test(form?.email))) {
    error.email = { error: true, helperText: 'Email inválido' };
    haveError = true;
  }

  if (!form?.telephone || form?.telephone === '') {
    error.telephone = { error: true, helperText: 'Campo Obrigatório' };
    haveError = true;
  }

  if (form?.telephone.length !== 15) {
    error.telephone = { error: true, helperText: 'Número inválido' };
    haveError = true;
  }

  if (!form?.birth_date || form?.birth_date === '') {
    error.birth_date = { error: true, helperText: 'Campo Obrigatório' };
    haveError = true;
  }

  if (!moment(form?.birth_date, "DD/MM/YYYY", true).isValid()) {
    error.birth_date = { error: true, helperText: 'Data inválida' };
    haveError = true;
  }

  if (!form?.genre || form?.genre === '') {
    error.genre = { error: true, helperText: 'Campo Obrigatório' };
    haveError = true;
  }

  if (haveError) return error;

  return true;
};
