import styled from 'styled-components';

export const Values = styled.div`
  position: absolute; 
  top: -30px; 

`;

export const Bars = styled.div`
  position: relative; 
  content: '';
  background-color: ${(props) =>
    props.color ? barColors[props.color] : 'none'};
  height: 24px;
  ${(props) => (props.first ? 'border-radius : 8px 0px 0px 8px;' : '')};
  ${(props) => (props.last ? 'border-radius : 0px 8px 8px 0px;' : '')};
  width: ${(props) => props.width};
`;

export const Legends = styled.div`
  max-width: ${(props) => props.width};
  text-align: center;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 150%;
`;

const barColors = {
  red: '#FF5F5F',
  yellow: '#FEC65A',
  green: '#5ACA75',
  blue: '#4B9BFF',
};
