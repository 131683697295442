import { Paper } from '@material-ui/core'
import { Colors } from 'globalStyle'
import styled from 'styled-components'

export const OtherPlans = styled.div`

  h4 {
    margin-bottom: 0;
    padding: 0;
  }

  span {
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    margin: 0;
    padding: 0;
    color: ${Colors.darkGrey};
    margin-top: 8px;
    display: inline-block;
  }
`

export const NewPlans = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 64px 0;

  h4 {
    margin: 0;
    padding: 0;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 125%;
    display: flex;
    align-items: center;
    color: #272833;
  }

  button {
    border:none;
    color: white;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    background: #3089C3;
    border-radius: 4px;
    min-width: 213px;
    min-height: 45px;
    outline: none;
    cursor: pointer;
  }
`

export const ClientOtherPlansCard = styled(Paper)`
  width: 100%;
  height: 200px;
  border: 1px solid #f1f2f5;
  display: flex;
  justify-content: space-between;
  padding: 20px 20px;
  h6 {
    margin: 0;
    padding: 0;
    font-weight: normal;
    font-size: 12px;
    line-height: 150%;
    color: ${Colors.darkGrey}
  }

  p {
    margin:0;
    padding: 0;
    margin-top:4px;
    font-weight: 600;
    font-size: 20px;
    line-height: 150%;
  }

  .card-item-top{
    margin:auto;
  }
`

export const CardInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`