import React, { useEffect, useState } from "react";
import Main from "components/main";

import { Grid, Button, Hidden } from "@material-ui/core";

import { Card, CardHeader, CardNumber } from "components/card";
import { Options } from "./style";
import Search from "components/search";
import api from "services/api";
import BeneficiariesTable from "components/client/beneficiary/table";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { getBeneficiariesLength } from "helper";

export default function Beneficiaries() {
  const [beneficiaries, setBeneficiaries] = useState([]);
  const [userPlan, setUserPlan] = useState({});
  const [plan, setPlan] = useState({});
  const [client, setClient] = useState({});
  const [data, setData] = useState([]);
  const history = useHistory();

  const location = useLocation();

  const getBeneficiaries = async () => {
    const response = await api.get("beneficiary/owner/");
    setBeneficiaries(response.data);
    setData(response.data);
  };

  useEffect(() => {
    const getBeneficiaries = async () => {
      const response = await api.get("beneficiary/owner/");
      setBeneficiaries(response.data);
      setData(response.data);
    };

    const getUserPlan = async () => {
      const response = await api.get("user-plan");
      if (response.data) {
        setUserPlan(response.data);
        const plan = await api.get("plan/" + response.data.plan_id);
        setPlan(plan.data);
      }

      const dynamicResponse = await api.get("dynamic-user-plan");
      if (dynamicResponse.data) {
        setUserPlan(dynamicResponse.data);
        const plan = await api.get(
          "dynamic-plan/" + dynamicResponse.data.dynamic_plan_id
        );
        setPlan(plan.data);
      }
    };

    const getClient = async () => {
      const response = await api.get("/user/clients/self");
      setClient(response.data);
    };

    getUserPlan();
    getBeneficiaries();
    getClient();
  }, []);

  const handleOpen = () => {
    if (userPlan) {
      const length =
        userPlan.number_users - getBeneficiariesLength(beneficiaries);
      if (length === 0 && plan.type === "PF")
        toast.error("Seu plano já atingiu o máximo de beneficiários");
      else
        history.push(
          plan.type === "PF"
            ? "/beneficiarios/add"
            : "/beneficiarios/add-multiple"
        );
    } else {
      toast.error("Contrate um plano para adicionar beneficiários");
    }
  };

  const handleSearch = (e) => {
    const { value } = e.target;

    let search = beneficiaries.filter(
      (obj) => obj.cpf === value || obj.cpf.includes(value)
    );
    if (search.length === 0)
      search = beneficiaries.filter((obj) => obj.name.includes(value));

    setData(search);
  };

  const removeBeneficiary = async (id) => {
    try {
      await api.delete("/beneficiary/" + id);
      await getBeneficiaries();
      toast.success("Beneficiário excluído com sucesso");
    } catch (err) {
      toast.error("Ocorreu um erro ao excluir o beneficiário");
    }
  };

  // const getBeneficiariesLength = (beneficiaryList = []) => {
  //   const holders = beneficiaryList.length;
  //   const dependents = beneficiaryList.reduce(
  //     (a, b) => a + (b.dependents ? b.dependents.length : 0),
  //     0
  //   );

  //   return holders + dependents;
  // };

  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  });

  let isMobile = width <= 768;

  return (
    <Main maxWidth="xl">
      {client.type === "PJ" ? (
        <h4>
          {location.state && location.state.secondStep
            ? isMobile
              ? "PASSO 2: Clique em Plano no menu superior para escolher seu plano Empresarial."
              : "PASSO 2: Clique em PLANOS, no menu superior e siga o fluxo de compra. Nas futuras inclusões, NÃO há mais necessidade desta ação."
            : 'PASSO 1: Clique em "Novo beneficiário" para cadastrar os usuários Titulares e/ou seus dependentes.'}
        </h4>
      ) : (
        <h4>
          PASSO 4: Clique no botão ‘Novo beneficiário’ para incluir os usuários
        </h4>
      )}
      <Grid container className="mt-3">
        <Card>
          <CardHeader label="Beneficiários do plano" />
          <CardNumber color="darkGrey">{userPlan.number_users || 0}</CardNumber>
        </Card>
        <Card>
          <CardHeader label="Beneficiários cadastrados" />
          <CardNumber color="blue">
            {getBeneficiariesLength(beneficiaries)}
          </CardNumber>
        </Card>
        <Card>
          <CardHeader label="Vagas disponíveis" />
          <CardNumber color="green">
            {isNaN(
              userPlan.number_users -
                getBeneficiariesLength(beneficiaries, true)
            )
              ? 0
              : userPlan.number_users -
                getBeneficiariesLength(beneficiaries, true)}
          </CardNumber>
        </Card>
      </Grid>
      <Options container>
        <Search onChange={handleSearch} label="Pesquise um beneficiário" />
        {(userPlan.number_users - getBeneficiariesLength(beneficiaries) > 0 &&
          plan.type === "PF") ||
        client.type === "PJ" ||
        plan.type === "MULTIPLAN" ? (
          <Button
            variant="outlined"
            style={{ backgroundColor: "#3089C3", color: "white" }}
            onClick={handleOpen}
          >
            <Hidden mdUp>Novo beneficiário</Hidden>
            <Hidden smDown>Novo beneficiário</Hidden>
          </Button>
        ) : null}
      </Options>
      <BeneficiariesTable
        options
        beneficiaries={data}
        addDependent={
          (userPlan.number_users - getBeneficiariesLength(beneficiaries) > 0 &&
            plan.type === "PF") ||
          plan.type === "PJ" ||
          client.type === "PJ" ||
          plan.type === "MULTIPLAN"
        }
        dynamicPlan={
          client.type === "PJ" ||
          plan.type === "PJ" ||
          plan.type === "MULTIPLAN"
        }
        removeBeneficiary={
          client.type !== "PF" && client.type ? removeBeneficiary : undefined
        }
      />
    </Main>
  );
}
