import { Colors } from 'globalStyle'
import styled from 'styled-components'

export const StepContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  input: {
    width:100%;
  }
  div.file-input{
    width: 20vw;
    height: 20vw;
    max-width: 200px;
    max-height: 200px;

    img{
      width: 20vw;
      height: 20vw;
      max-width: 200px;
      max-height: 200px;
      object-fit: cover;
    }

    label{
      margin: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  div.file-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    span {
      margin: 0;
    }
  }
  
`

export const HelperText = styled.span`
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  color: #DA1414;
  align-self: flex-start;
`

export const Button = styled.button`
  height: 45px;
  width: 100%;
  background-color: ${Colors.blue};
  outline: none;
  margin: 0;
  border: none;
  color: white;
  font-size: 16px;
  font-weight: 600;
  justify-self: flex-end;
  border-radius: 22.5px;
  margin-top: 50%;
  cursor: pointer;
`

export const DesktopButton = styled.button`
  height: 45px;
  background: ${props=>props.back ? "#FFFFFF": Colors.blue};
  border: ${props=>props.back ? "1px solid #6B6C7E" : "1px solid "+Colors.blue};
  color: ${props=>props.back ? Colors.darkGrey : "#FFFFFF"};
  box-sizing: border-box;
  border-radius: 4px;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  width: 49%;
  cursor: pointer;
`

export const DesktopButtons = styled.div`
  margin-top: 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`