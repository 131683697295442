import React from 'react'
import Slider from 'react-slick'



export const FixedCarousel = React.forwardRef(({children, sliderState, setSliderState}, ref) => {
  let settings = {
    infinite: false,
    speed: 500,
    arrows:false,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    adaptiveWidth: true,
    draggable: false,
    beforeChange: (current, next) => setSliderState({...sliderState, activeSlide: next }),
  };
    return(
    <Slider ref={ref} {...settings}>
      {children}
    </Slider>
    )
})