import { PaymentCard } from 'components/card'
import { fullMonthAndYear } from 'helper'
import React from 'react'
import { ShowPayment } from './style'

export default function ClientPayment({payments, openModal}) {

  const formatDateText = date => {
    const formatedText = fullMonthAndYear(date).split('/')[0]
    return formatedText.charAt(0).toUpperCase() + formatedText.slice(1)
  }

  const StatusLabel = (status) => ({
    'paid': "Pago",
    'waiting_payment': "Pendente",
    'chargedback': "Estornado",
    'canceled': "Cancelado",
    'default': "Não Pago"
  }[status || 'default'])
  
  return (
    <ShowPayment>
      {
        payments.slice(0,2).map(payment=>(
          <PaymentCard key={payment.createdAt} date={payment.payment_method==="boleto" ? formatDateText(payment.boleto_expiration_date) : formatDateText(payment.createdAt)} status={StatusLabel(payment.status)}/>
        ))
      }
      <p onClick={openModal}>Ver todos</p>
    </ShowPayment>
  )
}
