import React from 'react'
import { useHistory } from 'react-router-dom'

import { StatusBar, Patient, PatientData, PatientDetails } from './style'
import { Button, Container } from '@material-ui/core'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import { formatPhone } from 'helper'


export default function TriageHeader({name, tel, age, gender, id, makePreference}) {

  const history = useHistory()

  return(
    <StatusBar>
        <Container maxWidth="xl" style={{display:"flex", alignItems:"center"}}>
          <div className='align-center'> 
            <Button onClick={() => history.push('/fila')}>
              <ArrowBackIosIcon fontSize='small' />
            </Button>
          </div>
          <Patient>
            <img
              src={'/user.png'}
              alt='Profile Pic'
            />
            <PatientData>
              <h5> {name}</h5>
              <span> {tel ? formatPhone(tel) : ""} </span>
            </PatientData>
          </Patient>
          <PatientDetails>
            <p>Idade: <span>{age}</span></p>
            <p>Sexo: <span>{gender}</span></p>
          </PatientDetails>
          
          <div style={{marginLeft:"auto"}}>
            <Button
              style={{color:"#3089C3", borderColor:"#3089C3"}}
              variant='outlined'
              onClick={()=>makePreference()}
            >
                Definir como prioridade
            </Button>
          </div>

        </Container>
      </StatusBar>
  )
}