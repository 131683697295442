import storage from "redux-persist/lib/storage";

import { persistReducer } from "redux-persist";
import autoMergeLevel2 from "redux-persist/es/stateReconciler/autoMergeLevel2";

export default (reducers) => {
  const persistedReducer = persistReducer(
    {
      key: "app",
      storage,
      whitelist: ["auth", "user"],
      stateReconciler: autoMergeLevel2,
    },
    reducers
  );
  return persistedReducer;
};
