export const cpfMask = [
  /\d/,/\d/,/\d/,'.',/\d/,/\d/,/\d/,'.',/\d/,/\d/,/\d/,'-',/\d/,/\d/
]

export const cnpjMask = [
  /\d/,/\d/,'.',/\d/,/\d/,/\d/,'.',/\d/,/\d/,/\d/,'/',/\d/,/\d/,/\d/,/\d/,'-',/\d/,/\d/
]

export const phoneMask = [
  '(',/\d/,/\d/,')',' ',/\d/,/\d/,/\d/,/\d/,/\d/,'-',/\d/,/\d/,/\d/,/\d/
]

export const cepMask = [
  /\d/,/\d/,/\d/,/\d/,/\d/,'-',/\d/,/\d/,/\d/
]

export const cardValidityMask = [
  /\d/,/\d/,'/',/\d/,/\d/
]

export const cardNumberMask = [
  /\d/,/\d/,/\d/,/\d/," ",/\d/,/\d/,/\d/,/\d/," ",/\d/,/\d/,/\d/,/\d/," ",/\d/,/\d/,/\d/,/\d/
]

export const dateMask = [
  /\d/,/\d/,"/",/\d/,/\d/,"/",/\d/,/\d/,/\d/,/\d/
]