import styled from "styled-components";
import { Paper } from "@material-ui/core";
import { Colors } from "globalStyle";

export const Container = styled.div`
  padding-left: 24px;
  padding-right: 13px;
`;

export const Papers = styled(Paper)`
  width: 213px;
  height: 120px;
  margin-right: 1em;
  border-radius: 10px;
  border: 1px solid #cdced9;
  margin-bottom: ${(props) => props.mb ?? "none"};
`;

export const PaperMd = styled(Paper)`
  width: 213px;
  height: 120px;
  margin-right: 1em;
  border-radius: 10px;
  border: 1px solid #cdced9;
  margin-bottom: ${(props) => props.mb ?? "none"};
`;

export const PaperLg = styled(Paper)`
  width: 447px;
  min-height: 103px;
  margin-right: 1em;
  border-radius: 10px;
  border: 1px solid #cdced9;
  margin-bottom: ${(props) => props.mb ?? "none"};
`;

export const PaperXl = styled(Paper)`
  width: 100%;
  max-width: 913px;
  min-height: 230px;
  margin-right: 1em;
  border-radius: 10px;
  border: 1px solid #cdced9;
  margin-bottom: ${(props) => props.mb ?? "none"};
`;

export const PaperXlg = styled(Paper)`
  width: 446px;
  height: 260px;
  margin-right: 1em;
  border-radius: 10px;
  border: 1px solid #cdced9;
  margin-bottom: ${(props) => props.mb ?? "none"};
`;

export const PaperStatus = styled(Paper)`
  width: 445px;
  height: 117px;
  margin-right: 1em;
  border-radius: 10px;
  border: 1px solid #cdced9;
  margin-bottom: ${(props) => props.mb ?? "none"};
`;

export const PaperHeader = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  position: relative;
  padding-top: 20px;
  p {
    margin: 0;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 150%;
    color: ${Colors.darkGrey};
    text-transform: capitalize;
  }

  h5 {
    font-family: "Nunito";
    font-style: normal;
    /* font-weight: 600; */
    font-size: 20px;
    /* line-height: 150%; */
    width: 100%;
    color: #2e5aac;
    margin-top: 4px;
    margin-bottom: 14px;
  }
  button {
    background: none;
    border: none;
    margin-left: auto;
  }
  svg {
    height: 15px;
  }
  .headerLabel {
    width: 100%;
    display: flex;
    font-size: 12px;
  }
`;
export const PaperNumber = styled.div`
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 150%;
  margin-left: 24px;
  color: ${(props) => Colors[props.color]};
  font-size: ${(props) => props.fontSize ?? "none"};
  margin: 0;
`;

export const PlanPaper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  h3 {
    color: #2e5aac;
    font-family: "Nunito";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 150%;
    text-transform: capitalize;
    height: 100%;
    padding: 0;
    margin: 0;
  }
  h4 {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 150%;
    color: ${Colors.dark};
    margin: 0;
    padding: 0;
    margin: 0;
  }
  p {
    margin-top: 4px;
    margin-bottom: 38px;
  }
`;

export const PlanPaperLg = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  h3 {
    color: #2e5aac;
    font-family: "Nunito";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 150%;
    text-transform: capitalize;
    height: 100%;
    padding: 0;
    margin: 0;
  }
  h4 {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 150%;
    color: ${Colors.dark};
    margin: 0;
    padding: 0;
    margin: 0;
  }
  p {
    margin-top: 4px;
    margin-bottom: 38px;
  }
`;

export const DoubleHeader = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  position: relative;
  padding-top: 20px;
  justify-content: space-between;
  span {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 150%;
    color: #616161;
  }
`;

export const StyledPaymentCard = styled(Paper)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  max-width: 256px;
  background: #ffffff;
  border: 1px solid #d8dde2 !important;
  padding: 12px;
  box-sizing: border-box !important;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.05) !important;
  border-radius: 4px;
  & + & {
    margin-top: 10px;
  }
  p {
    align-self: flex-start;
  }
  div.payment-card-desc {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    small {
      margin: 0;
      padding: 0;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      justify-self: flex-start;
      align-self: flex-start;
      color: #a7a9bc;
      margin-bottom: 3px;
    }
    p {
      margin: 0;
      padding: 0;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #000000;
      text-transform: capitalize;
    }
  }
  p.payment-card-status {
    font-weight: normal;
    font-size: 10px;
    line-height: 150%;
    text-transform: uppercase;
    background-color: ${(props) =>
      props.status === "Pendente" ? "#FFF4EC" : "#EDF9F0"};
    color: ${(props) => (props.status === "Pendente" ? "#B95000" : "#287D3C")};
    padding: 5px 12px;
    border-radius: 12.5px;
    width: 40%;
    text-align: center;
    display: block;
  }
`;
