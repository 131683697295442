import React from "react";

import { Container, ModalForm, Body, Header, Footer } from "./style";

import CloseIcon from "@material-ui/icons/Close";
import { Button } from "@material-ui/core";

/**
 * Styled Form
 * @param {show} props.show deixa visivel.
 * @param {...rest} props recebe todos os parâmetros que um form receberia.
 */
export default function Modal(props) {
  const { show, children, ...rest } = props;
  return (
    <Container show={show}>
      <ModalForm {...rest}> {children} </ModalForm>
    </Container>
  );
}
/**
 * Corpo do modal, recebe os inputs.
 */
export function ModalBody(props) {
  return <Body>{props.children}</Body>;
}
/**
 * Header do modal, inclui o botão de fechar o modal
 */
export function ModalHeader(props) {
  const { close, children } = props;
  return (
    <Header>
      <h2> {children} </h2>
      {close ? (
        <button type="button" onClick={close} style={{ cursor: "pointer" }}>
          <CloseIcon />
        </button>
      ) : null}
    </Header>
  );
}
/**
 * Footer do modal
 * @param {cancel} props true of false
 * @param {next} props string
 */
export function ModalFooter(props) {
  const { cancel, next, disabled, children } = props;
  return (
    <Footer>
      {cancel ? (
        <Button variant="outlined" onClick={cancel}>
          {" "}
          Cancelar{" "}
        </Button>
      ) : null}
      {next ? (
        <Button
          disabled={disabled}
          variant="outlined"
          color="primary"
          type="submit"
        >
          {next}
        </Button>
      ) : null}
      {children ? children : null}
    </Footer>
  );
}
