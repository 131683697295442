import { ModalBody, ModalFooter, ModalHeader } from 'components/modal'
import React, { useEffect, useState } from 'react'
import api from 'services/api'
import { PlanDetails, PlanModal, PlanOverview } from './style'

export default function PlanDetailsModal({
  open,
  handleClose,
  name,
  mensality,
  beneficiaries,
  onSubmit,
  period,
  plan
}){

  const [planDiscount, setPlanDiscount] = useState(0)

  useEffect(()=> {
    const getDiscount = async () => {
      if(plan) {
        let planModel = null
        planModel = await api.get('/plan/'+plan)
        if(!planModel.data) {
          planModel = await api.get('/dynamic-plan/'+plan)
        }
        setPlanDiscount(
          period === "ANUAL" ? planModel.data.anuality : 
          period === "SEMESTRAL" ? planModel.data.semestral :
          period === "TRIMESTRAL" ? planModel.data.trimestral :
          0
        )
      }
    }
    getDiscount()

  },[period, plan])

  return(
    <PlanModal show={open} onSubmit={onSubmit}>
      <ModalHeader close={handleClose}> Detalhes do plano </ModalHeader>
      <ModalBody>
        <PlanOverview>
          <div>
            <h6>Nome do plano</h6>
            <p>{name}</p>
          </div>
          <div>
          <h6>Valor mensal</h6>
            <p>R$ {mensality.toFixed(2).replace('.', ',')}</p>
          </div>
        </PlanOverview>
        <h6 style={{margin:"auto"}}>outras informações</h6>
        <PlanDetails>
          <div>
            <p>Quantidade de beneficiários</p>
            <span>{beneficiaries}</span>
          </div>
          <div>
            <p>Descontos</p>
            <span>{planDiscount}%</span>
          </div>
        </PlanDetails>
      </ModalBody>
      <ModalFooter cancel={handleClose} next='Comparar' />
    </PlanModal>
  )
}