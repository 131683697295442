import React, { useState } from 'react';

import {
  Name,
  Occupation,
  ProfileDiv,
  ProfileInfo,
  ProfilePicture,
  Logout,
} from './style';

import { Button } from '@material-ui/core';
import { useDispatch } from 'react-redux'; 

export default function ProfileMenu(props) {
  const [logoutOpt, setLogoutOpt] = useState(true); 
  const dispatch = useDispatch(); 
  const handleLogout = () => { 
    setLogoutOpt(!logoutOpt)
  }

  const logout = () => { 
    dispatch({
      type: '@user/LOGOUT'
    })
  }



  return (
    
      <ProfileDiv onClick={handleLogout}>
        <ProfileInfo>
          <Name>{props.name}</Name>
          <Occupation>{props.occupation}</Occupation>
        </ProfileInfo>
        <ProfilePicture >
          <img src={props.avatar} alt="avatar" />
        </ProfilePicture>
        <Logout hidden={logoutOpt}>
          <Button onClick={logout}>Logout</Button>
        </Logout>
      </ProfileDiv>
    
  );
}
