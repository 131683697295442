import React from 'react';
import { InputAdornment } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

import {SearchField} from './style';
/**
 * @param {label} label
 * @param {placeholder} placeholder 
 * @param {action} action açao do form
 * @param {method} method metodo do form
 * @param {id} id ID do input

 */
export default function Search(props) {
  const { label, placeholder, action, method, id, ...rest} = props; 
  return (
    <form  action={action} method={method}>
      <SearchField
        label={label}
        placeholder={placeholder}
        variant="outlined"
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        id={id}
        {...rest}
      />
    </form>
  );
}
