import { Colors } from 'globalStyle'
import styled from 'styled-components'

export const Title = styled.div`

  display: flex;
  justify-content: space-between
`

export const BeneficiaryContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 1em;
  flex: 1 0 auto;
  margin: 14px;
  width: 150px;
  max-width: 150px;
  text-align: center;

  img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
  }

  strong {
    margin-top: 8px;
  }
  
`

export const BeneficiaryListContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  max-width: 100%;
  width: 100vw;
  background-color: white;
  border-radius: 4px;
  border: 1px solid #CDCED9;
  min-height: 121px;
`

export const FinishButton = styled.button`
  margin-top: 1em;
  height: 30px;
  background-color: ${Colors.green};
  color: white;
  outline: none;
  border:none;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  text-align: center;
  border-radius: 4px;
  padding-left: 6px;
  padding-right: 6px;
  cursor: pointer;
`

export const LoadingScreen = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width:100%;
  height:100%;
  background-color: white;
  z-index: 9999;
  opacity: 0.8;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  img {
    width: 3rem;
    height: 3rem;
  }
`