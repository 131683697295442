import React, {useState} from 'react'
import Modal, { ModalBody, ModalHeader, ModalFooter } from "../../modal/index";
import { toast } from "react-toastify";

import { Input } from '../../inputs'
import api from 'services/api'

export const RemoveVacanciesModal = ({open, vacancies, handleClose, userPlanId, reload}) => {
  const [toRemove, setToremove] = useState(1)
  const [disabled, setDisabled] = useState(false)

  const handleSubmit = async (e) => {
    if(e) e.preventDefault();

    try{
      setDisabled(true)
      await api.post("/dynamic-user-plan/removeVacancies", {
        userPlan_id: userPlanId,
        toRemove,
      });
      await reload()
      toast.success('Número de vagas atualizado com sucesso');
      handleClose()
      setDisabled(false)
    } catch(err) {
      setDisabled(false)
      toast.error(err.response.data.message);
    }
  }

  const valueChange = (e) => {
    setToremove(Number(e.target.value))
  }

  const validate = () => {
    if(toRemove < 1) setToremove(1)
    if(toRemove > vacancies) setToremove(vacancies)
  }

  return(
    <Modal show={open} close={handleClose} onSubmit={(e) => handleSubmit(e)} >
      <ModalHeader>Remover vagas</ModalHeader>
      <ModalBody>
        <p>
          Ao reduzir as vagas um desconto será gerado na próxima fatura referente ao pró-rata.
        </p>
        <p>
          Informe a quantidade de vagas que deseja reduzir para este cliente:
        </p>
        <Input
          width={'100px'}
          type='number' 
          value={toRemove} 
          onChange={(e) => valueChange(e)}
          onBlur={validate}
          min={1}
          max={vacancies}
        />
      </ModalBody>
      <ModalFooter cancel={handleClose} next="Confirmar" disabled={disabled} />
    </Modal>
  )
}