import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import jwt_decode from "jwt-decode";

import { store } from "store";

import { useDispatch } from "react-redux";

export default function RouteWrapper({
  component: Component,
  isPrivate,
  isUser,
  isAdmin,
  bgWhite,
  bgGrey,
  ...rest
}) {
  const dispatch = useDispatch();
  useEffect(() => {
    if (bgWhite) {
      dispatch({ type: "@theme/BG_WHITE" });
    }
    if (bgGrey) {
      dispatch({ type: "@theme/BG_GREY" });
    }
  });

  const { access_token } = store.getState().auth;
  if (!access_token && isPrivate) {
    return <Redirect to="/login" />;
  } else if (access_token && isPrivate) {
    const { profile } = jwt_decode(access_token);
    if (isUser && profile && profile !== "CLIENTE") {
      return <Redirect to="/dashboard" />;
    }
    if (
      isAdmin &&
      profile &&
      !(profile === "OPERADOR" || profile === "GESTOR")
    ) {
      return (
        <Redirect
          to={
            store.getState().user.cpfcnpj &&
            store.getState().user.cpfcnpj.length > 11
              ? "/beneficiarios"
              : "/planos"
          }
        />
      );
    }
  }

  return <Route {...rest} component={Component} />;
}

RouteWrapper.propTypes = {
  isPrivate: PropTypes.bool,
  isUser: PropTypes.bool,
  isAdmin: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    .isRequired,
};

RouteWrapper.defaultProps = {
  isPrivate: false,
};
