import * as moment from "moment-timezone";

/**
 * Conversor de data em string padrão DD/MM/YYYY
 * @param {*} Data
 * @param {boolean} toInput transforma no padrao de inputs
 */
export function formatDate(date, toInput) {
  let d = new Date(new Date(date));
  d.setHours(d.getHours() + 3);
  let month = String(d.getMonth() + 1);
  let day = String(d.getDate());
  let year = String(d.getFullYear());

  if (month.length < 2) month = "0" + month;

  if (day.length < 2) day = "0" + day;

  if (toInput) return `${year}-${month}-${day}`;

  return `${day}/${month}/${year}`;
}

export function formatDateWithTimezone(date, withoutHoursDiff = false) {
  const momentDate = moment(date, "YYYY/MM/DD hh:mm:ss a").tz(
    "America/Sao_Paulo"
  );
  if (!withoutHoursDiff) {
    return momentDate.subtract(3, "hours").format("DD/MM/YYYY");
  } else return momentDate.format("DD/MM/YYYY");
}

/**
 * Recebe os status e converte para PT-BR
 * @param {String} status Active, Inactive
 */
export function statusAlias(status) {
  switch (status) {
    case "Active":
      return "Ativo";
    case "Inactive":
      return "Inativo";
    case "ACTIVE":
      return "ATIVO";
    case "INACTIVE":
      return "INATIVO";
    case "PRE_REGISTER":
      return "PRÉ-REGISTRO";
    default:
      return status;
  }
}

export const formatDateTime = (timestamp) => {
  const date = new Date(timestamp);
  const localeDate = new Date(
    Date.UTC(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours(),
      date.getMinutes(),
      date.getSeconds()
    )
  );
  const hours =
    localeDate.getUTCHours() < 10
      ? `0${localeDate.getUTCHours()}`
      : localeDate.getUTCHours();
  const minutes =
    localeDate.getUTCMinutes() < 10
      ? `0${localeDate.getUTCMinutes()}`
      : localeDate.getUTCMinutes();
  return `${hours}:${minutes}`;
};

export const dateTimeDifference = (timestamp) => {
  const date = new Date(timestamp);
  const localeDate = new Date(
    Date.UTC(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours(),
      date.getMinutes(),
      date.getSeconds()
    )
  );

  const today = new Date();
  const localeToday = new Date(
    Date.UTC(
      today.getFullYear(),
      today.getMonth(),
      today.getDate(),
      today.getHours(),
      today.getMinutes(),
      today.getSeconds()
    )
  );

  const millisec = localeToday - localeDate;

  let seconds = (millisec / 1000).toFixed(0);

  let minutes = (millisec / (1000 * 60)).toFixed(0);

  let hours = (millisec / (1000 * 60 * 60)).toFixed(0);

  if (seconds < 60) {
    return seconds + " seg";
  } else if (minutes < 60) {
    return minutes + " min";
  } else {
    return hours + " hrs";
  }
};

export const formatCpfCnpj = (cpfcnpj = "") => {
  if (cpfcnpj.length === 11) {
    return cpfcnpj.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
  } else if (cpfcnpj.length === 14) {
    return cpfcnpj.replace(
      /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
      "$1.$2.$3/$4-$5"
    );
  }
};

export const formatPhone = (phone = "") => {
  if (phone.length === 11) {
    return phone.replace(/(\d{2})(\d{5})(\d{4})/, "($1) $2-$3");
  } else if (phone.length === 10) {
    return phone.replace(/(\d{2})(\d{4})(\d{4})/, "($1) $2-$3");
  }
};

export const fullMonthAndYear = (date) => {
  const newDate = new Date(date);
  const month = newDate.toLocaleString("default", { month: "long" });
  const year = newDate.toLocaleString("default", { year: "numeric" });
  return month + "/" + year;
};

export const calculateAge = (birthday) => {
  var ageDifMs = Date.now() - new Date(birthday).getTime();
  var ageDate = new Date(ageDifMs);
  return Math.abs(ageDate.getUTCFullYear() - 1970);
};

export const getBeneficiariesLength = (beneficiaryList = [], status) => {
  const holders = status === undefined 
    ? beneficiaryList.length
    : beneficiaryList.filter(b => b.status === status).length
  const dependents = beneficiaryList.reduce(
    (a, b) => {
      if(status === undefined) {
        return a + (b.dependents ? b.dependents.length : 0)
      } else {
        return a + (
          b.dependents 
            ? b.dependents.filter(d => d.status === status).length 
            : 0
          )
      }
      
    }, 0
  );

  return holders + dependents;
};
