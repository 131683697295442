import styled from "styled-components";

export const Containers = styled.div`
  margin-top: 4em;
  padding-left: 60px;
  padding-right: 60px;
  display: flex;
  flex-direction: column;
  max-width: 100%;
`;
