import React from "react";
import { FieldContainer } from "./style.js";

export default function SignupSteps({
  title,
  desc,
  children,
  className,
  titleColor,
  width,
}) {
  return (
    <FieldContainer
      display="flex"
      maxWidth="xl"
      width={width}
      className={className}
    >
      <h3 style={{ color: titleColor }}>{title}</h3>
      <p className="desc">{desc}</p>
      {children}
    </FieldContainer>
  );
}
