import styled from 'styled-components';
import { Colors } from 'globalStyle';

export const TextInput = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1em;
  max-width: ${(props) => (props.width ? props.width : 'inherit')};
  width: ${(props) => (props.width ? '100%' : 'inherit')};
  margin-left: ${(props) => (props.left ? props.left : '10')}px;
  input {
    background-color: #ffffff;
    height: 40px;
    padding-left: 1em;
    font-size: 16px;
    width: 100%;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;         /* Opera/IE 8+ */
    border: ${(props) => (props.error ? '1px solid red' : '1px solid #e7e7ed')};
    color: ${(props) => (props.error ? 'red' : 'black')};
    border-radius: 4px !important;
    max-width: ${(props) => (props.width ? props.width : '360px')};
  }
  select {
    background-color: #ffffff;
    height: 40px;
    margin-top: 1em;
    font-size: 16px;
    padding-left: 1em;
    border: 1px solid #e7e7ed;
    border-radius: 4px !important;
    max-width: ${(props) => (props.width ? props.width : '360px')};
  }

  input:disabled {
    background-color: ${Colors['bgGrey']}!important;
    border: 1px;
  }

  label {
    font-family: 'Montserrat';
    color: #000 !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    margin-bottom: 1em;
  }

  span {
    font-family: 'Montserrat';
    font-size: 10px;
    font-style: normal;
    color: red;
    margin: 0;
    max-width: ${(props) => (props.width ? props.width : '360px')};
  }
  .file {
    background-color: #ffffff;
    min-height: 29px;
    width: 463px;
    padding-top: 10px;
  }
  .file::-webkit-file-upload-button {
    visibility: hidden;
    display: none;
  }
  .file::before {
    padding-top: 20px;
  }
`;

export const MaskedInput = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1em;
  margin-left: ${(props) => (props.left ? props.left : '0')}px;
  input {
    background-color: #ffffff;
    height: 40px;
    margin-top: 1em;
    font-size: 16px;
    padding-left: 1em;
    /* border: 1px solid #e7e7ed; */
    border: ${(props) => (props.error ? '1px solid red' : '1px solid #e7e7ed')};
    border-radius: 4px !important;
    max-width: ${(props) => (props.width ? props.width : '360px')};
  }
  select {
    background-color: #ffffff;
    height: 40px;
    margin-top: 1em;
    padding-left: 1em;
    border: 1px solid #e7e7ed;
    border-radius: 4px !important;
    max-width: ${(props) => (props.width ? props.width : '360px')};
  }

  input:disabled {
    background-color: ${Colors['bgGrey']}!important;
    border: 1px;
  }

  label {
    font-family: 'Montserrat';
    color: #000 !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    margin-bottom: 1em;
  }

  span {
    padding-top: 0;
    margin-top: 0;
    font-weight: 300;
    font-size: 10px;
    color: ${Colors.darkRed};
  }
`;

export const ImageInput = styled.div`
  display: flex;
  z-index: 998;
  flex-direction: row;
  align-items: center;
  position: relative;
  color: #cdced9;
  width: 282px;
  input {
    display: none;
  }
  span {
    margin-left: 2em;
    font-style: italic;
    font-weight: normal;
    font-size: 12px;
  }
  img {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    z-index: 1000;
    object-fit: cover;
  }
`;

export const InputImgLabel = styled.div`
  position: relative;
  border-radius: 50%;
  width: 64px;
  height: 64px;
  border: 1px solid #cdced9;
  label {
    position: absolute;
    top: 19px;
    left: 20px;
    color: #cdced9;
    padding: 50%;
  }
`;

export const InputGroups = styled.div`
  margin-top: 1em;
  margin-bottom: 2em;
`;

export const InputGroupsHeader = styled.div`
  font-family: Montserrat;
  margin-bottom: 1em;
  h2 {
    margin: 0;
    padding: 0;
    font-size: 20px;
  }
  span {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    color: #393a4a;
  }
`;

export const InputGroupsBody = styled.div`
  display: ${(props) => (props.direction ? 'flex' : 'block')};
  direction: ${(props) => (props.direction ? props.direction : 'none')};
`;

export const FormBody = styled.form`
  ${(props) => (props.width ? 'width:' + props.width : '')}
`;

export const PasswordPatternContainer = styled.div`
  display: flex;
  align-items: flex-start;
  font-size: 14px;
  margin-top: 1em;
  label {
    margin-bottom:0;
    padding-left: 4px;
    font-weight: 400;
    color: ${Colors.darkGrey} !important;
  }
`
