import React from 'react'

import ClientSignupForm from 'components/clientHome/signup/form';

export default function Signup() {


  return (
    <>
      <ClientSignupForm/>
    </>
  )
}