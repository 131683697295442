import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useHistory, useParams } from "react-router-dom";
import * as moment from "moment-timezone";

import Main from "components/main";
import AddDependentForm from "components/client/beneficiary/addMultiple/dependentForm";
import { HolderInfo, Title, FinishButton } from "./style";
import api from "services/api";

import { formatCpfCnpj, getBeneficiariesLength } from "helper";
import { inputValidations } from "utils/beneficiary-validator";
import { LoadingScreen } from "../addMultiple/style";
import LoadingGif from "../../../assets/icons/LoadingBlue.gif";
import PaymentConfirmationModal from "components/client/payment/paymentConfirmationModal";

import ModalUserAlert from "components/client/beneficiary/modalUserAlert";

export default function AddMultipleDependents() {
  const { id } = useParams();

  const [dependentForm, setDependentForm] = useState([
    {
      cpf: "",
      name: "",
      email: "",
      telephone: "",
      type: "DEPENDENT",
      genre: "Masculino",
      birth_date: "",
    },
  ]);
  const [beneficiary, setBeneficiary] = useState(null);
  const [loading, setLoading] = useState(false);
  const [paymentForm, setPaymentForm] = useState({
    payment_method: "credit_card",
    card_cvv: "",
    card_expiration_date: "",
  });
  const [modalOpen, setModalOpen] = useState(false);
  const [modalAlertOpen, setModalAlertOpen] = useState(false);
  const [updatePayment, setUpdatePayment] = useState(false);
  const [client, setClient] = useState(null);
  const history = useHistory();

  useEffect(() => {
    const getBeneficiaryInfo = async () => {
      try {
        const response = await api.get("beneficiary/" + id);
        const profile_pic = await api.get("beneficiary/profile-pic/" + id);
        const beneficiaryInfo = response.data;
        if (beneficiaryInfo.type !== "HOLDER") {
          throw new Error("O tipo do beneficiário não é titular");
        }
        beneficiaryInfo.profile_pic = profile_pic.data;
        setBeneficiary(beneficiaryInfo);
      } catch (err) {
        toast.error(err.response ? err.response.data.message : err.message);
        history.push("/beneficiarios");
      }
    };
    getBeneficiaryInfo();
  }, [history, id]);

  const alertModalHandleOk = () => {
    setModalAlertOpen(false);
    history.push({
      pathname: "/beneficiarios",
      state: { secondStep: true },
    });
  };

  const handleDependentsFileChange = (e, i) => {
    setDependentForm(
      [...dependentForm],
      (dependentForm[i]["profile_pic"] = e.target.files[0])
    );
  };

  const handleDependentsChange = (e, i) => {
    setDependentForm(
      [...dependentForm],
      (dependentForm[i][e.target.name] = e.target.value)
    );
  };

  const handlePaymentFormChange = (e) => {
    setPaymentForm({ ...paymentForm, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      for (const [i, dependent] of dependentForm.entries()) {
        const duplicateDependentCpfInput = dependentForm.find((val, idx) => {
          return val.cpf === dependent.cpf && idx !== i;
        });

        if (duplicateDependentCpfInput) {
          toast.error("Existem dependentes com o mesmo CPF");
          return false;
        }

        if (!dependent.profile_pic) {
          throw new Error(
            parseInt(dependentForm.indexOf(dependent) + 1) === 0
              ? "Foto de perfil não inserida no Beneficiário Titular"
              : "Foto de perfil não inserida no Dependente " +
                parseInt(dependentForm.indexOf(dependent) + 1)
          );
        }

        const validation = validateBeneficiaryForm(dependent);
        if (!validation) return false;
      }

      const formData = mountMultipartForm();
      try {
        setLoading(true);
        await api.post("/beneficiary/multiple-dependent", formData);
        setLoading(false);
        toast.success("Beneficiários incluídos com sucesso");
        setModalAlertOpen(true);
      } catch (err) {
        console.log(err);
        setLoading(false);
        if (err.response) {
          toast.error(err.response.data.message);
        } else {
          toast.error(err.message);
        }
      }
      setLoading(false);
    } catch (err) {
      toast.error(err.response ? err.response.data.message : err.message);
    }
  };

  const validateBeneficiaryForm = (newForm) => {
    const validation = inputValidations(newForm);
    if (validation !== true) {
      let index = -1;
      for (const [i, form] of dependentForm.entries()) {
        if (form.cpf === newForm.cpf) index = parseInt(i) + 1;
      }
      toast.error("Existem campos inválidos no Dependente " + index);
      return false;
    } else {
      return true;
    }
  };

  const mountMultipartForm = () => {
    const payload = [];
    const profile_pics = [];

    for (const dependent of dependentForm) {
      const { profile_pic, ...dependendData } = dependent;
      const dependentObj = Object.assign({}, dependendData);

      const formatedDate = moment(dependent.birth_date, "DD/MM/YYYY", true);
      dependentObj.cpf = dependent.cpf.replace(/\D+/g, "");
      dependentObj.holder_id = id;
      dependentObj.telephone = dependent.telephone.replace(/\D+/g, "");
      dependentObj.birth_date = formatedDate.format("YYYY/MM/DD");
      profile_pics[dependent.cpf.replace(/\D+/g, "")] = profile_pic;

      payload.push(dependentObj);
    }

    const formData = new FormData();
    formData.append("payload", JSON.stringify(payload));
    if (client?.plan?._id) {
      formData.append(
        "paymentInfo",
        JSON.stringify({
          ...paymentForm,
          card_expiration_date: paymentForm.card_expiration_date.replace(
            /\D+/g,
            ""
          ),
        })
      );
      if (updatePayment) formData.append("changePaymentMethod", true);
    }
    Object.keys(profile_pics).map((key) =>
      formData.append(key, profile_pics[key])
    );

    return formData;
  };

  useEffect(() => {
    async function getClient() {
      const response = await api.get("/user/clients/self");
      setClient(response.data);
    }
    getClient();
  }, []);

  return (
    <Main maxWidth="xl">
      <Title>
        <h3>Beneficiário titular</h3>
        <FinishButton
          type="button"
          onClick={
            client?.plan?._id &&
            getBeneficiariesLength(client?.beneficiaries) +
              dependentForm.length >
              client?.plan?.number_users
              ? () => setModalOpen(true)
              : handleSubmit
          }
        >
          {" "}
          Finalizar{" "}
        </FinishButton>
      </Title>
      <HolderInfo>
        <img
          alt="profile"
          src={
            beneficiary?.profile_pic ??
            "/user.png"
          }
        ></img>
        <div>
          <strong>{beneficiary?.name}</strong>
          <span>{formatCpfCnpj(beneficiary?.cpf)}</span>
        </div>
      </HolderInfo>
      <AddDependentForm
        dependentForm={dependentForm}
        handleChange={handleDependentsChange}
        setDependentForm={setDependentForm}
        handleDependentsFileChange={handleDependentsFileChange}
      />
      {loading && (
        <LoadingScreen>
          <h5>Inserindo beneficiários, por favor aguarde</h5>
          <img src={LoadingGif} alt="loading icon" />
        </LoadingScreen>
      )}
      <PaymentConfirmationModal
        open={modalOpen}
        handleClose={() => setModalOpen(false)}
        handleChange={handlePaymentFormChange}
        paymentForm={paymentForm}
        setPaymentForm={setPaymentForm}
        planValue={client?.plan?.value}
        handleSubmit={handleSubmit}
        updatePayment={updatePayment}
        setUpdatePayment={setUpdatePayment}
        number={
          dependentForm.length +
          getBeneficiariesLength(client?.beneficiaries) -
          client?.plan?.number_users
        }
      />
      <ModalUserAlert 
        toggle={modalAlertOpen}
        handleOk={alertModalHandleOk}
      />
    </Main>
  );
}
