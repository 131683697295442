import React, { useState, useEffect } from "react";
import Main from "components/main";

import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  TableFooter,
  TablePagination,
  NativeSelect,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import { toast } from "react-toastify";

import Modal, { ModalBody, ModalFooter, ModalHeader } from "components/modal";
import { Card, CardHeader, CardNumber } from "components/card";
import { Options } from "../doctors/style";

import Status from "components/status";
import Search from "components/search";

import { useHistory } from "react-router-dom";
import api from "services/api";
import { statusAlias, formatCpfCnpj } from "helper";
import { formatNameForSearch } from "../../utils/input-validators";
import { store } from "store";
import { useDispatch } from "react-redux";

export default function Clients() {
  //PAGINAÇÃO
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(
    store.getState().auth.rows_per_page
  );
  const [clientCount, setClientCount] = useState(0);

  const [clients, setClients] = useState([]);
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState("");
  const history = useHistory();

  const [toggleModal, setToggleModal] = useState(false);
  const [clientSelected, setClientSelected] = useState("");
  const [statusSelected, setStatusSelected] = useState("");
  const statusList = [
    { label: "Ativo", value: "ACTIVE" },
    { label: "Inativo", value: "INACTIVE" },
    { label: "Adimplente", value: "ADIMPLENTE" },
    { label: "Inadimplente", value: "INADIMPLENTE" },
  ];
  const [updateStatus, setUpdateStatus] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    async function getClients() {
      const users = await api.get("/user/clients");
      setClients(users.data);
      setClientCount(users.data.length);
      setData(users.data);
    }
    getClients();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    dispatch({
      type: "@pagination/ROWS_PER_PAGE",
      payload: {
        rows_per_page: parseInt(event.target.value, 10),
      },
    });
    setPage(0);
  };
  //FIM PAGINAÇÃO

  const handleSearch = (e) => {
    const { value } = e.target;
    const doc = value.replace(/[^\d]+/g, "");

    const searchByName = clients.filter((obj) =>
      formatNameForSearch(obj.name).includes(formatNameForSearch(value))
    );
    const searchByDoc = doc.length
      ? clients.filter(
          (obj) => obj.cpfcnpj === value || obj.cpfcnpj.startsWith(doc)
        )
      : [];

    const search = [...searchByDoc, ...searchByName];

    setClientCount(search.length);
    setData(search);
    setPage(0);
  };

  const handleFilter = (e) => {
    const { value } = e.target;
    setFilter(value);
    if (value !== "") {
      let search = clients.filter((client) => client.status === value);
      setClientCount(search.length);
      setData(search);
    } else {
      setClientCount(clients.length);
      setData(clients);
    }
  };

  const handleChangeStatus = () => {
    api
      .put(`/user/status/${clientSelected}`, {
        status: statusSelected,
      })
      .then(() => {
        setUpdateStatus(true);
      })
      .catch((e) => {
        toast.error(e.response.data.message);
      });
  };

  const handleModalChangeStatus = (clientId) => {
    // Clear variables
    setUpdateStatus(false);
    setStatusSelected("");
    setClientSelected("");
    if (!updateStatus) document.getElementById("cm-select").selectedIndex = 0;
    // Set values
    setClientSelected(clientId);
    setToggleModal(!toggleModal);
  };

  return (
    <Main maxWidth="xl" style={{ position: "relative", top: "62px" }}>
      <Modal show={toggleModal} onSubmit={() => handleChangeStatus()}>
        <ModalHeader close={handleModalChangeStatus}>
          Alterar Status
        </ModalHeader>
        <ModalBody>
          {!updateStatus ? (
            <select
              id="cm-select"
              onChange={(e) => setStatusSelected(e.target.value)}
            >
              <option value="default">Escolha um status que deseja</option>
              {statusList.map((status) => {
                return (
                  <option key={status.value} value={status.value}>
                    {status.label}
                  </option>
                );
              })}
            </select>
          ) : (
            <span>Status alterado com sucesso!</span>
          )}
        </ModalBody>
        <ModalFooter>
          {!updateStatus && statusSelected ? (
            <>
              <Button variant="outlined" onClick={handleModalChangeStatus}>
                {" "}
                Cancelar{" "}
              </Button>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleChangeStatus}
              >
                {" "}
                Confirmar{" "}
              </Button>
            </>
          ) : (
            <Button
              variant="outlined"
              onClick={
                updateStatus
                  ? () => window.location.reload()
                  : handleModalChangeStatus
              }
            >
              {" "}
              Fechar{" "}
            </Button>
          )}
        </ModalFooter>
      </Modal>
      <Grid container spacing={1} className="mt-3">
        <Grid item>
          <Card>
            <CardHeader label="Pré-Registros" />
            <CardNumber color="red">
              {" "}
              {clients.filter((c) => c.status === "PRE_REGISTER").length}{" "}
            </CardNumber>
          </Card>
        </Grid>
        <Grid item>
          <Card>
            <CardHeader label="Clientes cadastrados" />
            <CardNumber color="darkGrey"> {clients.length} </CardNumber>
          </Card>
        </Grid>
        <Grid item>
          <Card>
            <CardHeader label="Clientes ativos" />
            <CardNumber color="blue">
              {clients.filter((c) => c.status !== "INACTIVE").length}
            </CardNumber>
          </Card>
        </Grid>
        <Grid item>
          <Card>
            <CardHeader label="Clientes Inadimplentes" />
            <CardNumber color="red">
              {clients.filter((c) => c.status === "INADIMPLENTE").length}
            </CardNumber>
          </Card>
        </Grid>
      </Grid>
      <Options container>
        <Search label={`Buscar clientes`} onChange={handleSearch} />
        <FormControl>
          <InputLabel htmlFor="filter">Filtrar por </InputLabel>
          <NativeSelect
            value={filter}
            onChange={handleFilter}
            inputProps={{
              name: "filter",
              id: "filter",
            }}
            f
          >
            <option aria-label="None" value="" />
            <option value="PRE_REGISTER">Pré-Registro</option>
            <option value="ACTIVE">{statusAlias("Active")}</option>{" "}
            <option value="ADIMPLENTE">Adimplente</option>
            <option value="INADIMPLENTE">Inadimplente</option>
            {/* remover */}
            <option value="INACTIVE">{statusAlias("Inactive")}</option>{" "}
            {/* remover */}
          </NativeSelect>
        </FormControl>
      </Options>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell width="200" align="center">
                Nome do Cliente
              </TableCell>
              <TableCell width="200" align="center">
                CPF/CNPJ
              </TableCell>
              <TableCell width="200" align="center">
                Tipo
              </TableCell>
              <TableCell width="200" align="center">
                E-mail
              </TableCell>
              <TableCell width="200" align="center">
                Beneficiários
              </TableCell>
              <TableCell width="200" align="center">
                Status
              </TableCell>
              <TableCell width="200" align="center">
                Opções
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((client) => (
                <TableRow key={client._id}>
                  <TableCell align="center">{client.name}</TableCell>
                  <TableCell align="center">
                    {formatCpfCnpj(client.cpfcnpj)}
                  </TableCell>
                  <TableCell align="center">{client?.type}</TableCell>
                  <TableCell align="center">{client.email}</TableCell>
                  <TableCell align="center">
                    {client?.plan?.number_users || 0}
                  </TableCell>
                  <TableCell align="center">
                    <Status
                      status={client.status}
                      onClick={(_) => handleModalChangeStatus(client._id)}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <Button
                      variant="outlined"
                      onClick={() => history.push(`/clientes/${client._id}`)}
                    >
                      Detalhes
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                // colSpan={3}
                count={clientCount}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { "aria-label": "Itens por página" },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onChangePage={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Main>
  );
}
