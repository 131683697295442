import React, { useState, useRef, useEffect } from 'react';
import {
    Container,
    Row,
    Button,
    AutoCompleteWrapper,
    AutoCompleteOptions
} from './style';

import { Input as Select, InputMask } from '../inputs';
import { dateMask } from "utils/masks";

import api from "services/api";

export default function Reports() {
    const optionsList = [
        { name: 'Atendimento', value: 'APPOINTMENTS' },
        { name: 'Contratante', value: 'CONTRACTING' },
        { name: 'Financeiro', value: 'FINANCIAL' },
    ];

    const monthList = [
        { name: 'Jan', value: '1' },
        { name: 'Fev', value: '2' },
        { name: 'Mar', value: '3' },
        { name: 'Abr', value: '4' },
        { name: 'Mai', value: '5' },
        { name: 'Jun', value: '6' },
        { name: 'Jul', value: '7' },
        { name: 'Ago', value: '8' },
        { name: 'Set', value: '9' },
        { name: 'Out', value: '10' },
        { name: 'Nov', value: '11' },
        { name: 'Dez', value: '12' },
    ];

    const yearList = (() => [...Array(10).keys()].map((_) => ({
        name: 2022 + _,
        value: 2022 + _,
    })))()
    
    const [reportTypeSelected, setReportTypeSelected] = useState('');
    const [contractors, setContractors] = useState([]);
    const [contractorSelected, setContractorSelected] = useState('');
    const [initialDate, setInitialDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [loader, setLoader] = useState(false);
    const [search, setSearch] = useState("");
    const [display, setDisplay] = useState(false);
    const wrapperRef = useRef(null);


    const [monthSelected, setMonthSelected] = useState('');
    const [yearSelected, setYearSelected] = useState('');


    async function handleSubmit(event) {

        try {
            setLoader(true);
            event.preventDefault();
            const format = (date) => {
                const split = date.split('/');
                return split[1] + '/' + split[0] + '/' + split[2]
            }

            const formatedDates = (() => {
                if(reportTypeSelected === optionsList[0].value) {
                    return {
                        startDate: new Date(format(initialDate)),
                        endDate: new Date(format(endDate))
                    }
                }
               return {
                    startDate: new Date(yearSelected, Number(monthSelected) - 1, 1),
                    endDate: new Date(yearSelected, Number(monthSelected), 0)
                }
            })();

            const body = {
                type: reportTypeSelected,
                start_date: formatedDates.startDate,
                end_date: formatedDates.endDate,
                contractor_id: contractorSelected
            }
            const response = await api.post("/reports", body, { responseType: 'arraybuffer' });
            const blob = new Blob([response.data], { type: response.headers['content-type'] });
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            const type = optionsList.find(opt => opt.value === reportTypeSelected);
            link.download = `Relatorio${type.name}.xlsx`;
            document.body.appendChild(link);
            link.click();
            link.remove();  //afterwards we remove the element again
            setLoader(false);
        } catch (err) {
            console.error('Error: ', err);
        }
    }

    function handleInitialDate(event) {
        setInitialDate(event.target.value);
    }

    function handleEndDate(event) {
        setEndDate(event.target.value);
    }

    async function handleReportType(event) {
        if(event.target.value.length > 0) {
            setReportTypeSelected(event.target.value);
            if(event.target.value === optionsList[1].value) {
                const response = await api.get("/user/clients");
                setContractors(response.data);
            }
        } else {
            setReportTypeSelected(''); 
            setEndDate('');
            setInitialDate('');
            setLoader(false);
        }
    }

    useEffect(() => {
        window.addEventListener("mousedown", handleClickOutside);
        return () => {
          window.removeEventListener("mousedown", handleClickOutside);
        };
      });
    
      const handleClickOutside = event => {
        const { current: wrap } = wrapperRef;
        if (wrap && !wrap.contains(event.target)) {
          setDisplay(false);
        }
      };
    
      const updateAutoCompleteList = search => {
        setSearch(search.name);
        setContractorSelected(search._id);
        setDisplay(false);
      };


    return (
        <Container>
            <form onSubmit={handleSubmit}>
                <Select 
                    type="select"
                    id="report-type"
                    name="report-type"
                    label="Escolha o tipo de relatório que deseja extrair"
                    value={reportTypeSelected}
                    onChange={(e) => handleReportType(e)}
                    width="300px"
                    required
                >
                    <option value="">Selecione uma opção</option>
                    {optionsList.map(opt => {
                        return <option key={opt.value} value={opt.value}>{opt.name}</option>
                    })}
                </Select>
                {reportTypeSelected.length > 0 && (
                    <>
                        {reportTypeSelected === optionsList[1].value && 

                            <AutoCompleteWrapper ref={wrapperRef} className="flex-container flex-column pos-rel">
                            <Select
                            type="input"
                            id="auto"
                            onClick={() => setDisplay(!display)}
                            placeholder="Type to search"
                            value={search}
                            onChange={event => setSearch(event.target.value)}
                            />
                            {display && (
                            <AutoCompleteOptions className="autoContainer">
                                {contractors
                                .filter(({ name }) => name.toLowerCase().indexOf(search.toLowerCase()) > -1)
                                .map((value, i) => {
                                    return (
                                    <div
                                        onClick={() => updateAutoCompleteList(value)}
                                        className="option"
                                        key={i}
                                        tabIndex="0"
                                    >
                                        <span>{value.name}</span>
                                    </div>
                                    );
                                })}
                            </AutoCompleteOptions>
                            )}
                            </AutoCompleteWrapper>
                        }
                        <Row>

                            {reportTypeSelected !== optionsList[0].value && 
                            (<>
                                <Select 
                                    type="select"
                                    id="month_select"
                                    name="month_select"
                                    label="Mês"
                                    value={monthSelected}
                                    onChange={(e) => setMonthSelected(e.target.value)}
                                    width="145px"
                                    required
                                >
                                    <option value="">Selecione um mês</option>
                                    {monthList.map(opt => {
                                        return <option key={opt.value} value={opt.value}>{opt.name}</option>
                                    })}
                                </Select>

                                <Select 
                                    type="select"
                                    id="year_select"
                                    name="year_select"
                                    label="Ano"
                                    value={yearSelected}
                                    onChange={(e) => setYearSelected(e.target.value)}
                                    width="145px"
                                    required
                                >
                                    <option value="">Selecione um ano</option>
                                    {yearList.map(opt => {
                                        return <option key={opt.value} value={opt.value}>{opt.name}</option>
                                    })}
                                </Select>
                            </>)
                            }

                {reportTypeSelected === optionsList[0].value && 
                            (<>
                            <InputMask
                                name="initial_date"
                                label="Data Inicial"
                                inputMode="numeric"
                                width="150px"
                                value={initialDate}
                                onChange={(e) => handleInitialDate(e)}
                                onBlur={() => {}}
                                mask={dateMask}
                                required
                            />
                            <InputMask
                                name="end_date"
                                label="Data Final"
                                inputMode="numeric"
                                width="150px"
                                value={endDate}
                                onChange={(e) => handleEndDate(e)}
                                onBlur={() => {}}
                                mask={dateMask}
                                required
                            />
                         </>)
                        }
                        </Row>

                        <Button type="submit">{!loader ? 'Gerar Relatório' : 'Gerando...'}</Button>
                    </>
                )}
            </form>
        </Container>
    )
}