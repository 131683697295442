import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Grid, Button } from "@material-ui/core";
import jwt_decode from "jwt-decode";

import {
  ImgInput,
  InputGroup,
  InputGroupHeader,
  Form,
  PasswordInput,
} from "components/inputs";
import Header from "components/header";
import { Containers } from "./style";

import api from "services/api";
import { toast } from "react-toastify";

export default function AdminCreate() {
  const { hash } = useParams();
  const history = useHistory();
  const [docs, setDocs] = useState([]);
  const [user, setUser] = useState({
    password: "",
  });

  try {
    jwt_decode(hash);
  } catch (err) {
    history.push("/login");
  }
  const { _id } = jwt_decode(hash);

  useEffect(() => {
    async function getUser() {
      const response = await api.get(`/user/${_id}`);
      const id = response.data._id;
      delete response.data._id;
      setUser({ userId: id, ...response.data });
    }
    getUser();
  }, [_id]);

  const handleChange = (event) => {
    setUser({ ...user, [event.target.name]: event.target.value });
  };

  const handleDocs = (event) => {
    setDocs({ ...docs, [event.target.name]: event.target.files[0] });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (user.password !== user.passwordConfirmation) {
      return toast.error("A confirmação é diferente da senha");
    }

    const formData = new FormData();
    Object.keys(user).map((key) => formData.append(key, user[key]));
    Object.keys(docs).map((key) => formData.append(key, docs[key]));

    const response = await api.post("/user/finishAdmin", formData);

    if (response.status === 201) {
      toast.success("Cadastrado com sucesso, realize seu login");
      history.push("/login");
    } else {
      toast.error(response.data.message);
    }
  };

  const checkConfirmation = () => {
    return user.password === user.passwordConfirmation;
  };

  return (
    <>
      <Header onlyLogo />
      <Containers maxWidth="100%">
        <Form width="100%" onSubmit={handleSubmit}>
          <Grid container justifyContent="center" alignItems="center">
            <Grid
              container
              justifyContent="center"
              // direction="column"
              item
              xs={3}
            >
              <h2>Complete seu registro</h2>
              <InputGroup>
                <InputGroupHeader>
                  <h2>Foto de perfil</h2>
                  <span> Adicione sua foto de perfil </span>
                </InputGroupHeader>
                <ImgInput onChange={handleDocs} required name="profile_pic" />
              </InputGroup>

              <InputGroup>
                <InputGroupHeader>
                  <h2>Senha</h2>
                </InputGroupHeader>
                <PasswordInput
                  showPattern
                  defaultValue={user.password}
                  name="password"
                  type="password"
                  placeholder="Senha"
                  label="Senha"
                  onChange={handleChange}
                  verificationText={checkConfirmation}
                  required
                />
                <PasswordInput
                  name="passwordConfirmation"
                  type="password"
                  required
                  placeholder="Confirmação de senha"
                  label="Confirmação de senha"
                  onChange={handleChange}
                />
              </InputGroup>
              <Grid item>
                <Button type="submit" variant="outlined">
                  Finalizar cadastro
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Form>
      </Containers>
      <footer />
    </>
  );
}
