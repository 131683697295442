import React, { useEffect, useState } from "react";

const ConditionalCarousel = ({ children, setState, isValid, initialPage = 0 }) => {
  const [actualPage, setActualPage] = useState(initialPage);

  useEffect(() => {
    setState({
      activeIndex: actualPage,
      slideTo: setActualPage,
      slideNext: (twoForward) => slide("next", twoForward),
      slidePrev: (twoForward) => slide("prev", twoForward),
      isLast: (children && actualPage === children.length - 1) ?? false,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualPage]);

  function slide(side, twoForward) {
    let newPage;
    if (twoForward) {
      newPage = actualPage + (side === "next" ? 2 : -2);
    } else {
      newPage = actualPage + (side === "next" ? 1 : -1);
    }
    const actualField =
      children[actualPage]?.props?.children?.props?.name || "";
    if (isValid(actualField) && (newPage > 0 || newPage < children?.length)) {
      setActualPage(newPage);
    }
  }

  return (
    <>
      {children.filter((page, idx) => {
        return idx === actualPage && <div>{page}</div>;
      })}
    </>
  );
};

export default ConditionalCarousel;
