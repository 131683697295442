import React from 'react'
import Modal, { ModalBody, ModalHeader } from 'components/modal';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { ReceiptButton } from './style';
import { toast } from 'react-toastify';
import { formatDate } from 'helper'

export default function PaymentHistoryModal({payments=[], open, handleClose}) {

  function openInNewTab(url) {
    if(url) {
      var win = window.open(url, '_blank');
      win.focus();
    } else {
      toast.warning("Nota não vinculada")
    }
  }

  const paymentStatus = (status) => {
    switch(status) {
      case "paid":
        return "Pago";
      case "waiting_payment":
        return "Pendente";
      case "chargedback":
        return "Estornado";
      case "canceled":
        return "Cancelado";
      case "failed":
        return "Não Pago - Falha";
      default:
        return "Não Pago"
    }
  }

  return(
    <Modal show={open} style={{minWidth:"80vw"}}>
      <ModalHeader close={handleClose}>Histórico de pagamento</ModalHeader>
      <ModalBody>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align='center'>
              <span>Data de vencimento</span>
              </TableCell>
              <TableCell align='center'>
              <span>Data de pagamento</span>
              </TableCell>
              <TableCell align='center'>
                <span>Forma de Pgto.</span>
              </TableCell>
              <TableCell align='center'>
                <span>Valor</span>
              </TableCell>
              <TableCell align='center'>
                <span>Status</span>
              </TableCell>
              <TableCell align='center'>
                <span>Opções</span>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {payments
              .map((payment) => (
                <TableRow key={payment.createdAt}>
                  <TableCell align='center'>
                    {payment.boleto_expiration_date ? formatDate(payment.boleto_expiration_date) : '--'}
                  </TableCell>
                  <TableCell align='center'>
                    {payment.paidAt ? formatDate(payment.paidAt) : '--'}
                  </TableCell>
                  <TableCell align='center'>
                    {payment.payment_method==="boleto" ? "Boleto" : "Cartão de Crédito"}
                  </TableCell>
                  <TableCell align='center'>
                    R$ {payment.amount.toFixed(2).replace(".",",")}
                  </TableCell>
                  <TableCell align='center'>
                    {paymentStatus(payment.status)}
                  </TableCell>
                  <TableCell align='center'>
                    {<ReceiptButton type="button" onClick={()=>openInNewTab(payment.receipt_url)}>Documento</ReceiptButton>}
                    {payment.payment_method==="boleto" &&
                      <ReceiptButton
                      type="button"
                      onClick={()=>openInNewTab(payment.boleto_url)}>
                        Boleto
                      </ReceiptButton>
                    }
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      </ModalBody>
    </Modal>
  )
}