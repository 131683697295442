import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  :root{
    --scroll : auto;
  }
  * { 
    font-family: 'Montserrat';
    box-sizing: border-box;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
  }
  html, body {
    background-color: ${(props) =>
      props.bgColor === true ? "#fff" : "#F7F8F9"}; 
    height: 100%; 
    width: 100%;
    overflow-x: hidden;
    z-index: 2;
  }
  body  {
    margin: 0%; 
    overflow: var(--scroll);
  }
  #root { 
    width: 100%; 
    height: 100%;
    background-color: ${(props) =>
      props.bgColor === true ? "#fff" : "#F7F8F9"} ; 
    /* #F7F8F9;  */
  }
  .mt-3 { 
    margin-top: 2em!important; 
  }
  .MuiTable-root{ 
    background-color : #ffffff; 
  }
  footer {
    height: 75px;
  }
  .slick-slide div:only-child {
    outline: none;
    height: 100%;
  }
`;

export const Colors = {
  blue: "#3089C3",
  green: "#50D2A0",
  red: "#FF5F5F",
  orange: "#B95000",
  dark: "#6B6C7E",
  whiteGrey: "#A7A9BC",
  darkRed: "#DA1414",
  darkGrey: "#6B6C7E",
  darkGreen: "#287D3C",
  bgRed: "#FEEFEF",
  bgGreen: "#EDF9F0",
  bgOrange: "#FFF4EC",
  bgGrey: "#F7F8F9",
};

export const StatusColors = {
  ACTIVE: {
    color: "#287D3C",
    background: "#EDF9F0",
  },
  ATIVO: {
    color: "#287D3C",
    background: "#EDF9F0",
  },
  ADIMPLENTE: {
    color: "#287D3C",
    background: "#EDF9F0",
  },
  PENDENTE: {
    color: "B95000",
    background: "#FFF4EC",
  },
  INADIMPLENTE: {
    color: "#DA1414",
    background: "#FEEFEF",
  },
  INACTIVE: {
    color: "#DA1414",
    background: "#FEEFEF",
  },
  INATIVO: {
    color: "#DA1414",
    background: "#FEEFEF",
  },
  PRE_REGISTER: {
    color: "#DA1414",
    background: "#FEEFEF",
  },
};

export default GlobalStyle;
