import { Colors } from 'globalStyle';
import styled from 'styled-components'; 

export const LoginDiv = styled.div`
  height: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: row; 
  justify-content: center;
  align-content: center;
`;  

export const FormContainer = styled.div`
  width: 400px; 
  height: 400px;
  align-self: center;
  padding: 1em; 
  display: flex; 
  flex-direction: column; 
  h1 { 
    text-align: center; 
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    color: #3089C3;
    padding-right: 0.5em; 
  }
  form { 
    margin-top: 1em; 
    padding-right: 1.5em; 
    padding-left: 1.5em; 
    display: flex; 
    flex-direction: column; 
    max-width: 100%; 
    height: 100%;
  }
  div:not(.eye-icon) {
    margin-top: 1em; 
  }
  .mt-5 { 
    margin-top : 1em; 
  }
  .ms-5 { 
    margin-left: 3em;
    margin-right: 3em;
  }
  span.forgot-password {
    font-size:12px;
    margin-top: 0.7em;
    margin-bottom: 0.5em;
    color: ${Colors.darkGrey};
    cursor: pointer;
  }
`

export const ButtonBox = styled.div`
  display: flex;
  button {
    width: 100%;
    height: 45px;
  }
  button + button {
    margin-left: auto;
  }
  button {
    background-color: ${props=>props.submit ? Colors.blue : null};
    color: ${props=>props.submit ? "white" : null};
  }
`

export const ButtonSeparator = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  div {
    background-color: ${Colors.darkGrey};
    height: 1px;
    border: 0;
    width: 40%;
    margin-top: 0 !important;
  }
`

export const LogoImg = styled.img`
  max-width: 100%;
  height: auto;
`