import PaymentForm from "components/client/payment/paymentForm";
import Main from "components/main";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { GridItem, SelectedPlan } from "./style";

export default function PlanPayment({ location }) {
  const [plan, setPlan] = useState({ value: 0, recurrence: "" });
  const history = useHistory();

  useEffect(() => {
    try {
      setPlan(location.state.plan);
    } catch (err) {
      history.push("/planos");
    }
  }, [history, location]);

  const valueFormat = (plan) => {
    const formatedValue = plan.value.toFixed(2).replace(".", ",");
    switch (plan.recurrence) {
      case "ANUAL":
        return `<span>12x de </span> <strong>R$ ${formatedValue}</strong>`;
      case "SEMESTRAL":
        return `<span>6x de </span> <strong>R$ ${formatedValue}</strong>`;
      case "TRIMESTRAL":
        return `<span>3x de </span> <strong>R$ ${formatedValue}</strong>`;
      case "MENSAL":
        return `<span>1x de </span> <strong>R$ ${formatedValue}</strong>`;
      default:
        return `<span>1x de </span> <strong>R$ ${formatedValue}</strong>`;
    }
  };

  const totalValue = (value) => {
    let res = value;
    plan.recurrence === "ANUAL"
      ? (res = plan.value * 12)
      : plan.recurrence === "SEMESTRAL"
      ? (res = plan.value * 6)
      : plan.recurrence === "TRIMESTRAL"
      ? (res = plan.value * 3)
      : (res = plan.value);
    return res.toFixed(2).replace(".", ",");
  };

  const cardItems = [
    {
      label: "Plano",
      value: plan.name,
      color: "#2E5AAC",
    },
    {
      label: "Recorrência",
      value:
        plan.recurrence.charAt(0).toUpperCase() +
        plan.recurrence.slice(1).toLowerCase(),
    },
    {
      label: "Quantidade de beneficiários",
      value: plan.number_users,
    },
    {
      label: "Valor",
      value: valueFormat(plan),
      color: "#287D3C",
    },
    {
      label: "Valor final",
      value: "R$ " + totalValue(plan.value),
      color: "#287D3C",
    },
  ];

  return (
    <Main maxWidth="xl">
      <h4>
        {plan.type === "PJ"
          ? "PASSO 5: Realize o pagamento"
          : "PASSO 3: Realizar pagamento do plano escolhido"}
      </h4>
      <SelectedPlan container>
        {cardItems.map((item) => (
          <GridItem color={item.color} key={item.label} item md={4} xs={6}>
            <label>{item.label}</label>
            <strong dangerouslySetInnerHTML={{ __html: item.value }}></strong>
          </GridItem>
        ))}
      </SelectedPlan>
      <PaymentForm
        period={plan.recurrence}
        planType={plan.type}
        plan={plan._id}
        number_users={plan.number_users}
      />
    </Main>
  );
}
