import React, { useState } from "react";
import TextMask from "react-text-mask";
import DoneIcon from "@material-ui/icons/Done";
import ClearIcon from "@material-ui/icons/Clear";

import {
  TextInput,
  ImageInput,
  InputImgLabel,
  InputGroups,
  InputGroupsHeader,
  InputGroupsBody,
  FormBody,
  MaskedInput,
  PasswordPatternContainer,
} from "./style";
import AddIcon from "@material-ui/icons/Add";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { Colors } from "globalStyle";

/**
 * Default VIRTUA INPUT
 * @param {string} width
 * @param {string} label
 * @param {string} placeholder
 * @param {boolean} disabled
 */
export function Input(props) {
  const { name, label, left, width, type, helperText, error, ...rest } = props;
  if (type === "select") {
    const { children } = props;
    return (
      <TextInput
        left={left}
        width={width}
        helperText={helperText}
        error={error}
      >
        <label htmlFor={name}>{label}</label>
        <select id={name} name={name} {...rest}>
          {children}
        </select>
        {error ? <span> {helperText} </span> : null}
      </TextInput>
    );
  } else if (type === "file") {
    return (
      <TextInput
        left={left}
        width={width}
        helperText={helperText}
        error={error}
      >
        <label htmlFor={name}>{label}</label>
        <input id={name} name={name} type={type} {...rest} className="file" />
        {error ? <span> {helperText} </span> : null}
      </TextInput>
    );
  } else {
    return (
      <TextInput
        left={left}
        width={width}
        helperText={helperText}
        error={error}
      >
        <label htmlFor={name}>{label}</label>
        {type ? (
          <input id={name} name={name} type={type} {...rest} />
        ) : (
          <input id={name} name={name} {...rest} />
        )}
        {error ? <span> {helperText} </span> : null}
      </TextInput>
    );
  }
}

export function InputMask({
  name,
  label,
  left,
  width,
  mask,
  validate,
  validateText,
  error,
  value,
  inputMode,
  handleNext,
  onBlur,
  ...rest
}) {
  const [inputValidate, setInputValidate] = useState("");

  const handleValidate = async (e) => {
    if (validate) {
      let validatedValue = await validate(e.target.value);
      setInputValidate(
        validatedValue.card ? validatedValue.isValid : validatedValue
      );
    }
    // onBlur(e);
  };

  return (
    <MaskedInput masked left={left} width={width} error={error}>
      <label htmlFor={name}>{label}</label>
      <TextMask
        mask={mask}
        guide={false}
        id={name}
        name={name}
        type="text"
        inputMode={inputMode}
        value={value}
        {...rest}
        onBlur={(e) => {
          const element = document.getElementById(name);
          if (
            typeof handleNext == "function" &&
            (!handleNext(e, true) || element.value.length === 0)
          ) {
            e.preventDefault();
            element.focus();
          }
          handleValidate(e);
        }}
      />
      {error ? (
        <span> {validateText} </span>
      ) : inputValidate === false ? (
        <span>{validateText}</span>
      ) : null}
      {/* {inputValidate === false &&
        <span>{validateText}</span>
      } */}
    </MaskedInput>
  );
}

export function InputGroup(props) {
  return <InputGroups>{props.children}</InputGroups>;
}

export function InputGroupHeader(props) {
  return <InputGroupsHeader>{props.children}</InputGroupsHeader>;
}

export function InputGroupBody(props) {
  return (
    <InputGroupsBody direction={props.direction}>
      {props.children}
    </InputGroupsBody>
  );
}

/**
 * Name OBRIGATÓRIO
 * @param {string} name
 */
export function ImgInput(props) {
  const [fileName, setFileName] = useState(props.fileName ?? "");
  const [preview, setPreview] = useState(props.preview ?? "");

  function onHandleChange(event) {
    if (event.target.files.length > 0) {
      let { name } = event.target.files[0];
      let url = URL.createObjectURL(event.target.files[0]);
      setFileName(name);
      setPreview(url);
    }
  }
  return (
    <ImageInput className={props.className}>
      <InputImgLabel className="file-input">
        {props.preview || preview ? (
          <div className="mask">
            <img src={props.preview ?? preview} alt="previewfile" />
          </div>
        ) : null}

        <label htmlFor={props.name}>
          {!props.preview && !preview && <AddIcon />}
        </label>
      </InputImgLabel>
      <span>
        {props.fileName === "" && fileName === ""
          ? "Nenhum arquivo selecionado"
          : fileName
          ? fileName
          : props.fileName}
      </span>
      <input
        type="file"
        name={props.name}
        id={props.name}
        accept="image/*"
        onChange={props.onChange}
        onClick={(event) => (event.target.value = null)}
        onInput={(event) =>
          props.onHandleImageChange
            ? props.onHandleImageChange(event)
            : onHandleChange(event)
        }
      />
    </ImageInput>
  );
}

export function Form(props) {
  const { children, ...rest } = props;
  return <FormBody {...rest}> {children} </FormBody>;
}

export function RadioInput({ radioOptions, onChange, name, ...rest }) {
  return (
    <RadioGroup onChange={onChange} name={name} {...rest}>
      {radioOptions.map((opt) => (
        <FormControlLabel
          key={opt.value}
          value={opt.value}
          control={<Radio color="primary" />}
          label={opt.label}
        />
      ))}
    </RadioGroup>
  );
}

export function PasswordInput(props) {
  const {
    name,
    label,
    left,
    width,
    helperText,
    error,
    placeholder,
    type,
    showPattern,
    verificationText,
    ...rest
  } = props;

  const [showPassword, setShowPassword] = useState(false);

  const eightCharactersPattern = (txt) => {
    return txt.length > 0 ? new RegExp(/(?=.{8,})/).test(txt) : false;
  };

  const oneLowerCasePattern = (txt) => {
    return txt.length > 0 ? new RegExp(/(?=.*[a-z])/).test(txt) : false;
  };

  const oneUpperCasePattern = (txt) => {
    return txt.length > 0 ? new RegExp(/(?=.*[A-Z])/).test(txt) : false;
  };

  const oneNumericPattern = (txt) => {
    return txt.length > 0 ? new RegExp(/(?=.*[0-9])/).test(txt) : false;
  };

  const specialCharacterPattern = (txt) => {
    return txt.length > 0
      ? new RegExp(/(?=.*[.,#?!@$%^&*-])/).test(txt)
      : false;
  };

  const validations = [
    {
      label: "Deve conter no mínimo 8 caracteres",
      function: (txt) => eightCharactersPattern(txt),
    },
    {
      label: "Deve conter ao menos uma letra minúscula",
      function: (txt) => oneLowerCasePattern(txt),
    },
    {
      label: "Deve conter ao menos uma letra maiúscula",
      function: (txt) => oneUpperCasePattern(txt),
    },
    {
      label: "Deve conter ao menos um número",
      function: (txt) => oneNumericPattern(txt),
    },
    {
      label: "Deve conter um dos caracteres a seguir .,#?!@$%^&*-",
      function: (txt) => specialCharacterPattern(txt),
    },
    {
      label: "Deve conter senhas idênticas",
      function: (txt) => verificationText(txt),
    },
  ];

  return (
    <TextInput left={left} width={width} helperText={helperText} error={error}>
      <label htmlFor={name}>{label}</label>
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          flexWrap: "nowrap",
        }}
      >
        <input
          id={name}
          style={{ width: "100%" }}
          name={name}
          placeholder={placeholder}
          type={showPassword ? "text" : "password"}
          {...rest}
        />
        <div
          onClick={() => setShowPassword(!showPassword)}
          style={{ marginLeft: "-30px", cursor: "pointer", width: "24px" }}
        >
          {showPassword ? <Visibility /> : <VisibilityOff />}
        </div>
      </div>
      {error ? <span> {helperText} </span> : null}
      {showPattern &&
        validations.map((validation) => (
          <PasswordPatternContainer key={validation.label || ""}>
            {validation.function(rest.defaultValue || "") ? (
              <DoneIcon style={{ fontSize: "18px", color: Colors.darkGreen }} />
            ) : (
              <ClearIcon style={{ fontSize: "18px", color: Colors.red }} />
            )}
            <label>{validation.label || ""}</label>
          </PasswordPatternContainer>
        ))}
    </TextInput>
  );
}
