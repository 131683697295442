import React, { useEffect, useState } from "react";
import TriageHeader from "components/triage/header";
import TriageContainer from "components/triage/container";
import Main from "components/main";
import { useHistory, useParams } from "react-router-dom";
import api from "services/api";
import { toast } from "react-toastify";
import { calculateAge } from "helper";

export default function Triage({ location }) {
  const [triage, setTriage] = useState({ patient: { user: {} } });
  const history = useHistory();

  const { id } = useParams();
  const { queueId } = location.state;

  useEffect(() => {
    const getTriage = async () => {
      const response = await api.get("queue/triage/" + id);
      console.log(response.data)
      setTriage(response.data);
    };
    getTriage();
  }, [id]);

  const makePreference = async (id) => {
    try {
      await api.post("/queue/make-preference/" + id);
      toast.success("O paciente agora é preferencial");
      history.push("/fila");
    } catch (err) {
      toast.error(err.response.data.message);
    }
  };

  return (
    <Main noContainer>
      <TriageHeader
        name={triage.patient.user.name}
        tel={triage.patient.user.phone}
        age={triage.patient.birth ? calculateAge(triage.patient.birth) : ""}
        gender={
          triage.patient.gender === "MALE"
            ? "Masculino"
            : triage.patient.gender === "FEMALE"
            ? "Feminino"
            : null
        }
        makePreference={() => makePreference(queueId)}
      />
      <TriageContainer
        symptoms={triage.symptoms}
        drugs={triage.drugs}
        exams={triage.attachmentHasTriage}
        allergies={triage.allergies}
        diseases={triage.diseases}
      />
    </Main>
  );
}
