import React from "react";
import Header from "../header";

import { Container } from "@material-ui/core";

export default function Main(props) {
  const { noContainer, children, ...rest } = props;
  if (noContainer)
    return (
      <>
        <Header />
        {children}
        <footer />
      </>
    );

  //verificar se teremos outras opções para passar para o modelo padrão.
  return (
    <>
      <Header />
      <Container style={{ position: "relative", top: "62px" }} {...rest}>
        {props.children}
      </Container>
      <footer />
    </>
  );
}
