import React from "react";

import { Grid } from "@material-ui/core";
import { Card, CardHeader, CardNumber } from "components/card";
import ProgressBar from "components/progressBar";

export default function Financial() {
  return (
    <>
      <Grid container>
        <Grid item xs={8}>
          <p> Receita </p>
          <Grid container direction="row" spacing={1}>
            <Grid item xs sm md={4} lg={3}>
              <Card>
                <CardHeader label="Planos cadastrados" />
                <CardNumber> 9 </CardNumber>
              </Card>
            </Grid>
            <Grid item xs sm md={4} lg={3}>
              <Card>
                <CardHeader label="Total de clientes ativos" />
                <CardNumber> 9 </CardNumber>
              </Card>
            </Grid>
            <Grid item xs sm md={4} lg={3}>
              <Card>
                <CardHeader label="Total de benificiários" />
                <CardNumber> 9 </CardNumber>
              </Card>
            </Grid>
            <Grid item xs sm md={4} lg={3}>
              <Card>
                <CardHeader label="Média beneficiários por plano" />
                <CardNumber> 9 </CardNumber>
              </Card>
            </Grid>
            <Grid item xs sm md={4} lg={3}>
              <Card>
                <CardHeader label="Receita YTD" />
                <CardNumber color="green"> 9 </CardNumber>
              </Card>
            </Grid>
            <Grid item xs sm md={4} lg={3}>
              <Card>
                <CardHeader label="Receita MTD " />
                <CardNumber color="green"> 9 </CardNumber>
              </Card>
            </Grid>
            <Grid item xs sm md={4} lg={3}>
              <Card>
                <CardHeader label="Receita média por cliente" />
                <CardNumber color="green"> 9 </CardNumber>
              </Card>
            </Grid>
            <Grid item xs sm md={4} lg={3}>
              <Card>
                <CardHeader label="Receita média por beneficiário" />
                <CardNumber color="green"> 9 </CardNumber>
              </Card>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <p> Gestão da adimplência </p>
          <Grid container direction="row">
            <Grid item xl>
              <Card xlg="true">
                <CardHeader label="Status dos clientes" />
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item>
                    <CardHeader label="Adimplentes" />
                  </Grid>
                  <Grid item>
                    <CardNumber color="blue" fontSize="24px">
                      75%
                    </CardNumber>
                  </Grid>
                  <Grid item>
                    <Grid container direction="column">
                      <div>Total</div>
                      <CardNumber color="blue" fontSize="14px">
                        (1686)
                      </CardNumber>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item>
                    {" "}
                    <CardHeader label="Inadimplentes" />{" "}
                  </Grid>
                  <Grid item>
                    <CardNumber color="red" fontSize="24px">
                      25%
                    </CardNumber>
                  </Grid>
                  <Grid item>
                    <Grid container direction="column">
                      <div>Total</div>
                      <CardNumber color="red" fontSize="14px">
                        (637)
                      </CardNumber>
                    </Grid>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={8}>
          <p>Custos assistenciais </p>
          <Grid container direction="row" spacing={1}>
            <Grid item xs sm md={4} lg={3}>
              <Card>
                <CardHeader label="Total de médicos ativos" />
                <CardNumber> 9 </CardNumber>
              </Card>
            </Grid>
            <Grid item xs sm md={4} lg={3}>
              <Card>
                <CardHeader label="Média de disponibilidade de tempo por dia  " />
                <CardNumber> 9 </CardNumber>
              </Card>
            </Grid>
            <Grid item xs sm md={4} lg={3}>
              <Card>
                <CardHeader label="Total pago aos médicos" />
                <CardNumber color="red"> 9 </CardNumber>
              </Card>
            </Grid>
            <Grid item xs sm md={4} lg={3}>
              <Card>
                <CardHeader label="Valor médio pago aos médicos" />
                <CardNumber color="red"> 9 </CardNumber>
              </Card>
            </Grid>
            <Grid item xs sm md={4} lg={3}>
              <Card>
                <CardHeader label="Custo assistencial YTD" />
                <CardNumber color="red"> 9 </CardNumber>
              </Card>
            </Grid>
            <Grid item xs sm md={4} lg={3}>
              <Card>
                <CardHeader label="Custo assistencial MTD " />
                <CardNumber color="red"> 9 </CardNumber>
              </Card>
            </Grid>
            <Grid item xs sm md={4} lg={3}>
              <Card>
                <CardHeader label="Custo médio por cliente" />
                <CardNumber color="red"> 9 </CardNumber>
              </Card>
            </Grid>
            <Grid item xs sm md={4} lg={3}>
              <Card>
                <CardHeader label="Custo médio por beneficiário" />
                <CardNumber color="red"> 9 </CardNumber>
              </Card>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <p>Gestão do capital humano </p>
          <Grid container direction="row">
            <Grid item>
              <Card mb="10px">
                <CardHeader label="Total de médicos ativos" />
                <CardNumber> 9 </CardNumber>
              </Card>
            </Grid>
            <Grid item>
              <Card mb="10px">
                <CardHeader label="Total de médicos ativos" />
                <CardNumber> 9 </CardNumber>
              </Card>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item>
              <Card status>
                <CardHeader label="Status M.A" />
                <ProgressBar
                  data={[
                    {
                      name: "< 3 M",
                      value: 60,
                      color: "red",
                    },
                    {
                      name: "< 1 A",
                      value: 7,
                      color: "yellow",
                    },
                    {
                      name: "< 3 A",
                      value: 23,
                      color: "green",
                    },
                    {
                      name: "> 3 A",
                      value: 10,
                      color: "blue",
                    },
                  ]}
                />
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
