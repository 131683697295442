import React, { useEffect, useState } from "react";

import { Grid, Button } from "@material-ui/core";

import Main from "components/main";

import api from "services/api";
import { store } from "store";
import { formatNameForSearch } from "utils/input-validators";
import { Card, CardHeader, CardNumber } from "components/card";
import Search from "components/search";
import { BeneficiariesAdmTable } from "./table";
import Modal, { ModalBody, ModalFooter, ModalHeader } from "components/modal";
import { toast } from "react-toastify";

export const BeneficiariesAdmin = () => {
  const [beneficiaries, setBeneficiaries] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const registered = beneficiaries.length;
  const actives = beneficiaries.filter((b) => b.status).length;

  const [page, setPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(
    store.getState().auth.rows_per_page
  );

  const [toggleModal, setToggleModal] = useState(false);
  const [beneficiarySelected, setBeneficiarySelected] = useState({});
  const [updateStatus, setUpdateStatus] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const response = await api.get("/beneficiary");
      setBeneficiaries(response.data);
      setFiltered(response.data);
    };

    fetchData();
  }, []);

  const handleSearch = (e) => {
    if (e) {
      const { value } = e.target;
      const doc = value.replace(/[^\d]+/g, "");

      const filteredByName = beneficiaries.filter((b) =>
        formatNameForSearch(b.name).includes(formatNameForSearch(value))
      );

      const filteredByDoc = doc.length
        ? beneficiaries.filter((b) => b.cpf === value || b.cpf.startsWith(doc))
        : [];

      setFiltered([...filteredByName, ...filteredByDoc]);
      setPage(0);
    }
  };

  const handleModalChangeStatus = (beneficiary) => {
    setBeneficiarySelected(beneficiary);
    setUpdateStatus(false);
    setToggleModal(!toggleModal);
  };

  async function changeBeneficiaryStatus(beneficiary) {
    api
      .put(`/beneficiary/${beneficiary._id}`, {
        status: !beneficiary.status,
        cpf: beneficiary.cpf,
      })
      .then(() => {
        setUpdateStatus(true);
        toast.success("Status alterado com sucesso");
      })
      .catch((e) => {
        toast.error(e.response.data.message);
      });
  }

  return (
    <Main maxWidth="xl">
      <Modal show={toggleModal}>
        <ModalHeader
          close={
            !updateStatus
              ? handleModalChangeStatus
              : () => window.location.reload()
          }
        >
          Alterar Status
        </ModalHeader>
        <ModalBody>
          {!updateStatus ? (
            <p>
              Tem certeza que deseja{" "}
              {beneficiarySelected.status === true ? (
                <strong style={{ color: "red" }}>inativar</strong>
              ) : (
                <strong style={{ color: "green" }}>ativar</strong>
              )}{" "}
              {beneficiarySelected.name && (
                <strong>{beneficiarySelected.name}</strong>
              )}
              ?
            </p>
          ) : (
            <p>Status alterado com sucesso.</p>
          )}
        </ModalBody>
        <ModalFooter>
          {!updateStatus ? (
            <>
              <Button variant="outlined" onClick={handleModalChangeStatus}>
                {" "}
                Cancelar{" "}
              </Button>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => changeBeneficiaryStatus(beneficiarySelected)}
              >
                {" "}
                Confirmar{" "}
              </Button>
            </>
          ) : (
            <Button variant="outlined" onClick={() => window.location.reload()}>
              {" "}
              Fechar{" "}
            </Button>
          )}
        </ModalFooter>
      </Modal>

      <Grid container className="mt-3">
        <Card>
          <CardHeader label="Beneficiários Cadastrados" />
          <CardNumber color="blue">{registered}</CardNumber>
        </Card>
        <Card>
          <CardHeader label="Beneficiários Ativos" />
          <CardNumber color="green">{actives}</CardNumber>
        </Card>
      </Grid>

      <Grid container style={{ marginTop: "30px" }}>
        <Search label={`Buscar beneficiarios`} onChange={handleSearch} />
      </Grid>

      <Grid container style={{ marginTop: "30px" }}>
        <BeneficiariesAdmTable
          count={filtered.length}
          beneficiaries={filtered.slice(
            page * itemsPerPage,
            page * itemsPerPage + itemsPerPage
          )}
          page={page}
          setPage={setPage}
          itemsPerPage={itemsPerPage}
          setItemsPerPage={setItemsPerPage}
          changeStatus={handleModalChangeStatus}
        />
      </Grid>
    </Main>
  );
};
