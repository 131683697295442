import styled from "styled-components";

export const Container = styled.div`
  max-width: 580px;
`;

export const ContainerLinks = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 15px 0;

  img {
    width: 160px;
  }
  img:first-child {
    margin-right: 15px;
  }
`;

export const ContainerAgree = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    margin: 0;
  }
`;
