import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import MenuIcon from "@material-ui/icons/Menu";

import ProfileMenu from "components/headerProfile";

import { Grid, Hidden } from "@material-ui/core";
import {
  Navbar,
  Logo,
  HeaderContainer,
  NavItem,
  MobileMenu,
  Content,
} from "./style";

import { store } from "store";

import menus from "./menu.json";
import { useDispatch } from "react-redux";

export default function Header(props) {
  const { name, profile, profile_pic, type } = store.getState().user;
  const dispatch = useDispatch();
  const [mobileOpen, setMobileOpen] = useState(false);

  const logout = () => {
    dispatch({
      type: "@user/LOGOUT",
    });
  };

  useEffect(() => {
    const resizeListener = () => {
      window.requestAnimationFrame(() => {
        return window.innerWidth > 960 && mobileOpen
          ? setMobileOpen(false)
          : null;
      });
    };
    document.documentElement.style.setProperty(
      "--scroll",
      mobileOpen ? "hidden" : "auto"
    );

    window.addEventListener("resize", resizeListener);

    return () => {
      window.removeEventListener("resize", resizeListener);
    };
  }, [mobileOpen]);

  return (
    <>
      <header>
        <Navbar>
          <nav>
            <HeaderContainer maxWidth="xl">
              <Grid container>
                <Logo>
                  <Link to="/">
                    <h1>VIRTUA</h1>
                  </Link>
                </Logo>
                <Hidden smDown>
                  {props.onlyLogo ? null : (
                    <Content>
                      {type === "PF"
                        ? menus[type].map((menu) => (
                            <NavItem key={menu.name}>
                              <NavLink
                                activeClassName="is-active"
                                to={menu.path}
                              >
                                {menu.name}
                              </NavLink>
                            </NavItem>
                          ))
                        : menus[`${profile}`].map((menu) => (
                            <NavItem key={menu.name}>
                              <NavLink
                                activeClassName="is-active"
                                to={menu.path}
                              >
                                {menu.name}
                              </NavLink>
                            </NavItem>
                          ))}
                      <ProfileMenu
                        name={name}
                        occupation={profile}
                        avatar={profile_pic}
                      />
                    </Content>
                  )}
                </Hidden>
                <Hidden mdUp>
                  <MenuIcon
                    style={{
                      color: "#A7A9BC",
                      marginTop: "20px",
                      cursor: "pointer",
                    }}
                    onClick={() => setMobileOpen(!mobileOpen)}
                  />
                </Hidden>
              </Grid>
            </HeaderContainer>
          </nav>
        </Navbar>
      </header>
      <MobileMenu show={mobileOpen}>
        {props.onlyLogo ? null : (
          <div>
            {menus[`${profile}`].map((menu) => (
              <NavItem key={menu.name}>
                <NavLink activeClassName="is-active" to={menu.path}>
                  {menu.name}
                </NavLink>
              </NavItem>
            ))}
          </div>
        )}
        <span onClick={logout}>Sair</span>
      </MobileMenu>
    </>
  );
}
