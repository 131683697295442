import { Grid } from '@material-ui/core'
import styled from 'styled-components'

export const SelectedPlan = styled(Grid)`
  background: #FFFFFF;
  border: 1px solid #F1F2F5;
  box-sizing: border-box;
  border-radius: 10px;
  max-width: 680px;
  padding-bottom: 24px;
  padding-left: 24px;
  padding-right: 24px;
  color: #6B6C7E;

  label {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 150%;
    color: #6B6C7E;
    margin-top: 24px;
  }

  strong {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 150%;
  }
`

export const GridItem = styled(Grid)`
  display: flex;
  flex-direction: column;
  strong {
    color: ${props=>props.color}
  }
`