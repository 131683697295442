import { Grid, Hidden } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import api from "services/api";
import FinishIcon from "assets/icons/finish.png";
import { FinishContainer } from "./style";

export default function ValidateEmail() {
  const { token } = useParams();
  const [user, setUser] = useState(null);
  const history = useHistory();

  useEffect(() => {
    try {
      const getUser = async () => {
        const response = await api.post("user/validate-email/", null, {
          headers: {
            Authorization: "Bearer " + token,
          },
        });
        setUser(response.data);
      };
      getUser();
    } catch (error) {}
  }, [token]);

  return (
    <Grid container>
      <Grid item xs={12} md={6} style={{ height: "100vh" }}>
        {user && user.status === "ACTIVE" && (
          <FinishContainer>
            <img src={FinishIcon} alt="finish" />
            <h3>E-mail validado com sucesso!</h3>
            <p>Agora realize o login e escolha o plano que deseja</p>
            <button type="button" onClick={() => history.push("/login")}>
              Login
            </button>
          </FinishContainer>
        )}
        {user && user.status !== "ACTIVE" && (
          <FinishContainer>
            <h3>O E-mail já foi validado!</h3>
            <p>Seu cadastro já está ativo na plataforma</p>
            <button type="button" onClick={() => history.push("/login")}>
              Login
            </button>
          </FinishContainer>
        )}
      </Grid>
      <Hidden smDown>
        <Grid
          item
          xs={6}
          style={{
            background:
              "url(https://cmtecnologia.com.br/wp-content/uploads/2019/06/hospital-digital.jpg)",
            transform: "scaleX(-1)",
            backgroundSize: "cover",
            backgroundPosition: "right top",
            height: "100vh",
          }}
        ></Grid>
      </Hidden>
    </Grid>
  );
}
