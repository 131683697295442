import produce from "immer";

const INITIAL_STATE = {
  name: null,
  profile: null,
  _id: null,
  profile_pic: null,
  cpfcnpj: null,
  type: null
};

export default function theme(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case "@user/LOGIN": {
        draft.name = action.payload.name;
        draft.profile = action.payload.profile;
        draft._id = action.payload.id;
        draft.profile_pic = action.payload.profile_pic;
        draft.cpfcnpj = action.payload.cpfcnpj;
        draft.type = action.payload.type;
        if (action.payload.profile === "CLIENTE")
          document.location.href =
            action.payload.cpfcnpj.length > 11 ? "/beneficiarios" : "/planos";
        if (
          action.payload.profile === "GESTOR" ||
          action.payload.profile === "OPERADOR"
        )
          document.location.href = "/";
        break;
      }
      case "@user/LOGOUT": {
        draft.name = null;
        draft.profile = null;
        break;
      }
      default:
        return state;
    }
  });
}
