import React from "react";

import { Form, InputContainer, Title } from "./style";
import { InputMask, Input, ImgInput } from "components/inputs";
import { cpfMask, dateMask, phoneMask } from "utils/masks";
import { Grid } from "@material-ui/core";

export default function AddMultipleForm({
  beneficiaryForm,
  handleChange,
  handleSubmit,
  plansValidate,
  handleFileChange,
  onHandleImageChange,
  preview,
  fileName,
}) {
  return (
    <Form onSubmit={handleSubmit}>
      <Title>
        <h3>Adicionar novo beneficiário titular</h3>
        <button type="submit">Inserir</button>
      </Title>
      <InputContainer container spacing={1}>
        <Grid container alignItems="center" xs={12}>
          <Grid item xs={12} md={3} sm={12}>
            <ImgInput
              className="file-container"
              onChange={handleFileChange}
              name="profile_pic"
              onHandleImageChange={onHandleImageChange}
              fileName={fileName}
              preview={preview}
            />
          </Grid>
          <Grid item xs={12} md={6} sm={12}>
            <span
              style={{
                flexDirection: "row",
                fontSize: "11px",
                color: "red",
                opacity: "0.7",
              }}
            >
              *Recomenda-se inserir uma foto quadrada
            </span>
          </Grid>
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <InputMask
            label="CPF"
            inputMode="numeric"
            name="cpf"
            value={beneficiaryForm.cpf}
            onChange={handleChange}
            onBlur={() => {}}
            required
            mask={cpfMask}
            validateText={plansValidate.cpf?.helperText}
            error={plansValidate.cpf?.error}
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <Input
            label="Nome"
            name="name"
            width="100%"
            value={beneficiaryForm.name}
            onChange={handleChange}
            helperText={plansValidate.name?.helperText}
            error={plansValidate.name?.error}
            required
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <Input
            label="Email"
            name="email"
            inputMode="email"
            width="100%"
            value={beneficiaryForm.email.toLowerCase()}
            onChange={(e) => {
              e.target.value = e.target.value.toLowerCase();
              handleChange(e);
            }}
            helperText={plansValidate.email?.helperText}
            error={plansValidate.email?.error}
            required
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <InputMask
            label="Celular"
            name="telephone"
            inputMode="tel"
            value={beneficiaryForm.telephone}
            onChange={handleChange}
            onBlur={() => {}}
            mask={phoneMask}
            validateText={plansValidate.telephone?.helperText}
            error={plansValidate.telephone?.error}
            required
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <InputMask
            label="Data de nascimento"
            inputMode="numeric"
            name="birth_date"
            value={beneficiaryForm.birth_date}
            onChange={handleChange}
            onBlur={() => {}}
            mask={dateMask}
            validateText={plansValidate.birth_date?.helperText}
            error={plansValidate.birth_date?.error}
            required
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <Input
            label="Gênero"
            type="select"
            name="genre"
            width="100%"
            value={beneficiaryForm.genre}
            onChange={handleChange}
            required
          >
            <option value="Masculino">Masculino</option>
            <option value="Feminino">Feminino</option>
          </Input>
        </Grid>
      </InputContainer>
    </Form>
  );
}
