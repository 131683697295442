import styled from "styled-components";
import { StatusColors, Colors } from "globalStyle";

export const StatusBar = styled.div`
  position: fixed;
  top: 62px;
  z-index: 900;
  height: 82px;
  width: 100%;
  max-width: 100%;

  display: flex;
  flex-direction: row;

  border-top: 1px solid #dfe1e6;
  border-bottom: 1px solid #dfe1e6;
  align-items: center;

  background-color: #ffffff;

  /* container */
  flex-wrap: wrap;
  & > div {
    display: flex;
    flex-direction: row;
    height: 82px;
  }
  .align-center {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;

export const User = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  margin-left: 30px;
  height: 82px;
  align-items: center;
  img {
    border-radius: 50%;
    width: 50px;
    height: 50px;
  }
`;

export const UserData = styled.div`
  max-height: 100%;
  display: flex;
  flex-direction: column;
  height: 82px;
  padding-left: 15px;
  margin-right: 33px;
  margin-top: 1.5em;

  h5 {
    margin: 0;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 28px;
  }
  h6 {
    margin: 0;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 28px;
  }
  span {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: ${Colors.whiteGrey};
  }
`;

export const Status = styled.div`
  /* background: #EDF9F0; */
  margin-left: 33px;
  height: 25px;
  background-color: ${(props) => StatusColors[props.status].background};
  color: ${(props) => StatusColors[props.status].color};
  border-radius: 12.5px;
  span {
    text-align: center;
    text-transform: uppercase;
    font-size: 10px;
    line-height: 150%;
    padding: 0 1.25em 0 1.25em;
  }
`;

export const Optionals = styled.div`
  margin-left: auto;
  align-self: center;
  display: flex;
  flex-direction: row;
`;
