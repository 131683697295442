import { Colors } from 'globalStyle'
import styled from 'styled-components'

// export const Header = styled.header`
//   position:relative;
//   display:flex;
//   align-items:center;
//   top: 0;
//   height: 64px;
//   width: 100%;
//   flex-grow:1;
//   left:0;

//   div {
//     margin-left:40px;
//   }

// `
export const AddressInfo = styled.div`
  h6{
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    color: ${Colors.darkGrey};
    margin: 0;
    padding: 0;
  }
  p{
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    margin-top: 4px;
    margin-bottom: 16px;
  }

`

export const Form = styled.form`
  display:flex;
  justify-content:center;
  align-items: center;
  height:100%;

  .slick-track {
    display: flex;
  }
  .slick-track .slick-slide {
    display: flex;
    height: auto;
    width: 100%;
  }

  .slick-track .slick-slide > div {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin:auto;
    height: auto;

    @media(max-width: 960px) {
      margin:0;
    }
  }

  .slick-prev, .slick-next {
    display:none !important;
  }
  
  @media(max-width: 960px) {
    align-items: flex-start;
    height: calc(100% - 64px);
  }
`

export const Button = styled.button`
  height: 45px;
  width: 100%;
  background-color: ${Colors.blue};
  outline: none;
  margin: 0;
  border: none;
  color: white;
  font-size: 16px;
  font-weight: 600;
  justify-self: flex-end;
  border-radius: 22.5px;
  cursor: pointer;
`

export const DesktopButton = styled.button`
  height: 45px;
  background: ${props=>props.back ? "#FFFFFF": Colors.blue};
  border: ${props=>props.back ? "1px solid #6B6C7E" : "1px solid "+Colors.blue};
  color: ${props=>props.back ? Colors.darkGrey : "#FFFFFF"};
  box-sizing: border-box;
  border-radius: 4px;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  width: 49%;
  cursor: pointer;
`

export const DesktopButtons = styled.div`
  margin-top: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const Header = styled.header`
  position:relative;
  display:flex;
  align-items:center;
  top: 0;
  height: 64px;
  width: 100%;
  flex-grow:1;
  left:0;

  div {
    margin-left:40px;
  }

`

export const FinishContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  h3{
    margin-top: 24px;
    margin-bottom: 0;
  }
  p {
    margin-top: 4px;
    color: ${Colors.darkGrey}
  }
  button {
    width: 70%;
    height: 45px;
    background-color: ${Colors.blue};
    color: white;
    outline: none;
    border:none;
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
    text-align: center;
    cursor: pointer;
  }
`