import styled from 'styled-components';
import { TextField } from '@material-ui/core';

export const SearchField = styled(TextField)`
  width:100%;
  max-width: 330px;
  height: 32px;
  border: 1px solid #e7e7ed;
  box-sizing: border-box;
  border-radius: 4px;
  background-color: #f1f2f5;
  input {
    background-color: #f1f2f5;
    max-width: 100%;
    max-height: 100%;
    min-width: 215px;
    padding: 0; 
    padding-left: 1em; 
  }
  &> div {
    background-color: #f1f2f5!important;
    width:100%;
    max-width: 330px;
    height: 32px;
  }
  .MuiInputBase-root  { 
    background-color: #f1f2f5!important;
  }
  label { 
    top: -10px; 
  }
`;
