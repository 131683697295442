import styled from "styled-components";
import { Container } from "@material-ui/core";

export const FieldContainer = styled(Container)`
  min-height: 100%;
  max-height: 100vh;
  overflow-y: auto;
  h3 {
    margin: 0;
    padding: 0;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    color: #272833;
  }

  p.desc {
    margin-top: 12px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    text-align: center;
    color: #393a4a;
    margin-bottom: 32px;
  }

  div {
    width: 100%;
    input {
      width: 100%;
      max-width: ${(props) => (props.width ? props.width : "100%")};
      @media (max-width: 960px) {
        max-width: 100%;
      }
    }
  }

  input {
    margin: 0;
  }
`;
