import { Colors } from 'globalStyle';
import styled from 'styled-components'

export const PlanPaper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  h3 {
    color: #2e5aac;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 150%;
    text-transform: capitalize;
    height: 100%;
    padding: 0;
    margin: 0;
  }
  h4 {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 150%;
    color: ${Colors.dark};
    margin: 0;
    padding: 0;
    margin: 0;
  }
  p {
    margin-top: 4px;
    margin-bottom: 38px;
  }

  .plan-name, .plan-hiredOn, .plan-period, .plan-nextPayment,  .plan-validity {
    color: #2E5AAC;
    font-size: 16px;
    line-height: 24px;
  }

  .plan-name, .plan-monthValue {
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
  }

  .plan-monthValue {
    color: ${Colors.darkGreen};
  }
`;

export const Manage = styled.button`
  background: #FFFFFF;
  border: 1px solid #3089C3;
  box-sizing: border-box;
  border-radius: 4px;
  height: 32px;
  text-align: center;
  color: #3089C3;
  margin-bottom: 38px;
  width: 100%;
`

