import styled from "styled-components";
import { StatusColors } from "globalStyle";

export const ColorizedStatus = styled.div`
  /* background: #EDF9F0; */
  /* margin-left: 33px; */
  height: 25px;
  background-color: ${(props) =>
    StatusColors[props.status] ? StatusColors[props.status].background : null};
  color: ${(props) =>
    StatusColors[props.status] ? StatusColors[props.status].color : null};
  border-radius: 12.5px;
  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: center;

  span {
    text-align: center;
    text-transform: uppercase;
    font-size: 10px;
    line-height: 150%;
    padding: 0 1.25em 0 1.25em;
  }
`;
