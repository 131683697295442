import styled from 'styled-components'

export const ReceiptButton = styled.button`
  background-color: white;
  border: 1px solid #CDCED9;
  width: 100px;
  height: 40px;
  cursor: pointer;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  color: #6B6C7E;
  margin: 0px 5px;
`