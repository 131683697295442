import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Tabs,
  TableFooter,
  TablePagination,
} from "@material-ui/core";

import { Options } from "./style";

import Main from "components/main";
import { Input, InputMask } from "components/inputs";
import { Card, PlanCard, CardHeader, CardNumber } from "components/card";
import Modal, { ModalBody, ModalFooter, ModalHeader } from "components/modal";
import { TabPanel, FullTab } from "components/tabs";
import Search from "components/search";
import Reports from "components/reports";
import { formatNameForSearch } from "utils/input-validators";

import Status from "components/status";
import api from "services/api";
import { formatDate, formatCpfCnpj, formatPhone } from "helper";
import { cpfMask, phoneMask } from "utils/masks";
import { cpfValidator } from "utils/input-validators";
import { toast } from "react-toastify";
import defaultPlanValidator, { inputValidations } from "utils/plans-validator";

export default function Admin() {
  const planInitialState = {
    id: "",
    name: "",
    number_users: "",
    per_user: "",
    mensality: "",
    anuality: "",
    semestral: "",
    trimestral: "",
    start_date: "",
    expired_date: "",
    type: "PF",
    secondaryPlan: ""
  };
  const defaultPlanValidate = defaultPlanValidator();
  const [plansValidate, setPlansValidate] = useState(defaultPlanValidator);
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [plans, setPlans] = useState([]);
  const [plansData, setPlansData] = useState([]);

  const [admins, setAdmins] = useState([]);
  const [data, setData] = useState([]);
  const [value, setValue] = useState(0);
  const history = useHistory();

  const [form, setForm] = useState(planInitialState);

  const [adminForm, setAdminForm] = useState({
    name: "",
    cpfcnpj: "",
    email: "",
    telephone: "",
    profile: "OPERADOR",
  });

  //PAGINAÇÃO
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  //FIM PAGINAÇÃO

  async function getPlans() {
    const response = await api.get("/plan");
    const dynamicResponse = await api.get("/dynamic-plan");

    const combinedArray = [...response.data, ...dynamicResponse.data];
    const allPlansActiveStatus = combinedArray.filter((plan) => plan.status === "ACTIVE")
    setPlans(allPlansActiveStatus);
    setPlansData(allPlansActiveStatus);
  }

  async function getAdmins() {
    try {
      const response = await api.get("/user");
      const allAdmins = response.data.filter(
        (admin) =>
          admin.profile === "ADMIN" ||
          admin.profile === "OPERADOR" ||
          admin.profile === "GESTOR"
      );
      setAdmins(allAdmins);
      setData(allAdmins);
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    getPlans();
    getAdmins();
  }, []);

  const handleOpen = () => {
    setForm(planInitialState);
    setPlansValidate(defaultPlanValidate);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEdit(false);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const deletePlan = async (id, type, number_users) => {
    try {
      if (window.confirm("Você realmente quer excluir esse plano?")) {
        let apiUrl = null;
        if ((type === "PJ" || type === "MULTIPLAN") && !number_users) {
          apiUrl = "dynamic-plan";
        } else {
          apiUrl = "plan";
        }
        await api.delete(apiUrl + "/" + id);
        getPlans();
      }
    } catch (err) {
      toast.error(err.response.data.message);
    }
  };

  const handleEdit = (event, newValue) => {
    const editablePlan = plans.filter((plan) => plan._id === newValue)[0];
    editablePlan.expired_date = formatDate(editablePlan.expired_date, true);
    editablePlan.start_date = formatDate(editablePlan.start_date, true);
    editablePlan.id = editablePlan._id;
    setForm(editablePlan);
    toggleEdit();
  };

  const toggleEdit = (event) => {
    setEdit(!edit);
  };

  const formChange = (e) => {
    const { name, value } = e.target;
    const newForm = { ...form, [name]: value };
    setForm(newForm);
    if (plansValidate !== defaultPlanValidate)
      setPlansValidate(defaultPlanValidate);
  };

  const validatePlanForm = (newForm) => {
    const validation = inputValidations(newForm);
    if (validation !== true) {
      setPlansValidate(validation);
      toast.error("Verifique os campos em vermelho");
      return false;
    } else {
      setPlansValidate(defaultPlanValidate);
      return true;
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const validation = validatePlanForm(form);
      if (validation === true) {
        const reqForm = Object.assign({}, form, {
          mensality: parseFloat(
            typeof form.mensality === "string"
              ? form.mensality.replace(",", ".")
              : form.mensality
          ),
          anuality: parseFloat(form.anuality),
          trimestral: parseFloat(form.trimestral),
          semestral: parseFloat(form.semestral),
        });

        if(form.secondaryPlan === '') delete reqForm.secondaryPlan

        if (form.type === "PJ" || form.type === "MULTIPLAN") {
          reqForm.min_users =
            form.type === "PJ" ? parseFloat(form.min_users) : 0;
          reqForm.max_users =
            form.type === "PJ" ? parseFloat(form.max_users) : 0;

          if (edit) {
            await api.put("/dynamic-plan/" + reqForm.id, reqForm);
            handleClose();
            toast.success("Plano editado com sucesso");
          } else {
            await api.post("/dynamic-plan", reqForm);
            toast.success("Plano criado com sucesso");
            handleClose();
          }
        } else {
          reqForm.per_user = parseFloat(form.per_user);
          reqForm.number_users = parseFloat(form.number_users);

          if (edit) {
            await api.put("/plan/" + reqForm.id, reqForm);
            handleClose();
            toast.success("Plano editado com sucesso");
          } else {
            await api.post("/plan", reqForm);
            handleClose();
            toast.success("Plano criado com sucesso");
          }
        }

        getPlans();
      }
    } catch (err) {
      toast.error(err.response.data.message);
    }
  };

  const handleAdminFormChange = (e) => {
    setAdminForm({ ...adminForm, [e.target.name]: e.target.value });
  };

  const handleAdminFormSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!cpfValidator(adminForm.cpfcnpj)) return;
      const formatedCpf = adminForm.cpfcnpj.replace(/\D+/g, "");
      const formatedPhone = adminForm.telephone.replace(/\D+/g, "");
      const formatedForm = Object.assign({}, adminForm, {
        cpfcnpj: formatedCpf,
        telephone: formatedPhone,
      });

      const response = await api.post("/user/preadmin", formatedForm);

      if (response.status === 201) {
        toast.success(
          "Administrador cadastrado com sucesso, email enviado para que o mesmo finalize o cadastro"
        );
        getAdmins();
        handleClose();
        setAdminForm({
          name: "",
          cpfcnpj: "",
          email: "",
          telephone: "",
          profile: "OPERADOR",
        });
      }
    } catch (err) {
      toast.error(err.response.data.message);
    }
  };


  const handleSearchPlans = (e) => {
    const { value } = e.target;
    const searchByName = plans.filter((obj) =>
      formatNameForSearch(obj.name).includes(formatNameForSearch(value))
    );
    const search = [...searchByName];
    setPlansData(search);
  };

  const handleSearch = (e) => {
    const { value } = e.target;
    const doc = value.replace(/[^\d]+/g, "");

    const searchByName = admins.filter((obj) =>
      formatNameForSearch(obj.name).includes(formatNameForSearch(value))
    );
    const searchByDoc = doc.length
      ? admins.filter(
          (obj) => obj.cpfcnpj === value || obj.cpfcnpj.startsWith(doc)
        )
      : [];

    const search = [...searchByDoc, ...searchByName];

    // let search = admins.filter(
    //   (obj) => obj.cpfcnpj === value || obj.cpfcnpj.startsWith(value)
    // );
    // if (search.length === 0)
    //   search = admins.filter((obj) =>
    //     obj.name.toLowerCase().includes(value.toLowerCase())
    //   );

    setData(search);
    setPage(0);
  };

  return (
    <Main maxWidth="xl">
      <Tabs
        indicatorColor="primary"
        textColor="primary"
        aria-label="disabled tabs example"
        onChange={handleChange}
        value={value}
      >
        <FullTab width="33%" label="Acessos" />
        <FullTab width="33%" label="Gestão de planos" />
        <FullTab width="33%" label="Relatórios" />
      </Tabs>
      {/* Acessos */}
      <TabPanel value={value} index={0}>
        <Grid container className="mt-3">
          <Card>
            <CardHeader label="Usuários cadastrados" />
            <CardNumber color="darkGrey"> {admins.length} </CardNumber>
          </Card>
          <Card>
            <CardHeader label="Usuários ativos" />
            <CardNumber color="blue">
              {admins.filter((admin) => admin.status === "ACTIVE").length}{" "}
            </CardNumber>
          </Card>
          <Card>
            <CardHeader label="Usuários online" />
            <CardNumber color="green"> 1 </CardNumber>
          </Card>
        </Grid>
        <Options container>
          <Search label="Médico ou CPF/CNPJ" onChange={handleSearch} />
          <Button variant="outlined" onClick={handleOpen}>
            Novo Cadastro
          </Button>
          <Modal show={open} onSubmit={handleAdminFormSubmit}>
            <ModalHeader close={handleClose}>
              Cadastrar Administradores
            </ModalHeader>
            <ModalBody>
              <Input
                name="profile"
                placeholder="Admin"
                label="Tipo"
                width="100%"
                type="select"
                onChange={handleAdminFormChange}
                left="0"
              >
                <option value="OPERADOR"> Operador </option>
                <option value="GESTOR"> Gestor </option>
              </Input>
              <Input
                name="name"
                onChange={handleAdminFormChange}
                placeholder="Nome"
                width="100%"
                label="Nome"
                left="0"
              />
              <InputMask
                name="cpfcnpj"
                mask={cpfMask}
                validate={cpfValidator}
                onChange={handleAdminFormChange}
                placeholder="CPF"
                value={adminForm.cpfcnpj}
                width="100%"
                label="CPF"
                onBlur={() => {}}
                inputMode="numeric"
                validateText="CPF inválido"
              />
              <Input
                name="email"
                inputMode="email"
                placeholder="Email"
                value={adminForm.email.toLowerCase()}
                label="Email"
                width="100%"
                type="email"
                onChange={(e) => {
                  e.target.value = e.target.value.toLowerCase();
                  handleAdminFormChange(e);
                }}
                left="0"
              />
              <InputMask
                name="telephone"
                inputMode="tel"
                label="Telefone"
                width="100%"
                mask={phoneMask}
                placeholder="Telefone"
                value={adminForm.telephone}
                onBlur={() => {}}
                onChange={handleAdminFormChange}
              />
            </ModalBody>
            <ModalFooter cancel={handleClose} next="Enviar" />
          </Modal>
        </Options>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center" width="200">
                  Nome
                </TableCell>
                <TableCell align="center" width="200">
                  CPF
                </TableCell>
                <TableCell align="center" width="200">
                  E-mail
                </TableCell>
                <TableCell align="center" width="200">
                  Telefone
                </TableCell>
                <TableCell align="center" width="200">
                  Nível de acesso
                </TableCell>
                <TableCell align="center" width="200">
                  Status do cadastro
                </TableCell>
                <TableCell align="center" width="200">
                  Opções
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((admin) => (
                  <TableRow key={admin._id}>
                    <TableCell align="center">{admin.name}</TableCell>
                    <TableCell align="center">
                      {formatCpfCnpj(admin.cpfcnpj)}
                    </TableCell>
                    <TableCell align="center">{admin.email}</TableCell>
                    <TableCell align="center">
                      {formatPhone(admin.telephone)}
                    </TableCell>
                    <TableCell align="center">{admin.profile}</TableCell>
                    <TableCell align="center">
                      <Status
                        status={
                          admin.status !== "INACTIVE" &&
                          admin.status !== "PRE_REGISTER"
                            ? "ACTIVE"
                            : admin.status
                        }
                      />
                    </TableCell>
                    <TableCell align="center">
                      <Button
                        variant="outlined"
                        onClick={() =>
                          history.push(`/administrador/${admin._id}`)
                        }
                      >
                        Detalhes
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  // colSpan={3}
                  count={admins.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: { "aria-label": "Itens por página" },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </TabPanel>
      {/* Planos */}
      <TabPanel value={value} index={1}>
        <Options container>
          <Search label={`Buscar Planos`} onChange={handleSearchPlans} />
          <Button id="plan" variant="outlined" onClick={handleOpen}>
            Novo Plano
          </Button>
          <Modal
            show={open || edit}
            onSubmit={handleSubmit}
            action={open ? "/plans" : "/plans/edit"}
            autoComplete="off"
          >
            <ModalHeader close={handleClose}>
              {open ? "Novo Plano" : `Editando Plano: ${form.name}`}{" "}
            </ModalHeader>
            <ModalBody>
              <Input
                name="name"
                placeholder="Plano familiar 1"
                label="Nome"
                width="100%"
                onChange={formChange}
                value={form.name ?? ""}
                helperText={plansValidate.name.helperText}
                error={plansValidate.name.error}
                left="0"
              />
              <Input
                name="type"
                placeholder="Tipo"
                label="Tipo"
                width="100%"
                type="select"
                value={form.type ?? ""}
                onChange={formChange}
                left="0"
              >
                <option value="PF"> Pessoa física </option>
                <option value="PJ"> Pessoa jurídica </option>
                <option value="MULTIPLAN"> Pessoa física - multiplano </option>
              </Input>
              {form.type === "PF" && (
                <Input
                  name="number_users"
                  placeholder="1"
                  label="Quantidade de beneficiários"
                  width="100%"
                  type="number"
                  onChange={formChange}
                  value={form.number_users ?? ""}
                  helperText={plansValidate.number_users.helperText}
                  error={plansValidate.number_users.error}
                  left="0"
                />
              )}
              {form.type === "PJ" && (
                <>
                  <Input
                    name="min_users"
                    placeholder="1"
                    label="Mínimo de beneficiários"
                    width="100%"
                    type="number"
                    onChange={formChange}
                    value={form.min_users ?? ""}
                    helperText={plansValidate.min_users.helperText}
                    error={plansValidate.min_users.error}
                    left="0"
                  />
                  <Input
                    name="max_users"
                    placeholder="1"
                    label="Máximo de beneficiários (Insira 0 para infinito)"
                    width="100%"
                    type="number"
                    onChange={formChange}
                    value={form.max_users ?? ""}
                    helperText={plansValidate.max_users.helperText}
                    error={plansValidate.max_users.error}
                    left="0"
                  />
                </>
              )}
              {/* <Input
                name='per_user'
                placeholder='1'
                label='Consultas por beneficiários'
                width='100%'
                type='number'
                onChange={formChange}
                value={form.per_user ?? ''}
                helperText={plansValidate.per_user.helperText}
                error={plansValidate.per_user.error}
              /> */}
              <Input
                name="mensality"
                label="Valor mensal"
                placeholder="R$ 200,00"
                type="number"
                width="100%"
                onChange={formChange}
                value={form.mensality ?? ""}
                helperText={plansValidate.mensality.helperText}
                error={plansValidate.mensality.error}
                left="0"
              />
              <span> Descontos </span>
              <Grid container spacing={2}>
                <Grid item>
                  <Input
                    name="anuality"
                    label="Anual"
                    type="number"
                    placeholder="30%"
                    width="135px"
                    onChange={formChange}
                    value={form.anuality ?? ""}
                    helperText={plansValidate.anuality.helperText}
                    error={plansValidate.anuality.error}
                    left="0"
                  />
                </Grid>
                <Grid item>
                  <Input
                    name="semestral"
                    label="Semestral"
                    type="number"
                    width="135px"
                    placeholder="30%"
                    onChange={formChange}
                    value={form.semestral ?? ""}
                    helperText={plansValidate.semestral.helperText}
                    error={plansValidate.semestral.error}
                    left="0"
                  />
                </Grid>
                <Grid item>
                  <Input
                    name="trimestral"
                    label="Trimestral"
                    type="number"
                    width="135px"
                    placeholder="30%"
                    onChange={formChange}
                    value={form.trimestral ?? ""}
                    helperText={plansValidate.trimestral.helperText}
                    error={plansValidate.trimestral.error}
                    left="0"
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Input
                    label="Inicío de vigência"
                    type="date"
                    width="100%"
                    name="start_date"
                    onChange={formChange}
                    value={form.start_date}
                    error={plansValidate.start_date.error}
                    helperText={plansValidate.start_date.helperText}
                    left="0"
                  />
                </Grid>
                <Grid item xs={6}>
                  <Input
                    label="Fim de vigência"
                    type="date"
                    width="100%"
                    name="expired_date"
                    onChange={formChange}
                    value={form.expired_date}
                    helperText={plansValidate.expired_date.helperText}
                    error={plansValidate.expired_date.error}
                    left="0"
                  />
                </Grid>
              </Grid>
              <Grid item>
                <Input
                  name="secondaryPlan"
                  placeholder="Plano Secundário"
                  label="Plano Secundário"
                  width="100%"
                  type="select"
                  {...(!!form.secondaryPlan && { value: form.secondaryPlan })}
                  onChange={formChange}
                  left="0"
                >
                  <option selected={!form.secondaryPlan} disabled> Selecione um Plano </option>
                  {plans.map((plan) => (
                    <option selected={form.secondaryPlan === plan._id} value={plan._id}> {plan.name} </option>
                  ))}
                </Input>
              </Grid>
       
            </ModalBody>
            <ModalFooter cancel={handleClose} next="Enviar" />
          </Modal>
        </Options>
        <Grid container spacing={2}>
          {plansData.map((plan) => (
            <Grid item md={4} sm={6} xs={12} key={plan._id}>
              <Card lg style={{ width: "100%" }}>
                <CardHeader
                  pen={(event) => handleEdit(event, plan._id)}
                  trash={() =>
                    deletePlan(plan._id, plan.type, plan.number_users)
                  }
                  label="plano"
                >
                  {plan.name}
                </CardHeader>
                <PlanCard
                  title="plano"
                  valueTitle="Valor mensal"
                  value={plan.mensality}
                  quantityTitle="Quantidade de beneficiários"
                  quantity={plan.number_users}
                />
              </Card>
            </Grid>
          ))}
        </Grid>
      </TabPanel>
      {/* Relatórios */}
      <TabPanel value={value} index={2}>
        <Reports />
      </TabPanel>
    </Main>
  );
}
