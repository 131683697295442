import { Colors } from 'globalStyle'
import styled from 'styled-components'

export const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const FinishButton = styled.button`
  margin-top: 1em;
  height: 30px;
  background-color: ${Colors.green};
  color: white;
  outline: none;
  border:none;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  text-align: center;
  border-radius: 4px;
  padding-left: 6px;
  padding-right: 6px;
  cursor: pointer;
`

export const HolderInfo = styled.div`
  border: 1px solid #CDCED9;
  border-radius: 10px;
  display: flex;
  width: fit-content;
  align-items: center;
  padding: 12px;
  background-color: white;
  height: 80px;

  img {
    object-fit: cover;
    border-radius: 10px;
    height: 100%;
    width: 50px;
  }

  div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    height: 100%;
    margin-left: 24px;
  }

`