import React from "react";
import { TabsStyled } from "./style";
export function TabPanel(props) {
  const { children, index, value } = props;

  return (
    <div hidden={value !== index}> {value === index && <> {children} </>} </div>
  );
}

export function FullTab(props) {
  const { ...rest } = props;
  return <TabsStyled {...rest} />;
}
