import { Grid, Card, CardContent } from "@material-ui/core";
import AddDependentForm from "components/client/beneficiary/addMultiple/dependentForm";
import AddMultipleForm from "components/client/beneficiary/addMultiple/form";
import Main from "components/main";
import React, { useEffect, useState } from "react";
import * as moment from "moment-timezone";
import { toast } from "react-toastify";
import { inputValidations } from "utils/beneficiary-validator";
import defaultBeneficiaryValidator from "utils/beneficiary-validator";
import {
  BeneficiaryContainer,
  BeneficiaryListContainer,
  FinishButton,
  LoadingScreen,
} from "./style";
import api from "services/api";
import { formatCpfCnpj } from "helper";
import LoadingGif from "../../../assets/icons/LoadingBlue.gif";
import { useHistory } from "react-router-dom";
import { getBeneficiariesLength } from "helper";
import PaymentConfirmationModal from "components/client/payment/paymentConfirmationModal";

import ModalUserAlert from "components/client/beneficiary/modalUserAlert";

export default function AddMultipleBeneficiaries() {
  const [beneficiaryForm, setBeneficiaryForm] = useState({
    cpf: "",
    name: "",
    type: "HOLDER",
    email: "",
    telephone: "",
    genre: "Masculino",
    birth_date: "",
    dependents: [],
  });
  const [dependentForm, setDependentForm] = useState([]);
  const [loading, setLoading] = useState(false);
  const [beneficiaries, setBeneficiaries] = useState([]);
  const defaultBeneficiaryValidate = defaultBeneficiaryValidator();
  const defaultDependentValidate = defaultBeneficiaryValidator();
  const [beneficiaryValidate, setBeneficiaryValidate] = useState(
    defaultBeneficiaryValidator
  );
  const [dependentValidate, setDependentValidate] = useState(
    defaultBeneficiaryValidator
  );
  const [paymentForm, setPaymentForm] = useState({
    payment_method: "credit_card",
    card_cvv: "",
    card_expiration_date: "",
    card_number: "",
  });
  const [modalOpen, setModalOpen] = useState(false);
  const [updatePayment, setUpdatePayment] = useState(false);
  const [client, setClient] = useState(null);
  const [fileName, setFileName] = useState("");
  const [preview, setPreview] = useState("");

  const [modalAlertOpen, setModalAlertOpen] = useState(false);

  const history = useHistory();

  const handleChange = (e) => {
    setBeneficiaryForm({ ...beneficiaryForm, [e.target.name]: e.target.value });
    if (beneficiaryValidate !== defaultBeneficiaryValidate)
      setBeneficiaryValidate(defaultBeneficiaryValidate);
  };

  const handleDependentsChange = (e, i) => {
    setDependentForm(
      [...dependentForm],
      (dependentForm[i][e.target.name] = e.target.value)
    );
    if (dependentValidate !== defaultDependentValidate)
      setDependentValidate(defaultDependentValidate);
  };

  const handleFileChange = (e) => {
    setBeneficiaryForm({ ...beneficiaryForm, profile_pic: e.target.files[0] });
  };

  const handlePaymentFormChange = (e) => {
    setPaymentForm({ ...paymentForm, [e.target.name]: e.target.value });
  };

  const handleDependentsFileChange = (e, i) => {
    setDependentForm(
      [...dependentForm],
      (dependentForm[i]["profile_pic"] = e.target.files[0])
    );
  };

  const alertModalHandleOk = () => {
    setModalAlertOpen(false);

    const total =
      getBeneficiariesLength(client?.beneficiaries) +
      getBeneficiariesLength(beneficiaries);

    if (client?.plan?._id && total > client?.plan?.number_users) {
      setModalOpen(true);
    } else {
      submitBeneficiaries();
    }

    // client?.plan?._id &&
    // getBeneficiariesLength(client?.beneficiaries) +
    // getBeneficiariesLength(beneficiaries) >
    // client?.plan?.number_users
    // ? setModalOpen(true)
    // : submitBeneficiaries()
  };

  function onHandleImageChange(event) {
    if (event.target.files.length > 0) {
      let { name } = event.target.files[0];
      let url = URL.createObjectURL(event.target.files[0]);
      setFileName(name);
      setPreview(url);
    }
  }

  const validateBeneficiaryForm = (newForm) => {
    const validation = inputValidations(newForm);
    if (validation !== true) {
      setBeneficiaryValidate(validation);
      toast.error("Verifique os campos em vermelho");
      return false;
    } else {
      setBeneficiaryValidate(defaultBeneficiaryValidate);
      return true;
    }
  };

  const validateDependentForm = (newForm) => {
    var validationDendents = true;
    for (let index = 0; index < newForm.length; index++) {
      validationDendents = inputValidations(newForm[index]);
    }
    if (validationDendents !== true && newForm.length !== 0) {
      setDependentValidate(validationDendents);
      toast.error("Verifique os campos em vermelho");
      return false;
    } else {
      setDependentValidate(defaultDependentValidate);
      return true;
    }
  };

  const handleSubmit = async (e) => {
    if (e) e.preventDefault();
    try {
      const validation = validateBeneficiaryForm(beneficiaryForm);
      const validationDependent = validateDependentForm(dependentForm);
      const cpfValidation = await beneficiaryCpfValidation(
        beneficiaryForm.cpf.replace(/\D+/g, "")
      );

      for (const [i, dependent] of dependentForm.entries()) {
        const dependentCpfValidation = await beneficiaryCpfValidation(
          dependent.cpf.replace(/\D+/g, "")
        );
        if (!dependentCpfValidation) return false;

        const duplicateDependentCpfInput = dependentForm.find((val, idx) => {
          return val.cpf === dependent.cpf && idx !== i;
        });

        if (duplicateDependentCpfInput) {
          toast.error("Existem dependentes com o mesmo CPF");
          return false;
        }
      }

      if (validation && cpfValidation && validationDependent) {
        const duplicateDependent = dependentForm.find(
          (dependent) => dependent.cpf === beneficiaryForm.cpf
        );
        if (duplicateDependent) {
          throw new Error("O CPF já pertence a um dependente");
        }

        if (!beneficiaryForm.profile_pic) {
          throw new Error(
            parseInt(dependentForm.indexOf(beneficiaryForm) + 1) === 0
              ? "Foto de perfil não inserida no Beneficiário Titular"
              : "Foto de perfil não inserida no Dependente " +
                parseInt(dependentForm.indexOf(beneficiaryForm) + 1)
          );
        }

        const formatedDate = moment(
          beneficiaryForm.birth_date,
          "DD/MM/YYYY",
          true
        );
        setBeneficiaries([
          ...beneficiaries,
          {
            ...beneficiaryForm,
            cpf: beneficiaryForm.cpf.replace(/\D+/g, ""),
            telephone: beneficiaryForm.telephone.replace(/\D+/g, ""),
            birth_date: formatedDate.format("YYYY/MM/DD"),
            dependents: dependentForm,
          },
        ]);
        setBeneficiaryForm({
          cpf: "",
          name: "",
          type: "HOLDER",
          email: "",
          telephone: "",
          genre: "Masculino",
          birth_date: "",
          dependents: [],
        });
        setDependentForm([]);
        setFileName("");
        setPreview("");
      }
    } catch (err) {
      console.log(err);
      toast.error(err.response ? err.response.data.message : err.message);
    }
  };

  const beneficiaryCpfValidation = async (cpf) => {
    try {
      await api.get(
        "/beneficiary/check-status?cpf=" +
          beneficiaryForm.cpf.replace(/\D+/g, "")
      );

      beneficiaries.map((beneficiary) => {
        if (beneficiary.cpf === cpf)
          throw new Error("O CPF já pertence a um beneficiário");

        const dependent = beneficiary.dependents.find(
          (dependent) => dependent.cpf.replace(/\D+/g, "") === cpf
        );
        if (dependent) throw new Error("O CPF já pertence a um dependente");

        return true;
      });

      return true;
    } catch (err) {
      toast.error(err.response ? err.response.data.message : err.message);
      return false;
    }
  };

  const submitBeneficiaries = async (e) => {
    if (e) e.preventDefault();
    const formData = mountMultipartForm();
    try {
      setLoading(true);
      await api.post("/beneficiary/multiple", formData);
      setLoading(false);
      history.push({ pathname: "/beneficiarios", state: { secondStep: true } });
      toast.success("Beneficiários incluídos com sucesso");
    } catch (err) {
      console.log(err);
      setLoading(false);
      if (err.response) {
        toast.error(err.response.data.message);
      } else {
        toast.error(err.message);
      }
    }
    setLoading(false);
  };

  const mountMultipartForm = () => {
    const payload = [];
    const profile_pics = [];

    for (const beneficiary of beneficiaries) {
      const { profile_pic, ...beneficiaryData } = beneficiary;
      payload.push(beneficiaryData);
      profile_pics[beneficiary.cpf] = profile_pic;
      for (const dependent of beneficiary.dependents) {
        const formatedDate = moment(dependent.birth_date, "DD/MM/YYYY", true);

        dependent.cpf = dependent.cpf.replace(/\D+/g, "");
        dependent.telephone = dependent.telephone.replace(/\D+/g, "");
        dependent.birth_date = formatedDate.format("YYYY/MM/DD");
        profile_pics[dependent.cpf] = dependent.profile_pic;
      }
    }

    const formData = new FormData();
    formData.append("payload", JSON.stringify(payload));
    if (client?.plan?._id) {
      formData.append(
        "paymentInfo",
        JSON.stringify({
          ...paymentForm,
          card_expiration_date: paymentForm.card_expiration_date.replace(
            /\D+/g,
            ""
          ),
        })
      );
      if (updatePayment) formData.append("changePaymentMethod", true);
    }
    Object.keys(profile_pics).map((key) =>
      formData.append(key, profile_pics[key])
    );

    return formData;
  };

  useEffect(() => {
    async function getClient() {
      const response = await api.get("/user/clients/self");
      setClient(response.data);
    }
    getClient();
  }, []);

  return (
    <>
      <Main maxWidth="xl">
        <Grid container>
          {beneficiaries.length > 0 && (
            <>
              <Grid item md={12} style={{ marginTop: "1em", width: "100%" }}>
                <h3 style={{ marginTop: "1em" }}>Beneficiários Inseridos</h3>
                <BeneficiaryListContainer>
                  {beneficiaries?.map((beneficiary) => (
                    <BeneficiaryContainer>
                      <img
                        src={URL.createObjectURL(beneficiary.profile_pic)}
                        alt="beneficiary profile pic"
                      ></img>
                      <strong>{beneficiary.name}</strong>
                      <small>{formatCpfCnpj(beneficiary.cpf)}</small>
                    </BeneficiaryContainer>
                  ))}
                </BeneficiaryListContainer>
              </Grid>
              <Grid
                item
                md={12}
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <FinishButton
                  type="button"
                  onClick={
                    () => setModalAlertOpen(true)
                    // client?.plan?._id &&
                    // getBeneficiariesLength(client?.beneficiaries) +
                    // getBeneficiariesLength(beneficiaries) >
                    // client?.plan?.number_users
                    // ? () => setModalOpen(true)
                    // : submitBeneficiaries
                  }
                >
                  {" "}
                  Finalizar{" "}
                </FinishButton>
              </Grid>
            </>
          )}
          <Grid item md={12}>
            <Card
              style={{
                marginTop: "20px",
                borderRadius: "4px",
                border: "1px solid #CDCED9",
              }}
            >
              <CardContent>
                <AddMultipleForm
                  beneficiaryForm={beneficiaryForm}
                  handleChange={handleChange}
                  handleSubmit={handleSubmit}
                  plansValidate={beneficiaryValidate}
                  handleFileChange={handleFileChange}
                  onHandleImageChange={onHandleImageChange}
                  preview={preview}
                  fileName={fileName}
                />
                <AddDependentForm
                  dependentForm={dependentForm}
                  handleChange={handleDependentsChange}
                  setDependentForm={setDependentForm}
                  handleDependentsFileChange={handleDependentsFileChange}
                />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        {loading && (
          <LoadingScreen>
            <h5>Inserindo beneficiários, por favor aguarde</h5>
            <img src={LoadingGif} alt="loading icon" />
          </LoadingScreen>
        )}
        <PaymentConfirmationModal
          open={modalOpen}
          handleClose={() => setModalOpen(false)}
          handleChange={handlePaymentFormChange}
          paymentForm={paymentForm}
          setPaymentForm={setPaymentForm}
          planValue={client?.plan?.value}
          handleSubmit={submitBeneficiaries}
          updatePayment={updatePayment}
          setUpdatePayment={setUpdatePayment}
          number={
            getBeneficiariesLength(beneficiaries) +
            getBeneficiariesLength(client?.beneficiaries, true) -
            client?.plan?.number_users
          }
        />
      </Main>
      <ModalUserAlert
        toggle={modalAlertOpen}
        handleOk={() => alertModalHandleOk()}
      />
    </>
  );
}
