import {
  ImgInput,
  Input,
  InputMask,
  PasswordInput,
} from "../../../../components/inputs";
import {
  Button,
  StepContainer,
  HelperText,
  DesktopButton,
  DesktopButtons,
} from "./style";
import React, { useState } from "react";
import { FormControlLabel, Hidden, Radio, RadioGroup } from "@material-ui/core";

export default function SignupStep({
  type,
  name,
  value,
  onChange,
  buttonAction,
  verification,
  verificationText,
  backAction,
  mask,
  pattern,
  inputMode,
  radioOptions,
  isOptional,
  buttonText,
  showPattern,
  placeholder,
  confirmationValue,
  submit,
  ContainerLink,
  children,
  ...rest
}) {
  const [helperText, setHelperText] = useState("");

  // useEffect(() => {
  //   const onKeyDown = (event) => {
  //     var inputValue = event?.target?.children[1]?.children[1][0]?.value;
  //     if (event.key === "Enter") {
  //       event.preventDefault();
  //       if (!inputValue) {
  //         setHelperText("Campo obrigatório");
  //       } else {
  //         buttonAction();
  //       }
  //     }
  //   }

  //   window.addEventListener('keydown', onKeyDown)
  //   return () => { window.removeEventListener('keydown', onKeyDown) }
  // }, [buttonAction])

  function handleNext() {
    if (!value && !isOptional) {
      setHelperText("Campo obrigatório");
    } else {
      if (verification) {
        const verificationResult = verification(value);
        if (!verificationResult) {
          setHelperText(
            typeof verificationText === "function"
              ? verificationText()
              : verificationText
          );
        } else {
          if (buttonAction) {
            buttonAction();
          }
          setHelperText("");
        }
      } else {
        if (buttonAction) {
          buttonAction();
        }
        setHelperText("");
      }
    }
  }

  const handleChange = (e) => {
    if (helperText) setHelperText("");
    onChange(e);
  };

  if (
    type === "text" ||
    type === "date" ||
    type === "number" ||
    type === "password" ||
    type === "email"
  ) {
    return (
      <StepContainer>
        {type === "password" ? (
          <>
            <PasswordInput
              showPattern={showPattern}
              onChange={handleChange}
              type={type}
              defaultValue={value}
              name={name}
              verificationText={verificationText}
              {...rest}
            />
            <PasswordInput
              type="password"
              defaultValue={confirmationValue}
              onChange={handleChange}
              name="passwordConfirmation"
              label="Confirmação de senha"
              {...rest}
            />
          </>
        ) : (
          <Input
            pattern={pattern}
            placeholder={placeholder}
            inputMode={inputMode}
            onChange={handleChange}
            type={type}
            value={value}
            name={name}
            {...rest}
          />
        )}

        <HelperText>{helperText}</HelperText>
        <Hidden mdUp>
          <Button type={submit ? "submit" : "button"} onClick={handleNext}>
            {buttonText ? buttonText : "Avançar"}
          </Button>
        </Hidden>
        <Hidden smDown>
          <DesktopButtons>
            <DesktopButton back type="button" onClick={backAction}>
              Voltar
            </DesktopButton>
            <DesktopButton
              next
              type={submit ? "submit" : "button"}
              onClick={handleNext}
            >
              {buttonText ? buttonText : "Avançar"}
            </DesktopButton>
          </DesktopButtons>
        </Hidden>
      </StepContainer>
    );
  }

  if (type === "mask") {
    return (
      <StepContainer>
        <InputMask
          mask={mask}
          placeholder={placeholder}
          onChange={handleChange}
          type={type}
          value={value}
          name={name}
          handleNext={handleNext}
          inputMode={inputMode}
          {...rest}
        />
        <HelperText>{helperText}</HelperText>
        <Hidden mdUp>
          <Button type="button" onClick={handleNext}>
            Continuar
          </Button>
        </Hidden>
        <Hidden smDown>
          <DesktopButtons>
            <DesktopButton back type="button" onClick={backAction}>
              Voltar
            </DesktopButton>
            <DesktopButton next type="button" onClick={handleNext}>
              Avançar
            </DesktopButton>
          </DesktopButtons>
        </Hidden>
      </StepContainer>
    );
  }

  if (type === "radio") {
    return (
      <StepContainer>
        <RadioGroup
          style={{ width: "250px" }}
          aria-label="gender"
          value={value}
          onChange={handleChange}
          name={name}
          id={name}
          {...rest}
        >
          {radioOptions.map((opt) => (
            <FormControlLabel
              key={opt.value}
              value={opt.value}
              control={<Radio color="primary" />}
              label={opt.label}
              disabled={opt.disabled}
            />
          ))}
        </RadioGroup>
        <Input
          name="Input"
          id={"radio" + name}
          style={{ opacity: "0", height: "0px" }}
          {...rest}
        />

        <HelperText>{helperText}</HelperText>

        {children}

        <Hidden mdUp>
          <Button type="button" onClick={handleNext}>
            Continuar
          </Button>
        </Hidden>
        <Hidden smDown>
          <DesktopButtons>
            <DesktopButton back type="button" onClick={backAction}>
              Voltar
            </DesktopButton>
            <DesktopButton next type="button" onClick={handleNext}>
              Avançar
            </DesktopButton>
          </DesktopButtons>
        </Hidden>
      </StepContainer>
    );
  }
  if (type === "img") {
    return (
      <StepContainer>
        <ImgInput
          name="profile_pic"
          className="file-container"
          onChange={onChange}
          value={value}
          {...rest}
        />
        <Input
          name="Input"
          id="imgInput"
          style={{ opacity: "0", height: "0px" }}
          {...rest}
        />
        <HelperText>{helperText}</HelperText>
        <Hidden mdUp>
          <Button type="button" onClick={handleNext}>
            Continuar
          </Button>
        </Hidden>
        <Hidden smDown>
          <DesktopButtons>
            <DesktopButton back type="button" onClick={backAction}>
              Voltar
            </DesktopButton>
            <DesktopButton next type="button" onClick={handleNext}>
              Avançar
            </DesktopButton>
          </DesktopButtons>
        </Hidden>
      </StepContainer>
    );
  }
}
