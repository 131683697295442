import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
import { formatDate } from 'helper'
import React from 'react'
import { toast } from 'react-toastify'
import { ReceiptButton } from '../paymentHistoryModal/style'

export default function PaymentHistoryTable({data}) {

  function openInNewTab(url) {
    if(url) {
      var win = window.open(url, '_blank');
      win.focus();
    } else {
      toast.warning("Nota não vinculada")
    }
  }

  const StatusLabel = (status) => ({
    'paid': "Pago",
    'waiting_payment': "Pendente",
    'chargedback': "Estornado",
    'canceled': "Cancelado",
    'failed': "Não Pago - Falha",
    'default': "Não Pago"
  }[status || 'default'])

  return(
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align='center'>
            <span>Data de vencimento</span>
            </TableCell>
            <TableCell align='center'>
            <span>Data de pagamento</span>
            </TableCell>
            <TableCell align='center'>
              <span>Forma de Pgto.</span>
            </TableCell>
            <TableCell align='center'>
              <span>Valor</span>
            </TableCell>
            <TableCell align='center'>
              <span>Status</span>
            </TableCell>
            <TableCell align='center'>
              <span>Opções</span>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data
            .map((payment) => (
              <TableRow key={payment.createdAt}>
                <TableCell align='center'>
                {payment.boleto_expiration_date ? formatDate(payment.boleto_expiration_date) : '--'}
                </TableCell>
                <TableCell align='center'>
                {payment.paidAt ? formatDate(payment.paidAt) : '--'}
                </TableCell>
                <TableCell align='center'>
                  {payment.payment_method==="boleto" ? "Boleto" : "Cartão de Crédito"}
                </TableCell>
                <TableCell align='center'>
                  R$ {payment.amount.toFixed(2).replace(".",",")}
                </TableCell>
                <TableCell align='center'>
                 {StatusLabel(payment.status)}
                </TableCell>
                <TableCell align='center'>
                  {
                    <ReceiptButton
                    type="button"
                    onClick={()=>openInNewTab(payment.receipt_url)}>
                      Documento
                    </ReceiptButton>
                  }
                  {payment.payment_method==="boleto" &&
                    <ReceiptButton
                    type="button"
                    onClick={()=>openInNewTab(payment.boleto_url)}>
                      Boleto
                    </ReceiptButton>
                  }
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}