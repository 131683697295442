import { RadioInput, Input } from 'components/inputs';
import Modal from 'components/modal';
import styled from 'styled-components'

export const FormatedRadioInput = styled(RadioInput)`
  flex-direction: row;
  justify-content: space-between;

  label {
    width: calc(50% - 4px);
    border: 1px solid #D8DDE2;
    box-sizing: border-box;
    border-radius: 4px;
    margin: 0;
    padding: 0;
    height: 90px;
    display: flex;
    align-items: center;
  }
`

export const PaymentModal = styled(Modal)`
  label {
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    display: flex;
    align-items: center;
    color: #6B6C7E;
  }
`

export const InputModal= styled(Input)`

  margin: 0 !important;

  label {
    margin: 0;
    padding: 0;
  }
`