import styled from 'styled-components';
import { TableCell, Grid } from '@material-ui/core';
import { Colors } from 'globalStyle';
/**
 * Styled TableCell
 */
export const CellTitle = styled(TableCell)`
  color: #a7a9bc !important;
  font-size: 12px !important;
`;

export const PaperHeader = styled.h3`
  font-family: Montserrat !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 18px !important;
  line-height: 150% !important;

  color: #616161 !important;
  margin-left: 20px;
  padding-top: 20px;
`;

export const TitleDownload = styled.div`
  margin-top: 83px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  h4 {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 150%;
  }
`;

export const SeeAll = styled(Grid)`
  margin-top: 30px !important;
  button {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    text-transform: none;
  }
`;

export const CardBody = styled(Grid)`
  height: 100%;
  align-self: center;
  margin-top: ${(props) => (props.mt ?? 'none')};
  h4 {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 150%;
    color: ${Colors.dark};
  }
  h3 {
    margin: 0; 
    margin-left: 120px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 150%;
    
    text-align: center;
  }
`;

