import { Colors } from 'globalStyle'
import styled from 'styled-components'

export const FinishContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  justify-content: center;
  h3{
    margin-top: 24px;
    margin-bottom: 0;
  }
  p {
    margin-top: 4px;
    color: ${Colors.darkGrey}
  }
  button {
    width: 70%;
    height: 45px;
    background-color: ${Colors.blue};
    color: white;
    outline: none;
    border:none;
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
    text-align: center;
    cursor: pointer;
  }
`