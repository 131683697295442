import { Grid, Paper } from '@material-ui/core'
import styled from 'styled-components'

export const BeneficiaryCard = styled(Paper)`
  height: 250px;
  padding: 40px;

  strong, p, h4 {
    margin: 0;
    padding: 0;
  }
`

export const CardGrid = styled(Grid)`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
`

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  height: 101px;

  strong {
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 150%;
    color: #4B9BFF;
  }
  
  p {
    font-size: 14px;
    line-height: 150%;
    text-align: center;
    color: #393A4A;
    padding-top: 17px;
  }
`

export const AddButton = styled.button`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #FFFFFF;
  background: #f44336;
  border-radius: 4px;
  border:none;
  outline: none;
  width:140px;
  height: 40px;
  margin: auto;
  display: block;
  cursor: pointer;
`