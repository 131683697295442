import React from "react";
import AddCircleRoundedIcon from "@material-ui/icons/AddCircleRounded";
import RemoveCircleRounded from "@material-ui/icons/RemoveCircleRounded";
import { Grid, IconButton } from "@material-ui/core";
import { toast } from "react-toastify";

import {
  Form,
  Title,
  InputContainer,
  DependentTitle,
  AddButtonContainer,
} from "./style";
import { InputMask, Input, ImgInput } from "components/inputs";
import { cpfMask, dateMask, phoneMask } from "utils/masks";
import { Colors } from "globalStyle";
import {
  cpfValidator,
  dateValidator,
  emailValidator,
  phoneValidator,
} from "utils/input-validators";
import { inputValidations } from "utils/beneficiary-validator";
import api from "services/api";

export default function AddDependentForm({
  dependentForm,
  handleChange,
  setDependentForm,
  handleDependentsFileChange,
}) {
  const addDependentForm = (dependent) => {
    const validation = validateBeneficiaryForm(dependent);

    if (validation === true) {
      setDependentForm([
        ...dependentForm,
        {
          cpf: "",
          name: "",
          email: "",
          telephone: "",
          type: "DEPENDENT",
          genre: "Masculino",
          birth_date: "",
        },
      ]);
    }
  };

  const startDependentForm = () => {
    setDependentForm([
      {
        cpf: "",
        name: "",
        email: "",
        telephone: "",
        type: "DEPENDENT",
        genre: "Masculino",
        birth_date: "",
      },
    ]);
  };

  const removeDependent = (i) => {
    setDependentForm(dependentForm.filter((val, index) => i !== index));
  };

  const validateCpfAndDuplication = async (cpf) => {
    if (cpfValidator(cpf)) {
      try {
        await api.get(
          "/beneficiary/check-status?cpf=" + cpf.replace(/\D+/g, "")
        );
        return true;
      } catch (err) {
        toast.error(err.response.data.message);
        return false;
      }
    } else {
      return false;
    }
  };

  const validateBeneficiaryForm = (newForm) => {
    const validation = inputValidations(newForm);
    if (validation !== true) {
      toast.error("Existem campos inválidos no dependente atual");
      return false;
    } else {
      return true;
    }
  };

  return (
    <Form>
      <Title>
        <h3>Adicionar dependentes</h3>
        {dependentForm.length === 0 && (
          <IconButton type="button" onClick={() => startDependentForm()}>
            <AddCircleRoundedIcon style={{ fill: Colors.blue }} />
          </IconButton>
        )}
      </Title>
      {dependentForm.map((dependent, i) => (
        <>
          <DependentTitle>
            <h5>Dependente {i + 1}</h5>
            <IconButton type="button" onClick={() => removeDependent(i)}>
              <RemoveCircleRounded style={{ fill: Colors.red }} />
            </IconButton>
          </DependentTitle>
          <InputContainer container spacing={1}>
            <Grid container alignItems="center" xs={12}>
              <Grid item xs={3} md={1} sm={12}>
                <ImgInput
                  className="file-container"
                  onChange={(e) => handleDependentsFileChange(e, i)}
                  name={"dependent_profile_pic" + i}
                />
              </Grid>
              <Grid item xs={6} md={6} sm={12}>
                <span
                  style={{
                    flexDirection: "row",
                    fontSize: "11px",
                    color: "red",
                    opacity: "0.7",
                  }}
                >
                  *Recomenda-se inserir uma foto quadrada
                </span>
              </Grid>
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <InputMask
                label="CPF"
                inputMode="numeric"
                name="cpf"
                width="100%"
                onChange={(e) => handleChange(e, i)}
                onBlur={() => {}}
                mask={cpfMask}
                value={dependent.cpf}
                validate={validateCpfAndDuplication}
                validateText={"CPF Inválido"}
                required
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <Input
                label="Nome"
                name="name"
                width="100%"
                value={dependent.name}
                onChange={(e) => handleChange(e, i)}
                required
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <Input
                label="Email"
                name="email"
                inputMode="email"
                width="100%"
                value={dependent.email.toLowerCase()}
                onChange={(e) => {
                  e.target.value = e.target.value.toLowerCase();
                  handleChange(e, i);
                }}
                helperText={"Email Inválido"}
                error={
                  dependent.email ? !emailValidator(dependent.email) : false
                }
                required
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <InputMask
                label="Celular"
                name="telephone"
                inputMode="tel"
                width="100%"
                value={dependent.telephone}
                onChange={(e) => handleChange(e, i)}
                onBlur={() => {}}
                mask={phoneMask}
                validate={phoneValidator}
                validateText={"Telefone Inválido"}
                required
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <InputMask
                label="Data de nascimento"
                inputMode="numeric"
                width="100%"
                name="birth_date"
                value={dependent.birth_date}
                onChange={(e) => handleChange(e, i)}
                onBlur={() => {}}
                mask={dateMask}
                validate={dateValidator}
                validateText="Data inválida"
                required
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <Input
                label="Gênero"
                type="select"
                name="genre"
                width="100%"
                value={dependent.genre}
                onChange={(e) => handleChange(e, i)}
                required
              >
                <option value="Masculino">Masculino</option>
                <option value="Feminino">Feminino</option>
              </Input>
            </Grid>
          </InputContainer>
          {i === dependentForm.length - 1 && (
            <AddButtonContainer>
              <IconButton
                type="button"
                onClick={() => addDependentForm(dependent)}
              >
                <AddCircleRoundedIcon
                  fontSize="large"
                  style={{ fill: Colors.blue }}
                />
              </IconButton>
            </AddButtonContainer>
          )}
        </>
      ))}
    </Form>
  );
}
