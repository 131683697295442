import { Checkbox } from '@material-ui/core'
import { ModalBody, ModalFooter, ModalHeader } from 'components/modal'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { PlanCard, PlanCardItem, PlanModal } from './style'

export default function AvaliablePlansModal({
  open,
  handleClose,
  plans = []
}){

  const [selectedPlans, setSelectedPlans] = useState([])
  const history = useHistory()

  const handlePlanSelection = (e, plan) => {
    console.log(e.target.checked, plan)
    if(e.target.checked)
      setSelectedPlans([...selectedPlans, {_id: plan._id, type: plan.type}])
    else
      setSelectedPlans(selectedPlans.filter(val=>val._id !== plan._id))
      
  }

  const handleSubmit = e => {
    e.preventDefault()
    history.push('/planos/compare', {
      plans: selectedPlans
    })
  }

  return(
    <PlanModal onSubmit={handleSubmit} show={open}>
      <ModalHeader close={handleClose}> Planos disponíveis </ModalHeader>
      <ModalBody>
        {plans.map(plan=>(
           <PlanCard key={plan._id}>

           <PlanCardItem color="#2E5AAC">
              <label>Plano</label>
              <span>{plan.name}</span>
           </PlanCardItem>
           <PlanCardItem color="#287D3C">
             <label>Valor mensal</label>
             <span>R$ {plan.mensality.toFixed(2).replace(".", ",")}</span>
           </PlanCardItem>
           <PlanCardItem>
               <label>Beneficiários</label>
               <span>
               {
                  plan.type==="PF" ?
                  plan.number_users : 
                  plan.min_users > 0 && plan.max_users === 0 ? plan.min_users+" + " :
                  plan.min_users === 0 && plan.max_users > 0 ? plan.max_users+" - " :
                  plan.min_users === 0 && plan.max_users === 0 ? "Customizado" :
                  `${plan.min_users} - ${plan.max_users}`
                }
               </span>
           </PlanCardItem>
           <PlanCardItem>
            <Checkbox
              color="primary"
              inputProps={{ 'aria-label': 'secondary checkbox' }}
              style={{ backgroundColor: 'transparent' }}
              disableRipple
              onChange={(e)=>handlePlanSelection(e, plan)}
            />
           </PlanCardItem>
         </PlanCard>
        ))}
      </ModalBody>
      <ModalFooter cancel={handleClose} next='Comparar' disabled={!selectedPlans.length}/>
    </PlanModal>
  )
}