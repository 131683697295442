import { PaymentModal } from "./style";
import React from "react";
import { ModalHeader, ModalBody, ModalFooter } from "../../../modal";
import { PaymentFormContainer } from "../paymentForm/style";
import { Grid } from "@material-ui/core";
import { FormatedRadioInput } from "./style";
import { cardNumberMask, cardValidityMask } from "utils/masks";
import { InputMask, Input } from "components/inputs";

export default function PaymentConfirmationModal({
  updatePayment,
  setUpdatePayment,
  open,
  handleClose,
  number = 0,
  planValue = 0,
  paymentForm,
  setPaymentForm,
  handleChange,
  handleSubmit,
}) {
  var valid = require("card-validator");
  return (
    <PaymentModal onSubmit={(e) => handleSubmit(e)} show={open}>
      <ModalHeader close={handleClose}> Confirmação de pagamento </ModalHeader>
      <ModalBody>
        <PaymentFormContainer container>
          <h4>Serão gerados custos referentes aos novos beneficiários</h4>
          <p>
            Valor do plano por pessoa: R${" "}
            {planValue.toFixed(2).replace(".", ",")}
          </p>
          <p>
            Valor total a ser adicionado: R${" "}
            {(planValue * number).toFixed(2).replace(".", ",")}
          </p>
          <p>
            Será cobrado um valor pró-rata referente à liberação dos novos beneficiários de hoje até a próxima database.
            Esses novos beneficiários podem ter acesso limitado à plataforma até que ocorra a confirmação desse pagamento.
          </p>
          <Grid item xs={12}>
            <FormatedRadioInput
              name="payment_method"
              row
              value={updatePayment}
              onChange={(e) => setUpdatePayment(!updatePayment)}
              radioOptions={[
                { label: "Manter forma Pagamento", value: false },
                { label: "Trocar forma de pagamento", value: true },
              ]}
            />
          </Grid>
        </PaymentFormContainer>
        {!!updatePayment && (
          <PaymentFormContainer container>
            <h5>Selecionar forma de pagamento</h5>
            <Grid item xs={12}>
              <FormatedRadioInput
                name="payment_method"
                row
                value={paymentForm.payment_method}
                onChange={handleChange}
                radioOptions={[
                  { label: "Cartão de crédito", value: "credit_card" },
                  { label: "Boleto", value: "boleto" },
                ]}
              />
            </Grid>
            {paymentForm.payment_method === "credit_card" && (
              <>
                <Grid item xs={12}>
                  <h5>Detalhes do pagamento</h5>
                </Grid>
                <Grid item xs={12}>
                  <InputMask
                    type="text"
                    name="card_number"
                    mask={cardNumberMask}
                    label="Número do cartão"
                    value={paymentForm.card_number}
                    inputMode="numeric"
                    width="100%"
                    validate={valid.number}
                    validateText="Número do Cartão Inválido"
                    onChange={handleChange}
                  />
                  <h5 style={{ margin: "5px", fontSize: "10px" }}>
                    *Para alterar o número do cartão, clique no dígito desejado.
                  </h5>
                </Grid>
                <Grid item xs={12}>
                  <Input
                    required
                    name="card_owner_name"
                    label="Nome do titular"
                    width="100%"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Input
                    required
                    name="card_holder_name"
                    pattern="[a-zA-Z\s]*$"
                    title="É aceito apenas letras, não é permitido números ou caracteres especiais"
                    label="Nome impresso no cartão"
                    width="100%"
                    onChange={handleChange}
                  />
                  <h5 style={{ margin: "5px", fontSize: "10px" }}>
                    *É aceito apenas letras, não é permitido números ou caracteres especiais
                  </h5>
                </Grid>
                <Grid item xs={12}>
                  <Grid spacing={2} container>
                    <Grid item xs={6}>
                      <InputMask
                        required
                        type="text"
                        name="card_expiration_date"
                        inputMode="numeric"
                        label="Validade"
                        width="100%"
                        onChange={handleChange}
                        value={paymentForm.card_expiration_date}
                        mask={cardValidityMask}
                        validate={() =>
                          paymentForm.card_expiration_date.length === 5
                        }
                        validateText="A data de validade está fora do padrão"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <InputMask
                        required
                        type="text"
                        name="card_cvv"
                        inputMode="numeric"
                        label="Código de segurança"
                        width="100%"
                        value={paymentForm.card_cvv}
                        onChange={handleChange}
                        mask={[/\d/, /\d/, /\d/]}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}
          </PaymentFormContainer>
        )}
      </ModalBody>
      <ModalFooter cancel={handleClose} next="Enviar" />
    </PaymentModal>
  );
}
