import styled from 'styled-components';
import { Colors } from 'globalStyle';

export const TriageDetail = styled.div`
  h5 {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: ${Colors.darkGrey}
  }

  div{
    display: flex;
    flex-wrap: wrap;

    p {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      letter-spacing: 0.1px;
      padding: 10px 24px;
      background: #EEEEEE;
      border-radius: 20px;
      cursor: ${props=>props.exam ? "pointer": "auto"};

      &:not(:last-child) {
        margin-right: 8px;
      }
    }


  }
`;

