import { Grid, Paper } from '@material-ui/core'
import styled from 'styled-components'

export const PaymentCard = styled(Paper)`
  height: 250px;
  padding: 40px;
  display: flex;
  flex-direction: column;
  h4 {
    margin: 0;
    display: inline;
  }

  h6{
    margin: 0;
    display:inline;
  }

  div {
    margin: 0;
  }

  p{
    display:inline;
    margin: 0;
  }

  div.container{
    border-right: 1px solid #F1F2F5;
    height: 101px;
    width: 105%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  div:not(:first-of-type){
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    div {
      margin: 0;
      display: flex;
      justify-content: center;
      flex-direction: column;
      text-align: left;
      padding-left: 33px;
      padding-right: 33px;
      height:100%;
      padding: auto;
    }
  }

  div.first-info{
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    div{
      margin: 0;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      text-align: left;
      height:100%;
      
      padding: auto;
    }
  }

  button {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #FFFFFF;
    background: #3089C3;
    border-radius: 4px;
    border:none;
    outline: none;
    width:140px;
    height: 40px;
    margin: auto;
    display: block;
    cursor: pointer;
  }
`

export const CardGrid = styled(Grid)`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
`