import { PaymentModal } from "./style";
import React, { useRef, useState } from "react";
import { ModalHeader, ModalBody, ModalFooter } from "../../../modal";
import PaymentForm from "../paymentForm";
import { toast } from "react-toastify";

export default function PaymentTypeModal({
  open,
  handleClose,
  actual_payment_method,
}) {
  const formSubmit = useRef(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!cardNumberError) {
      if (typeof formSubmit.current.requestSubmit === "function") {
        formSubmit.current.requestSubmit();
      } else {
        formSubmit.current.dispatchEvent(
          new Event("submit", { cancelable: true })
        );
      }
      handleClose();
    } else {
      toast.error("Erro noo Preenchimento");
    }
  };

  const [cardNumberError, setCardNumberError] = useState(true);

  return (
    <PaymentModal onSubmit={(e) => handleSubmit(e)} show={open}>
      <ModalHeader close={handleClose}> Forma de pagamento </ModalHeader>
      <ModalBody>
        <PaymentForm
          modal
          updatePaymentType
          actual_payment_method={actual_payment_method}
          forwardedRef={formSubmit}
          setCardNumberError={setCardNumberError}
        />
      </ModalBody>
      <ModalFooter cancel={handleClose} next="Enviar" />
    </PaymentModal>
  );
}
