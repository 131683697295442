import React, { useState } from "react";
import {
  ButtonBox,
  ButtonSeparator,
  FormContainer,
  LoginDiv,
  LogoImg,
} from "./style";
import {
  TextField,
  Button,
  Grid,
  Hidden,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import { store } from "store";
import { useDispatch } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";

import Logo from "../../assets/logos/Logo-Horizontal2.png";
import BgImage from "assets/images/bg_virtua.png";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import ForgotPasswordModal from "components/forgotPasswordModal";

export default function Login() {
  const dispatch = useDispatch();
  const { access_token } = store.getState().auth;

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const history = useHistory();

  if (access_token) {
    return <Redirect to="/" />;
  }

  function handleSubmit(event) {
    event.preventDefault();
    dispatch({
      type: "@auth/LOGIN",
      payload: {
        email,
        password,
      },
    });
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={6} style={{ height: "100vh" }}>
          <LoginDiv>
            <FormContainer>
              <LogoImg src={Logo} height="90px" alt="logo" />
              <form onSubmit={(e) => handleSubmit(e)}>
                <TextField
                  label="Email"
                  type="email"
                  name="email"
                  onChange={(e) => setEmail(e.target.value.toLowerCase())}
                  value={email.toLowerCase()}
                  required
                />
                <TextField
                  label="Senha"
                  type={showPassword ? "text" : "password"}
                  name="password"
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end" className="eye-icon">
                        <IconButton
                          disableRipple
                          style={{ backgroundColor: "transparent" }}
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  required
                />
                <span
                  className="forgot-password"
                  onClick={() => setShowForgotPassword(true)}
                >
                  Esqueci minha senha
                </span>
                <ButtonBox submit>
                  <Button type="submit">Entrar</Button>
                </ButtonBox>
                <ButtonSeparator>
                  <div />
                  ou
                  <div />
                </ButtonSeparator>
                <ButtonBox>
                  <Button
                    type="button"
                    variant="outlined"
                    style={{backgroundColor: '#001C5F', color: '#FEF445'}}
                    onClick={() => history.push("client/signup")}
                  >
                    <b>Não é usuário? Clique aqui</b>
                  </Button>
                </ButtonBox>
              </form>
            </FormContainer>
          </LoginDiv>
        </Grid>
        <Hidden smDown>
          <Grid
            item
            xs={6}
            style={{
              background: `url(${BgImage})`,
              backgroundSize: "cover",
              backgroundPosition: "right",
              height: "100vh",
            }}
          ></Grid>
        </Hidden>
      </Grid>
      <ForgotPasswordModal
        show={showForgotPassword}
        handleClose={() => setShowForgotPassword(false)}
      />
    </>
  );
}
