import { Colors } from 'globalStyle'
import styled from 'styled-components'

export const ShowPayment = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 256px;
  p{
    align-self: center;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    display: flex;
    align-items: center;
    text-align: center;
    color: ${Colors.darkGrey};
    cursor: pointer;
  }
`