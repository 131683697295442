import React, { useState } from "react";

import { Button } from "@material-ui/core";

import Modal, { ModalBody, ModalFooter, ModalHeader } from "components/modal";

import { Container, ContainerLinks, ContainerAgree } from "./style";

import appStore from "assets/images/apple-store.png";
import googlePlay from "assets/images/google-play.png";

export default function ModalUserAlert(props) {
  const [enableOk, setEnableOk] = useState(false);

  const handleEnableOk = (e) => {
    setEnableOk(e.target.checked);
  };

  return (
    <Modal show={props.toggle} style={{ maxWidth: "580px" }}>
      <ModalHeader>ATENÇÃO</ModalHeader>
      <ModalBody>
        <Container>
          <strong>
            {" "}
            O atendimento médico é feito exclusivamente pelo celular.
          </strong>
          <p>
            Para realizar suas consultas pelo <strong>Virtua Care</strong>, você
            precisa <strong>baixar</strong> e{" "}
            <strong>realizar seu cadastro</strong> (um cadastro para cada
            usuário) no Aplicativo <strong>Vida Connect</strong>.
          </p>
          <span>
            Disponível nas lojas de aplicativos App Store e Google Play.
          </span>
          <ContainerLinks>
            <img src={appStore} alt="App Store" />
            <img src={googlePlay} alt="Google Play" />
          </ContainerLinks>
          <ContainerAgree>
            <span>"Li e Compreendi"</span>
            <input type="checkbox" onChange={handleEnableOk} />
          </ContainerAgree>
        </Container>
      </ModalBody>
      <ModalFooter>
        <Button
          variant="outlined"
          disabled={!enableOk}
          onClick={props.handleOk}
        >
          OK
        </Button>
      </ModalFooter>
    </Modal>
  );
}
