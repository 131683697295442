import React, { useEffect } from 'react';

import { Router } from 'react-router-dom';
import Routes from './routes';

import {shallowEqual, useSelector} from 'react-redux'

import history from 'services/history';
import GlobalStyle from 'globalStyle';

import {ToastContainer} from 'react-toastify'

import 'react-toastify/dist/ReactToastify.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function App() {
  
  const state = useSelector((state) => state.auth['bgWhite'], shallowEqual)
  
  useEffect(() => { 

  }, [state])

  return (
    <Router history={history}>
      <GlobalStyle bgColor={state} />
      <Routes />
      <ToastContainer/>
    </Router>
  );
}
