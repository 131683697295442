import styled from "styled-components";

import { Tab } from "@material-ui/core";

export const TabsStyled = styled(Tab)`
  max-width: ${(props) => (props.width ? props.width : "50%")} !important;
  width: ${(props) => (props.width ? props.width : "50%")} !important;
  button {
    max-width: ${(props) => (props.width ? props.width : "50%")} !important;
    width: ${(props) => (props.width ? props.width : "50%")} !important;
  }
`;
