import React from 'react'
import { Title, Image, Button, MaterialBox } from './style'
import { Container } from '@material-ui/core'


export default function Signup() {

  return (
    <Container maxWidth="sm">
      <MaterialBox display="flex" alignItems="center" flexDirection="column">
        <Title>Virtua Care</Title>
        <Image></Image>
        <h2>Bem-vindo</h2>
        <p>Pronto socorro na palma da sua mão</p>
        <Button variant="signup">Cadastre-se</Button>
        <Button variant="signin">Entrar</Button>
      </MaterialBox>
    </Container>
  )
}