import React, { Component } from "react";
import { BrowserRouter, Switch } from "react-router-dom";

import Route from "./route";

import Dashboard from "pages/dashboard";
import Login from "pages/login";

//Doctors
import Doctors from "pages/doctors";
import DoctorsShow from "pages/doctors/show";
import DoctorCreate from "pages/doctors/create";

//Client
import Clients from "pages/clients";
import ClientsShow from "pages/clients/show";

//Admin
import Admin from "pages/admin";
import AdminShow from "pages/admin/show";

// Planos
import Plans from "pages/plans";

// Beneficiários
import Beneficiaries from "pages/beneficiaries";

import { BeneficiariesAdmin }  from "pages/beneficiariesadmin";

// Pagamentos
import Payments from "pages/payments";
import Queue from "pages/queue";

// Cadastro de cliente
import ClientHome from "pages/clientAuth";
import Signup from "pages/clientAuth/signup";
import AdminCreate from "pages/admin/create";
import Triage from "pages/triage";
import PlanComparison from "pages/planComparison";
import PlanPayment from "pages/planPayment";
import AddBeneficiary from "pages/beneficiaries/add";
import ValidateEmail from "pages/validateEmail";
import ForgotPassword from "pages/forgotPassword";
import AddMultipleBeneficiaries from "pages/beneficiaries/addMultiple";
import AddMultipleDependents from "pages/beneficiaries/addMultipleDependents";

class Routes extends Component {
  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route
            path="/"
            exact
            component={Dashboard}
            bgGrey
            isAdmin
            isPrivate
          />
          <Route
            path="/dashboard"
            exact
            component={Dashboard}
            isAdmin
            bgGrey
            isPrivate
          />
          <Route path="/login" exact component={Login} bgGrey />
          {/* Médicos  */}
          <Route
            path="/medicos"
            exact
            component={Doctors}
            isAdmin
            bgGrey
            isPrivate
          />
          <Route
            path="/medicos/:id"
            exact
            component={DoctorsShow}
            isAdmin
            isPrivate
            bgWhite
          />
          <Route path="/cadastro/:hash" exact component={DoctorCreate} />
          {/* Clientes */}
          <Route
            path="/clientes"
            exact
            component={Clients}
            isAdmin
            bgGrey
            isPrivate
          />
          <Route
            path="/clientes/:id"
            exact
            component={ClientsShow}
            isAdmin
            bgWhite
            isPrivate
          />
          {/* Admin */}
          <Route
            path="/administrador"
            exact
            isAdmin
            component={Admin}
            bgGrey
            isPrivate
          />
          <Route
            path="/administrador/:id"
            exact
            isAdmin
            component={AdminShow}
            bgWhite
            isPrivate
          />
          <Route
            path="/administrador/cadastro/:hash"
            exact
            component={AdminCreate}
            bgWhite
          />
          {/* Planos */}
          <Route
            path="/planos"
            exact
            component={Plans}
            isUser
            bgGrey
            isPrivate
          />
          {/* Pagamentos */}
          <Route
            path="/pagamentos"
            exact
            component={Payments}
            isUser
            bgGrey
            isPrivate
          />
          {/* Beneficiários */}
          <Route
            path="/beneficiariosadmin"
            exact
            component={BeneficiariesAdmin}
            isAdmin
            bgGrey
            isPrivate
          />
          <Route
            path="/beneficiarios"
            exact
            component={Beneficiaries}
            isUser
            bgGrey
            isPrivate
          />
          <Route
            path="/beneficiarios/add"
            exact
            component={AddBeneficiary}
            isUser
            bgGrey
            isPrivate
          />
          <Route
            path="/beneficiarios/add-multiple"
            exact
            component={AddMultipleBeneficiaries}
            isUser
            bgGrey
            isPrivate
          />
          <Route
            path="/beneficiarios/add-multiple-dependents/:id"
            exact
            component={AddMultipleDependents}
            isUser
            bgGrey
            isPrivate
          />
          <Route
            path="/beneficiarios/add/:id"
            exact
            component={AddBeneficiary}
            isUser
            bgGrey
            isPrivate
          />
          {/* Fila */}
          <Route
            path="/fila"
            exact
            component={Queue}
            isAdmin
            bgGrey
            isPrivate
          />
          {/* Cadastro de cliente */}
          <Route path="/client/" exact component={ClientHome} bgGrey />
          <Route path="/client/signup" exact component={Signup} bgGrey />
          {/* Triagem */}
          <Route
            path="/triagem/:id"
            exact
            isAdmin
            isPrivate
            component={Triage}
          />
          {/* Comparação de planos */}
          <Route
            path="/planos/compare"
            exact
            isUser
            isPrivate
            component={PlanComparison}
          />
          {/* Pagamento de plano */}
          <Route
            path="/planos/pagamento"
            exact
            isUser
            isPrivate
            component={PlanPayment}
          />
          {/* Validar Email */}
          <Route path="/validar-email/:token" exact component={ValidateEmail} />
          {/* Recuperação de senha */}
          <Route
            path="/recuperacao-senha/:token"
            exact
            component={ForgotPassword}
          />
        </Switch>
      </BrowserRouter>
    );
  }
}

export default Routes;
