import styled from 'styled-components';

import { Grid, Tab } from '@material-ui/core';

export const Options = styled(Grid)`
  margin-top: 2em;
  margin-bottom: 2em;
  justify-content: space-between;
  justify-content: ${props => props.justifyEnd ? 'flex-end' : ''} ; 
  button {
    text-transform: none;
    font-size: 16px;
  }
`;

export const TabsStyled = styled(Tab)`
  max-width: 50%!important;
  width: 50%!important;
  button {
    max-width: 50%!important;
    width: 50%!important;
    
  }
`;
