import { Input } from "components/inputs";
import Main from "components/main";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Slider from "react-slick";
import { toast } from "react-toastify";
import api from "services/api";
import { calculateValue } from "utils/calculate-value";
import { PlanContainer, PlanDetails, PlanPeriodValue } from "./style";

export default function PlanComparison({ location }) {
  const history = useHistory();

  let plans = [];

  const [beneficiaryNumber, setBeneficiaryNumber] = useState(1);

  try {
    plans = location.state.plans;
  } catch (err) {
    history.push("/planos");
  }
  const [plansToCompare, setPlansToCompare] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getPlans = async () => {
      const dynamicPlansToCompare = plans
        .filter((plan) => plan.type === "PJ" || plan.type === "MULTIPLAN")
        .map((plan) => plan._id);
      const plansToCompare = plans
        .filter((plan) => plan.type === "PF")
        .map((plan) => plan._id);

      const gettedPlans = await api.get("/plan");
      const gettedDynamicPlans = await api.get("/dynamic-plan");
      const combinedPlans = [
        ...gettedPlans.data.filter((plan) => plansToCompare.includes(plan._id)),
        ...gettedDynamicPlans.data.filter((plan) =>
          dynamicPlansToCompare.includes(plan._id)
        ),
      ];

      setPlansToCompare(combinedPlans);
      setLoading(true);
    };
    getPlans();
  }, [plans]);

  const redirectToPayment = async (plan, recurrence) => {
    try {
      if (plan.type === "PJ" || plan.type === "MULTIPLAN") {
        if (plan.min_users > 0 && plan.max_users > 0) {
          if (
            beneficiaryNumber < plan.min_users ||
            beneficiaryNumber > plan.max_users
          )
            throw new Error(
              "O número de beneficiários inserido é incompatível com esse plano"
            );
        } else if (plan.min_users === 0 && plan.max_users > 0) {
          if (beneficiaryNumber > plan.max_users)
            throw new Error(
              "O número de beneficiários inserido é incompatível com esse plano"
            );
        } else if (plan.min_users > 0 && plan.max_users === 0) {
          if (beneficiaryNumber < plan.min_users)
            throw new Error(
              "O número de beneficiários inserido é incompatível com esse plano"
            );
        }

        const beneficiaryRes = await api.get("beneficiary/owner/");
        const beneficiaries = beneficiaryRes.data;

        const holders = beneficiaries.length;
        const dependents = beneficiaries.reduce(
          (a, b) => a + (b.dependents ? b.dependents.length : 0),
          0
        );

        const beneficiaryLength = holders + dependents;

        if ((plan.max_users !== 0 && beneficiaryLength > plan.max_users) && plan.type === "PJ") {
          throw new Error(
            "Você tem mais beneficiários cadastrados do que o permitido para esse plano"
          );
        }

        if (beneficiaryLength < plan.min_users && plan.type === "PJ") {
          throw new Error(
            "Você não tem beneficiários o suficiente cadastrados para esse plano"
          );
        }

        if (beneficiaryLength > beneficiaryNumber) {
          throw new Error(
            "Você tem mais beneficiários cadastrados do que o permitido para esse plano"
          );
        }
      }

      let value = null;

      switch (recurrence) {
        case "ANUAL":
          value = calculateValue(plan.mensality, 12, plan.anuality);
          break;
        case "SEMESTRAL":
          value = calculateValue(plan.mensality, 6, plan.semestral);
          break;
        case "TRIMESTRAL":
          value = calculateValue(plan.mensality, 3, plan.trimestral);
          break;
        default:
          break;
      }

      history.push("/planos/pagamento", {
        plan: {
          ...plan,
          recurrence: recurrence,
          value: plan.type === "PF" ? value : value * beneficiaryNumber,
          number_users: beneficiaryNumber,
        },
      });
    } catch (err) {
      toast.error(err.message);
    }
  };

  const settings = {
    infinite: true,
    speed: 500,
    arrows: false,
    mobileFirst: true,
    responsive: [
      {
        breakpoint: 3000,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: plansToCompare.length > 3,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: plansToCompare.length > 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: plansToCompare.length > 1,
        },
      },
    ],
  };

  const multiplyByBeneficiaryNumber = (num) => {
    if (beneficiaryNumber > 1) return num * beneficiaryNumber;
    else return num;
  };

  const qtdBeneficiariosStyle = {
    color: "black",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "13px",
    marginTop: "30px",
  };

  return (
    <Main maxWidth="xl">
      <h4
        style={
          plans[0] && plans[0].type === "PJ" ? { marginBottom: "0px" } : null
        }
      >
        {plans.length === 1
          ? plans[0] && plans[0].type === "PJ"
            ? "PASSO 4: Insira o número total de usuários (titulares + dependentes) e escolha a vigência desejada."
            : "PASSO 2: Informe o número de usuários e escolha a vigência desejada"
          : "Comparação de planos"}
      </h4>
      {plans[0] && plans[0].type === "PJ" ? (
        <h5 style={{ marginTop: "10px" }}>
          *A vigência ANUAL tem os maiores descontos
        </h5>
      ) : null}
      <Slider {...settings}>
        {loading &&
          plansToCompare.map((plan) => (
            <PlanContainer key={plan._id} elevation={0}>
              <PlanDetails>
                <div>
                  <h6>{plan.name}</h6>
                  <div>
                    <span>R${plan.mensality.toFixed(2).replace(".", ",")}</span>
                    <label>
                      {plan.type === "PF" ? "Valor Avulso" : "Valor por pessoa"}
                    </label>
                  </div>
                </div>
                {plan.type === "PJ" || plan.type === "MULTIPLAN" ? (
                  <>
                    <text style={qtdBeneficiariosStyle}>
                      Insira a quantidade de beneficiários
                    </text>

                    <Input
                      type="number"
                      placeholder="Número de beneficiários"
                      onChange={(e) => setBeneficiaryNumber(e.target.value)}
                      value={beneficiaryNumber}
                    />
                    <div>
                      <label>Número de beneficiários</label>
                      <span>
                        {plan.min_users > 0 && plan.max_users === 0
                          ? plan.min_users + " + "
                          : plan.min_users === 0 && plan.max_users > 0
                          ? plan.max_users + " - "
                          : plan.min_users === 0 && plan.max_users === 0
                          ? "Customizado"
                          : `${plan.min_users} - ${plan.max_users}`}
                      </span>
                    </div>
                  </>
                ) : (
                  <div>
                    <label>Máximo de beneficiários</label>
                    <span>{plan.number_users}</span>
                  </div>
                )}
              </PlanDetails>
              <PlanPeriodValue>
                <h6>ANUAL</h6>
                <div>
                  <div>
                    <strong>
                      R${" "}
                      {multiplyByBeneficiaryNumber(
                        Number(calculateValue(plan.mensality, 12, plan.anuality).toFixed(2))
                      )
                        .toFixed(2)
                        .replace(".", ",")}
                    </strong>
                    <label>Valor mensal</label>
                  </div>
                  <div>
                    <span>
                      - R${" "}
                      {multiplyByBeneficiaryNumber(
                        Number(((plan.mensality * plan.anuality) / 100).toFixed(2))
                      )
                        .toFixed(2)
                        .replace(".", ",")}
                    </span>
                    <label>Desconto</label>
                  </div>
                </div>
                <button
                  type="button"
                  onClick={() => redirectToPayment(plan, "ANUAL")}
                >
                  Contratar
                </button>
              </PlanPeriodValue>
              <PlanPeriodValue>
                <h6>SEMESTRAL</h6>
                <div>
                  <div>
                    <strong>
                      R${" "}
                      {multiplyByBeneficiaryNumber(
                        Number(calculateValue(plan.mensality, 6, plan.semestral).toFixed(2))
                      )
                        .toFixed(2)
                        .replace(".", ",")}
                    </strong>
                    <label>Valor mensal</label>
                  </div>
                  <div>
                    <span>
                      - R${" "}
                      {multiplyByBeneficiaryNumber(
                        Number(((plan.mensality * plan.semestral) / 100).toFixed(2))
                      )
                        .toFixed(2)
                        .replace(".", ",")}
                    </span>
                    <label>Desconto</label>
                  </div>
                </div>
                <button
                  type="button"
                  onClick={() => redirectToPayment(plan, "SEMESTRAL")}
                >
                  Contratar
                </button>
              </PlanPeriodValue>
              <PlanPeriodValue>
                <h6>TRIMESTRAL</h6>
                <div>
                  <div>
                    <strong>
                      R${" "}
                      {multiplyByBeneficiaryNumber(
                        Number(calculateValue(plan.mensality, 3, plan.trimestral).toFixed(2))
                      )
                        .toFixed(2)
                        .replace(".", ",")}
                    </strong>
                    <label>Valor mensal</label>
                  </div>
                  <div>
                    <span>
                      - R${" "}
                      {multiplyByBeneficiaryNumber(
                        Number(((plan.mensality * plan.trimestral) / 100).toFixed(2))
                      )
                        .toFixed(2)
                        .replace(".", ",")}
                    </span>
                    <label>Desconto</label>
                  </div>
                </div>
                <button
                  type="button"
                  onClick={() => redirectToPayment(plan, "TRIMESTRAL")}
                >
                  Contratar
                </button>
              </PlanPeriodValue>
            </PlanContainer>
          ))}
      </Slider>
    </Main>
  );
}
