import { Grid } from '@material-ui/core'
import { Colors } from 'globalStyle'
import styled from 'styled-components'

export const Form = styled.form`
  margin-top: 1em;
`

export const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  button {
    height: 30px;
    background-color: ${Colors.blue};
    color: white;
    outline: none;
    border:none;
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    text-align: center;
    cursor: pointer;
    border-radius: 4px;
    padding-left: 6px;
    padding-right: 6px;
  }
`

export const InputContainer = styled(Grid)`
  display: flex;
  flex-wrap: wrap;

  input, select {
    margin-top: 0;
    max-width: 100%;
  }

  div.file-input{
    width: 64px;
    height: 64px;

    img{
      width: 64px;
      height: 64px;
      object-fit: cover;
    }

    label{
      margin: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
`