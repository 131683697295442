import styled from 'styled-components';
import Modal from 'components/modal';
import { Grid } from '@material-ui/core';
import { RadioInput } from 'components/inputs';

export const PaymentModal = styled(Modal)`
  label {
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    display: flex;
    align-items: center;
    color: #6B6C7E;
  }
  z-index: 9999;
`

export const PaymentFormContainer = styled(Grid)`
  max-width: 446px;

  @media(max-width: 960px) {
    max-width: 100%;
  }

  h4 {
    padding: 6px;
  }

  h5 {
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    display: flex;
    align-items: center;
    color: #6B6C7E;
  }
`

export const FormatedRadioInput = styled(RadioInput)`
  flex-direction: row;
  justify-content: space-between;

  label {
    width: calc(50% - 4px);
    border: 1px solid #D8DDE2;
    box-sizing: border-box;
    border-radius: 4px;
    margin: 0;
    padding: 0;
    height: 45px;
    display: flex;
    align-items: center;
  }
`