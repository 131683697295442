import { takeLatest, all, call, put } from "redux-saga/effects";
import api from "services/api";

import jwt_decode from "jwt-decode";

import { login_success } from "./actions";
import { setUser } from "../user/actions";
import { toast } from "react-toastify";

export function* login({ payload }) {
  const { email, password } = payload;
  try {
    const response = yield call(api.post, "auth/login", {
      email,
      password,
    });

    if (response.status === 201) {
      const decoded = jwt_decode(response.data.access_token);
      const { name, profile, _id } = decoded;
      const access_token = `Bearer ${response.data.access_token}`;
      const res = yield call(api.get, `user/profile-pic/${_id}`, {
        headers: { Authorization: access_token },
      });
      const user = yield call(api.get, `user/${_id}`, {
        headers: { Authorization: access_token },
      });
      yield put(setUser(name, profile, _id, res.data, user.data.cpfcnpj, user.data.type));
      yield put(login_success(access_token));
    }
  } catch (err) {
    toast.error(err.response.data.message);
  }
}

export function setToken({ payload }) {
  if (!payload) return;

  const { access_token } = payload.auth;

  if (access_token) api.defaults.headers.Authorization = access_token;
}

export default all([
  takeLatest("persist/REHYDRATE", setToken),
  takeLatest("@auth/LOGIN", login),
]);
