import React, { useState, useEffect } from "react";

import Main from "components/main";
import { Grid } from "@material-ui/core";
import {
  Input,
  InputGroup,
  InputGroupBody,
  InputGroupHeader,
} from "components/inputs";

import UserStatusBar from "components/userStatusBar";

import { Containers } from "./style";
import api from "services/api";
import { useHistory, useParams } from "react-router-dom";

import {
  formatCpfCnpj,
  formatDateWithTimezone,
  formatPhone,
  statusAlias,
} from "helper";
import FileCard from "components/fileCard";
import { toast } from "react-toastify";

export default function DoctorShow(props) {
  const [doctor, setDoctor] = useState({});
  const { id } = useParams();
  const history = useHistory();

  useEffect(() => {
    async function getDoctor() {
      const response = await api.get(`/doctor/${id}`);
      setDoctor(response.data);
    }
    getDoctor();
  }, [id]);

  const changeUserStatus = async (id) => {
    try {
      await api.put(`/user/change-status/${id}`);
      toast.success("Status do usuário alterado com sucesso");
      history.push("/medicos");
    } catch (err) {
      toast.error(err.response.data.message);
    }
  };

  return (
    <Main noContainer>
      <UserStatusBar
        name={doctor.user && doctor.user.name ? doctor.user.name : ""}
        tel={doctor.user && doctor.user.telephone ? doctor.user.telephone : ""}
        status={
          doctor.user && doctor.user.status
            ? statusAlias(doctor.user.status)
            : ""
        }
        memberDate={
          doctor.user && doctor.user.createdAt
            ? formatDateWithTimezone(doctor.user.createdAt)
            : ""
        }
        dashboard
        profile_pic={doctor.user && doctor.user ? doctor.user.profile_pic : ""}
        option={() => console.log("")}
        actions={[
          {
            name:
              doctor.user && doctor.user.status === "ACTIVE"
                ? "Inativar"
                : "Ativar",
            action: () => changeUserStatus(doctor.user && doctor.user._id),
          },
        ]}
      />
      <Containers maxWidth="100%">
        <Grid container>
          <Grid item xs={8}>
            <InputGroup>
              <InputGroupHeader>
                <h2>Dados Pessoais</h2>
              </InputGroupHeader>
              <Input
                placeholder="CPF"
                label="CPF"
                disabled
                width="190px"
                value={
                  doctor.user && doctor.user.cpfcnpj
                    ? formatCpfCnpj(doctor.user.cpfcnpj)
                    : ""
                }
                left="0"
              />
              <Input
                placeholder="Nome"
                label="Nome"
                disabled
                width="550px"
                value={doctor.user && doctor.user.name ? doctor.user.name : ""}
                left="0"
              />
              <Input
                placeholder="Email"
                label="Email"
                disabled
                value={
                  doctor.user && doctor.user.email ? doctor.user.email : ""
                }
                left="0"
              />
              <Input
                placeholder="Telefone"
                label="Telefone"
                disabled
                width="170px"
                value={
                  doctor.user && doctor.user.telephone
                    ? formatPhone(doctor.user.telephone)
                    : ""
                }
                left="0"
              />
            </InputGroup>
            <InputGroup>
              <InputGroupHeader>
                <h2>Profissão</h2>
              </InputGroupHeader>
              <Input
                placeholder="Profissão"
                label="Profissão"
                disabled
                value={doctor && doctor.speciality ? doctor.speciality : ""}
                left="0"
              />
              <InputGroupBody direction="row">
                <Input
                  name="crm"
                  placeholder="CRM"
                  label="CRM"
                  width="108px"
                  value={doctor && doctor.crm ? doctor.crm : ""}
                  disabled
                  left="0"
                />
                <Input
                  name="crm-state"
                  placeholder="SP"
                  label="UF"
                  width="60px"
                  value={doctor && doctor.crm_state ? doctor.crm_state : ""}
                  left={10}
                  max-length="2"
                  disabled
                />
              </InputGroupBody>
            </InputGroup>
            <InputGroup>
              <InputGroupHeader>
                <h2>Vínculo contratual</h2>
              </InputGroupHeader>
              <Input
                placeholder="CNPJ"
                label="CNPJ"
                disabled
                left="0"
                value={doctor && doctor.cnpj ? formatCpfCnpj(doctor.cnpj) : ""}
              />
              <Input
                placeholder="Razão social"
                label="Razão social"
                disabled
                left="0"
                value={doctor && doctor.social ? doctor.social : ""}
              />
            </InputGroup>
          </Grid>
          <Grid item xs={4}>
            <InputGroupHeader>
              <h2 style={{ marginTop: "15px" }}>Documentação</h2>
            </InputGroupHeader>

            <FileCard
              title="Contrato"
              name="Contrato"
              extension={
                doctor.user ? doctor.user.docs.contract_attachment.type : ""
              }
              size={doctor.user ? doctor.user.docs.contract_attachment.size : 0}
              file={doctor.user ? doctor.user.docs.contract_attachment.url : ""}
            />
            <FileCard
              title="CRM"
              name="CRM"
              extension={
                doctor.user ? doctor.user.docs.crm_attachment.type : ""
              }
              size={doctor.user ? doctor.user.docs.crm_attachment.size : 0}
              file={doctor.user ? doctor.user.docs.crm_attachment.url : ""}
            />
            <FileCard
              title="Ficha cadastral"
              name="Cadastro"
              extension={
                doctor.user ? doctor.user.docs.ficha_attachment.type : ""
              }
              size={doctor.user ? doctor.user.docs.ficha_attachment.size : 0}
              file={doctor.user ? doctor.user.docs.ficha_attachment.url : ""}
            />
            <FileCard
              title="Documento de identidade"
              name="Identidade"
              extension={
                doctor.user ? doctor.user.docs.identity_attachment.type : ""
              }
              size={doctor.user ? doctor.user.docs.identity_attachment.size : 0}
              file={doctor.user ? doctor.user.docs.identity_attachment.url : ""}
            />
          </Grid>
        </Grid>
      </Containers>
    </Main>
  );
}
