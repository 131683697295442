import React from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  Button,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  TableFooter,
  TablePagination,
} from "@material-ui/core";

import { formatCpfCnpj } from "helper";

export const BeneficiariesAdmTable = ({
  beneficiaries,
  page,
  setPage,
  itemsPerPage,
  setItemsPerPage,
  count,
  changeStatus,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setItemsPerPage(parseInt(event.target.value, 10));
    dispatch({
      type: "@pagination/ROWS_PER_PAGE",
      payload: {
        rows_per_page: parseInt(event.target.value, 10),
      },
    });
    setPage(0);
  };

  return (
    <TableContainer>
      <Table>
        {beneficiaries.map((b) =>
          <TableRow key={b._id} style={{ backgroundColor: b.status ? "" : "#FEEFEF" }}>
            <TableCell>{b.name}</TableCell>
            <TableCell>{formatCpfCnpj(b.cpf)}</TableCell>
            <TableCell>{b.type === "HOLDER" ? "TIT" : "DEP"}</TableCell>
            <TableCell>{b.email}</TableCell>
            <TableCell>
              <Button
                variant="outlined"
                onClick={() => history.push(`/clientes/${b.owner_id}`)}
              >
                Ver Cliente
              </Button>
            </TableCell>
            <TableCell>
              <Button
                variant="outlined"
                onClick={() => changeStatus(b)}
                width="200px"
              >
                {b.status ? "Inativar" : "Ativar"}
              </Button>
            </TableCell>
          </TableRow>
        )}
        <TableFooter>
          <TableRow>
            <TablePagination
              count={count}
              rowsPerPage={itemsPerPage}
              page={page}
              SelectProps={{
                inputProps: { "aria-label": "Itens por página" },
                native: true,
              }}
              onChangePage={handleChangePage}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};
