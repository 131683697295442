import styled from 'styled-components'
import { Paper } from '@material-ui/core'

export const FileCardContainer = styled.div`
  h6 {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #6B6C7E;
    margin-top: 24px;
    margin-bottom: 16px;
  }
`

export const StyledCard = styled(Paper)`
  display:flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  max-width: 256px;
  background: #FFFFFF;
  border: 1px solid #D8DDE2 !important;
  padding:12px;
  box-sizing: border-box !important;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.05) !important;
  border-radius: 4px;
  div.file-card-desc {
    height:100%;
      small {
        margin:0;
        padding:0;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        color: #42526E;
        margin-bottom: 3px;
      }
      p {
        margin:0;
        padding:0;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: #B3BAC5;
      }
  }

  div.file-card-icons {
    width:44px;
    display: flex;
    justify-content: space-between;
    
    img {
      cursor: pointer;
    }
  }

`