import React, { useEffect } from 'react'
import Main from 'components/main'
import AddBeneficiaryForm from 'components/client/beneficiary/form'
import { useHistory, useParams } from 'react-router-dom'
import api from 'services/api'
import jwtDecode from 'jwt-decode'
import { store } from 'store'

export default function AddBeneficiary() {
  
  const { id } = useParams()
  const history = useHistory()

  useEffect(()=> {
    const verifyBeneficiary = async () => {
      try{
        if(id) {
          const beneficiary = await api.get("/beneficiary/"+id)
          const { access_token } = store.getState().auth;
          const {_id} = jwtDecode(access_token)
          if(!beneficiary.data || beneficiary.data.owner_id !== _id)
            history.push('/beneficiarios')
        }
      } catch (err) {
        console.log(err)
      }
      
    } 
    verifyBeneficiary()
  },[history, id])

  return(
    <Main maxWidth="xl">
      <AddBeneficiaryForm  beneficiary_id={id}/>
    </Main>
  )
}