import React, { useEffect, useState } from "react";

import Main from "components/main";
import { Grid } from "@material-ui/core";
import { Input, InputGroup, InputGroupHeader } from "components/inputs";

import UserStatusBar from "components/userStatusBar";

import { Containers } from "./style";
import { Card, CardHeader, CardNumber } from "components/card";
import { useHistory, useParams } from "react-router-dom";
import api from "services/api";
import {
  formatCpfCnpj,
  formatDateWithTimezone,
  formatPhone,
} from "../../../helper";
import { toast } from "react-toastify";

export default function AdminShow() {
  const [admin, setAdmin] = useState({});
  const { id } = useParams();
  const history = useHistory();

  useEffect(() => {
    async function getAdmin() {
      const response = await api.get("user/" + id);
      const getProfilePic = (await api.get(`user/profile-pic/${id}`)).data;
      const adminResponse = response.data;
      adminResponse.profile_pic = getProfilePic;
      setAdmin(adminResponse);
    }
    getAdmin();
  }, [id]);

  const changeUserStatus = async (id) => {
    try {
      await api.put(`/user/change-status/${id}`);
      toast.success("Status do usuário alterado com sucesso");
      history.push("/administrador");
    } catch (err) {
      toast.error(err.response.data.message);
    }
  };

  return (
    <Main noContainer>
      <UserStatusBar
        name={admin.name}
        tel={admin.telephone}
        status={admin.status}
        memberDate={formatDateWithTimezone(admin.createdAt)}
        dashboard
        option
        profile_pic={admin.profile_pic}
        actions={[
          {
            name: admin && admin.status === "ACTIVE" ? "Inativar" : "Ativar",
            action: () => changeUserStatus(admin && admin._id),
          },
        ]}
      />
      <Containers top="10">
        <Card>
          <CardHeader label="Nível de acesso" />
          <CardNumber color="darkGrey"> {admin.profile} </CardNumber>
        </Card>
      </Containers>
      <Containers>
        <Grid container>
          <Grid item xs={8}>
            <InputGroup>
              <InputGroupHeader>
                <h2>Dados Pessoais</h2>
              </InputGroupHeader>
              <Input
                placeholder="CPF"
                label="CPF"
                disabled
                width="190px"
                value={admin.cpfcnpj ? formatCpfCnpj(admin.cpfcnpj) : ""}
              />
              <Input
                placeholder="Nome"
                label="Nome"
                disabled
                width="550px"
                value={admin.name ?? ""}
              />
              <Input
                placeholder="Email"
                label="Email"
                disabled
                value={admin.email ?? ""}
              />
              <Input
                placeholder="Telefone"
                label="Telefone"
                disabled
                width="170px"
                value={admin.telephone ? formatPhone(admin.telephone) : ""}
              />
            </InputGroup>
          </Grid>
        </Grid>
      </Containers>
    </Main>
  );
}
