import React, { useState } from 'react'
import Modal, {ModalHeader, ModalBody, ModalFooter} from 'components/modal'
import {Input} from 'components/inputs'
import api from 'services/api'
import { toast } from 'react-toastify'

export default function ForgotPasswordModal({show, handleClose}) {

  const [form, setForm] = useState({email:''})

  const handleSubmit = async e => {
    e.preventDefault()
    try {
      await api.post('/user/forgot-password', form)
      toast.success("E-Mail de recuperação de senha enviado com sucesso")
      handleClose()
    } catch (err) {
      toast.error(err.response.data.message)
    }
  }
  

  return (
    <Modal show={show} onSubmit={handleSubmit}>
      <ModalHeader close={handleClose}> Recuperação de senha </ModalHeader>
      <ModalBody>
        <Input label='Insira seu e-mail' onChange={(e)=>setForm({email:e.target.value})} width="100%"/>
      </ModalBody>
      <ModalFooter cancel={handleClose} next='Enviar' />
    </Modal>
  )
}