import styled from "styled-components";

export const ProfileDiv = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: 10px;
  right: 24px;
  /* background-color: blueviolet; */
`;

export const ProfileInfo = styled.div`
  font-family: "Montserrat";
  text-align: right;
  max-width: 150px;
`;
export const Name = styled.div`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
export const Occupation = styled.div`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 150%;
  text-transform: uppercase;
  color: #6b6c7e;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
export const ProfilePicture = styled.div`
  width: 32px;
  height: 32px;
  margin-left: 0.5em;
  img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
  }
`;

export const Logout = styled.div`
  width: 100px;
  position: absolute;
  z-index: 998;
  top: 54px;
  right: 5px;
  border-radius: 5px;
  box-shadow: 1px 1px 10px #333;
  background-color: white;
`;
