import styled from 'styled-components';
import { Colors } from 'globalStyle';

export const StatusBar = styled.div`
  position: fixed;
  top: 62px;
  height: 82px;
  width: 100%;
  max-width: 100%;

  display: flex;
  flex-direction: row;

  border-top: 1px solid #dfe1e6;
  border-bottom: 1px solid #dfe1e6;
  align-items: center;

  background-color: #ffffff;

  /* container */
  flex-wrap: wrap;
  & > div {
    display: flex;
    flex-direction: row;
    height: 82px;
  }
  .align-center {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;

export const Patient = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  margin-left: 30px;
  height: 82px;
  align-items: center;
  img {
    border-radius: 50%;
    width: 50px;
    height: 50px;
  }
`;

export const PatientData = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 15px;
  margin-right: 33px;

  h5 {
    margin: 0;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 28px;
  }
  h6 {
    margin: 0;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 28px;
  }
  span {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: ${Colors.whiteGrey};
  }
`;

export const PatientDetails = styled.div`
  display:flex;
  flex-direction: row;
  width:20%;
  p {
    margin: 0;
    margin-left: auto;
    font-size: 12px;
    line-height: 150%;
    color: #103458;

    span {
      font-size: 14px;
      font-weight: 500;
    }
  }
`
