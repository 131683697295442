import { Grid } from '@material-ui/core'
import { RadioInput } from 'components/inputs'
import styled from 'styled-components'

export const PaymentFormContainer = styled(Grid)`
  max-width: 446px;

  @media(max-width: 960px) {
    max-width: 100%;
  }

  h5 {
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    display: flex;
    align-items: center;
    color: #6B6C7E;
  }
`

export const FormatedRadioInput = styled(RadioInput)`
  flex-direction: row;
  justify-content: space-between;

  label {
    height: 40px !important;
    width: calc(50% - 4px);
    border: 1px solid #D8DDE2;
    box-sizing: border-box;
    border-radius: 4px;
    margin: 0;
    padding: 0;
    height: 90px;
    display: flex;
    align-items: center;
    background-color: white;
  }
`

export const Terms = styled.div`
  margin-top: 30px;
`

export const Buttons = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

 

  button {
    border-radius: 4px;
    width: 48%;
    height: 45px;
    background: #FFFFFF;
    border: 1px solid #6B6C7E;
    box-sizing: border-box;
    border-radius: 4px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #6B6C7E;
    cursor: pointer;

    &:disabled {
      background-color: transparent;
      color: #CACACA;
      border: 1px solid #CACACA;
    }
  }

  button + button {
    border: none;
    background: #3089C3;
    border-radius: 4px;
    font-weight: 600;
    color: #FFFFFF;
  }
`