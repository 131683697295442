import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import Slider from "react-slick";
import { toast } from "react-toastify";
import * as moment from "moment-timezone";
import api from "services/api";
import {
  cpfValidator,
  dateValidator,
  phoneValidator,
} from "utils/input-validators";
import { cpfMask, dateMask, phoneMask } from "utils/masks";
import AddBeneficiaryStep from "./step";

export default function AddBeneficiaryForm({ beneficiary_id }) {
  const slider1 = useRef();
  const [sliderState, setSliderState] = useState({
    activeSlide: 0,
  });
  const history = useHistory();

  const [form, setForm] = useState({
    type: beneficiary_id ? "DEPENDENT" : "",
    holder_id: beneficiary_id ? beneficiary_id : "",
  });
  const [beneficiaries, setBeneficiaries] = useState([]);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const getHolders = async () => {
      const response = await api.get("beneficiary/owner/");
      setBeneficiaries(
        response.data.map((val) => ({ label: val.name, value: val._id }))
      );
    };
    getHolders();
    const getUsers = async () => {
      const response = await api.get("/user/clients/self");
      setUser(response.data);
    };
    getUsers();

    if (beneficiary_id) {
      slider1.current.slickGoTo(2);
    }
  }, [beneficiary_id]);

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleFileChange = (e) => {
    setForm({ ...form, profile_pic: e.target.files[0] });
  };

  const handleNext = () => {
    slider1.current.slickNext();
  };

  const typeList = {
    PF: [
      { label: "Titular", value: "HOLDER" },
      { label: "Dependente", value: "DEPENDENT" },
      { label: "Eu mesmo", value: "MYSELF" },
    ],
    PJ: [
      { label: "Titular", value: "HOLDER" },
      { label: "Dependente", value: "DEPENDENT" },
    ],
  };

  const handleBeneficiaryType = async () => {
    try {
      if (form.type === "MYSELF") {
        if (user.type === "PJ") {
          toast.error("Não é possível cadastrar uma empresa como beneficiária");
        } else {
          const beneficiaryData = {
            name: user.name,
            cpf: user.cpfcnpj,
            email: user.email,
            birth_date: user.birthDate,
            genre: user.genre,
            profile_pic: user.profile_pic,
            owner_id: user._id,
            telephone: user.telephone,
            type: "HOLDER",
            ownUser: true,
          };
          await api.post("/beneficiary", beneficiaryData);
          toast.success("Beneficiário cadastrado com sucesso");
          history.push("/beneficiarios");
        }
      } else {
        slider1.current.slickNext();
      }
    } catch (err) {
      if (form.type === "MYSELF") {
        toast.error("Você já está ativo como beneficiário");
      } else {
        toast.error(err.response.data.message);
      }
    }
  };

  const validateCpf = async (cpf) => {
    try {
      await api.get("/beneficiary/check-status?cpf=" + cpf.replace(/\D+/g, ""));
      slider1.current.slickNext();
    } catch (err) {
      toast.error(err.response.data.message);
    }
  };

  const handlePrev = () => {
    if (sliderState.activeSlide !== 0) {
      slider1.current.slickPrev();
    } else {
      history.push("/beneficiarios");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formUser = Object.assign({}, form);
      formUser.cpf = formUser.cpf.replace(/\D+/g, "");
      formUser.telephone = formUser.telephone.replace(/\D+/g, "");
      const formatedDate = moment(formUser.birth_date, "DD/MM/YYYY", true);
      formUser.birth_date = formatedDate.format("YYYY/MM/DD");

      const formData = new FormData();
      Object.keys(form).map((key) => formData.append(key, formUser[key]));

      await api.post("/beneficiary", formData);
      toast.success("Beneficiário cadastrado com sucesso");
      history.push("/beneficiarios");
    } catch (err) {
      toast.error(err.response.data.message);
    }
  };

  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    adaptativeHeight: true,
    dots: false,
    draggable: false,
    beforeChange: (current, next) =>
      setSliderState({ ...sliderState, activeSlide: next }),
  };

  return (
    <form onSubmit={handleSubmit}>
      <Slider ref={(slider) => (slider1.current = slider)} {...settings}>
        <AddBeneficiaryStep
          name="type"
          title="Tipo de beneficiário"
          desc="Selecione o tipo de beneficiário"
          type="radio"
          value={form.type}
          onChange={handleChange}
          buttonAction={handleBeneficiaryType}
          backAction={handlePrev}
          list={typeList[user?.type]}
        />
        {form.type === "DEPENDENT" && (
          <AddBeneficiaryStep
            title="Titular"
            desc="Selecione o beneficiário titular"
            name="holder_id"
            type="select"
            value={form.holder_id}
            onChange={handleChange}
            buttonAction={handleNext}
            list={beneficiaries}
            backAction={handlePrev}
          />
        )}
        <AddBeneficiaryStep
          title="Insira o CPF"
          desc="Somente números"
          name="cpf"
          value={form.cpf}
          onChange={handleChange}
          buttonAction={() => validateCpf(form.cpf)}
          backAction={handlePrev}
          type="mask"
          mask={cpfMask}
          verification={cpfValidator}
          verificationText="Insira um CPF válido"
        />
        <AddBeneficiaryStep
          title="Insira o nome"
          desc="Insira o nome completo"
          name="name"
          value={form.name}
          onChange={handleChange}
          buttonAction={handleNext}
          backAction={handlePrev}
        />
        <AddBeneficiaryStep
          title="Insira o email"
          desc="Insira o email do beneficiário"
          type="email"
          name="email"
          inputMode="email"
          value={form.email}
          onChange={handleChange}
          buttonAction={handleNext}
          backAction={handlePrev}
          verification={validateEmail}
          verificationText={"Insira um email válido"}
        />
        <AddBeneficiaryStep
          title="Gênero"
          desc="Selecione o gênero"
          name="genre"
          type="radio"
          value={form.genre}
          onChange={handleChange}
          buttonAction={handleNext}
          backAction={handlePrev}
          list={[
            { label: "Masculino", value: "Masculino" },
            { label: "Feminino", value: "Feminino" },
          ]}
        />
        <AddBeneficiaryStep
          title="Nascimento"
          desc="Insira sua data de nascimento"
          type="mask"
          name="birth_date"
          mask={dateMask}
          value={form.birth_date}
          onChange={handleChange}
          buttonAction={handleNext}
          backAction={handlePrev}
          verification={dateValidator}
          verificationText="Insira uma data válida"
        />
        <AddBeneficiaryStep
          title="Celular"
          desc="Insira seu celular"
          type="mask"
          name="telephone"
          inputMode="tel"
          mask={phoneMask}
          value={form.telephone}
          onChange={handleChange}
          buttonAction={handleNext}
          backAction={handlePrev}
          verification={phoneValidator}
          verificationText="Insira um telefone válido"
        />
        <AddBeneficiaryStep
          title="Foto de perfil"
          desc="Insira a foto de perfil"
          subdesc="*Sugerimos que a foto seja quadrada"
          type="img"
          value={form.profile_pic}
          onChange={handleFileChange}
          backAction={handlePrev}
          buttonAction={handleNext}
          submit
        />
      </Slider>
    </form>
  );
}

const validateEmail = (text) => {
  const re = new RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
  return re.test(text.toLowerCase());
};
