import React, { useState } from "react";

import {
  Grid,
  Paper,
  Table,
  TableContainer,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
  TableHead,
  TablePagination,
  Button,
} from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import { Card, CardHeader, CardNumber } from "components/card";

import fakeresponse from "assets/mocks/doctors/doctors.json";
import {
  CellTitle,
  PaperHeader,
  TitleDownload,
  SeeAll,
  CardBody,
} from "./style";

export default function Attendance() {
  //PAGINAÇÃO
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <>
      <Grid container>
        <Card lg mb="20px">
          <CardHeader
            double
            label="Total de exames"
            label2="Média por consulta"
          />
          <CardBody
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <h4> 400 </h4>
            </Grid>
            <Grid item>
              <h4> 1.500 </h4>
            </Grid>
          </CardBody>
        </Card>
        <Card lg mb="20px">
          <CardHeader
            double
            label="Total de encaminhamentos"
            label2="Média por consulta"
          />
          <CardBody
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <h4> 400 </h4>
            </Grid>
            <Grid item>
              <h4> 0.7 </h4>
            </Grid>
          </CardBody>
        </Card>
        <Card lg mb="20px">
          <CardHeader
            double
            label="Total de encaminhamentos"
            label2="Média por consulta"
          />
          <CardBody
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <h4> 400 </h4>
            </Grid>
            <Grid item>
              <h4> 0.7 </h4>
            </Grid>
          </CardBody>
        </Card>
        <Card lg mb="20px">
          <CardBody container direction="row" alignItems="center" mt="30px">
            <Grid item> TMA </Grid>
            <Grid item>
              <CardNumber color="blue" h3>
                {" "}
                15 min{" "}
              </CardNumber>
            </Grid>
          </CardBody>
        </Card>
        <Card lg mb="20px">
          <CardBody container direction="row" alignItems="center" mt="30px">
            <Grid item> TME </Grid>
            <Grid item>
              <CardNumber color="red" h3>
                {" "}
                15 min
              </CardNumber>
            </Grid>
          </CardBody>
        </Card>
        <Card lg mb="20px">
          <CardBody container direction="row" alignItems="center" mt="30px">
            <Grid item> NPS </Grid>
            <Grid item>
              <CardNumber color="green" h3>
                {" "}
                15 min{" "}
              </CardNumber>
            </Grid>
          </CardBody>
        </Card>
      </Grid>
      <Grid container direction="row" spacing={2}>
        <Grid item xs={6}>
          <Paper>
            <PaperHeader> Médicos que mais encaminharam pacientes </PaperHeader>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <CellTitle width="100">Posição</CellTitle>
                    <CellTitle width="300">Médico</CellTitle>
                    <CellTitle align="right" width="100">
                      Quantidade
                    </CellTitle>
                    <CellTitle align="right" width="100">
                      Consultas
                    </CellTitle>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {fakeresponse.doctors.map((user) => (
                    <TableRow key={user.__id}>
                      <TableCell>{user.__id}</TableCell>
                      <TableCell>{user.name}</TableCell>
                      <TableCell align="right">{user.__id}</TableCell>
                      <TableCell align="right">{user.__id}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Grid container justifyContent="center" spacing={5}>
              <SeeAll item>
                <Button> Ver todos </Button>
              </SeeAll>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper>
            <PaperHeader> Médicos que mais solicitaram exames </PaperHeader>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <CellTitle width="100">Posição</CellTitle>
                    <CellTitle width="300">Médico</CellTitle>
                    <CellTitle align="right" width="100">
                      Quantidade
                    </CellTitle>
                    <CellTitle align="right" width="100">
                      Consultas
                    </CellTitle>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {fakeresponse.doctors.map((user) => (
                    <TableRow key={user.__id}>
                      <TableCell>{user.__id}</TableCell>
                      <TableCell>{user.name}</TableCell>
                      <TableCell align="right">{user.__id}</TableCell>
                      <TableCell align="right">{user.__id}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Grid container justifyContent="center" spacing={5}>
              <SeeAll item>
                <Button> Ver todos </Button>
              </SeeAll>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <Grid>
        <TitleDownload>
          <h4> Consultas </h4>
          <Button>
            <GetAppIcon />
          </Button>
        </TitleDownload>
        <TableContainer>
          <Table style={{ border: "1px solid #CDCED9" }}>
            <TableHead>
              <TableRow>
                <TableCell align="center" width="100">
                  Cliente
                </TableCell>
                <TableCell align="center" width="300">
                  Médico
                </TableCell>
                <TableCell align="center" width="100">
                  Data
                </TableCell>
                <TableCell align="center" width="100">
                  Horário de entrada
                </TableCell>
                <TableCell align="center" width="100">
                  Duração
                </TableCell>
                <TableCell align="center" width="100">
                  NPS
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {fakeresponse.doctors
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((user) => (
                  <TableRow key={user.__id}>
                    <TableCell align="center">{user.__id}</TableCell>
                    <TableCell align="center">{user.name}</TableCell>
                    <TableCell align="center">{user.__id}</TableCell>
                    <TableCell align="center">{user.__id}</TableCell>
                    <TableCell align="center">{user.__id}</TableCell>
                    <TableCell align="center">{user.__id}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  colSpan={3}
                  count={fakeresponse.doctors.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: { "aria-label": "Itens por página" },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Grid>
      {/* <Paper>
  <ComposedChart width={730} height={250} data={data}>
    <XAxis dataKey='name' />
    <YAxis />
    <Tooltip />
    <Legend />
    <CartesianGrid stroke='#f5f5f5' />
    <Bar dataKey='pv' barSize={20} fill='#413ea0' />
    <Line type='monotone' dataKey='uv' stroke='#ff7300' />
  </ComposedChart>
</Paper> */}{" "}
    </>
  );
}
