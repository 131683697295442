import React, { useState } from "react";
import { FormControlLabel, Hidden, Radio, RadioGroup } from "@material-ui/core";
import {
  BeneficiaryStep,
  Button,
  DesktopButton,
  DesktopButtons,
  HelperText,
} from "./style";
import { ImgInput, Input, InputMask } from "components/inputs";

export default function AddBeneficiaryStep({
  title,
  desc,
  subdesc,
  backAction,
  buttonAction,
  name,
  type,
  onChange,
  value,
  verification,
  verificationText,
  list,
  mask,
  submit,
}) {
  const [helperText, setHelperText] = useState("");

  const handleNext = () => {
    if (!value) {
      return setHelperText("Campo obrigatório");
    }
    if (verification) {
      const verificationResult = verification(value);
      if (!verificationResult) setHelperText(verificationText);
      else {
        setHelperText("");
        if (buttonAction) buttonAction();
      }
    } else {
      if (buttonAction) buttonAction();
    }
  };

  return (
    <BeneficiaryStep maxWidth="xl">
      <strong>{title}</strong>
      <label style={{ marginBottom: "0px" }}>{desc}</label>
      <h6>{subdesc}</h6>
      <StepInput
        onChange={onChange}
        type={type}
        value={value}
        name={name}
        validationText={helperText}
        list={list}
        mask={mask}
      />
      <HelperText>{helperText}</HelperText>
      <Hidden mdUp>
        <Button type={submit ? "submit" : "button"} onClick={handleNext}>
          Continuar
        </Button>
      </Hidden>
      <Hidden smDown>
        <DesktopButtons>
          <DesktopButton back type="button" onClick={backAction}>
            Voltar
          </DesktopButton>
          <DesktopButton
            next
            type={submit ? "submit" : "button"}
            onClick={handleNext}
          >
            Avançar
          </DesktopButton>
        </DesktopButtons>
      </Hidden>
    </BeneficiaryStep>
  );
}

function StepInput({ type, value, name, onChange, list = [], mask }) {
  if (type === "radio") {
    return (
      <RadioGroup
        style={{ width: "250px" }}
        value={value}
        onChange={onChange}
        name={name}
      >
        {list.map((val) => (
          <FormControlLabel
            key={val.value}
            value={val.value}
            control={<Radio color="primary" />}
            label={val.label}
          />
        ))}
      </RadioGroup>
    );
  }
  if (type === "select") {
    return (
      <select value={value} onChange={onChange} label="Age" name={name}>
        <option disabled></option>
        {list.map((val) => (
          <option key={val.value} value={val.value}>
            {val.label}
          </option>
        ))}
      </select>
    );
  }
  if (type === "img") {
    return (
      <ImgInput
        name="profile_pic"
        className="file-container"
        onChange={onChange}
        value={value}
      />
    );
  }
  if (type === "mask") {
    return (
      <InputMask
        mask={mask}
        width="100%"
        onChange={onChange}
        type={type}
        value={value}
        name={name}
      />
    );
  } else {
    return (
      <Input
        onChange={onChange}
        width="100%"
        type={type}
        value={value}
        name={name}
      />
    );
  }
}
