import { Paper } from '@material-ui/core'
import styled from 'styled-components'

export const PlanContainer = styled(Paper)`
  display: flex;
  max-width: 30%;
  min-width: 98%;
  flex-direction: column;
  * {
    margin: 0;
    padding: 0;
  }
`

export const PlanDetails = styled.div`

  padding: 24px;
  border-bottom: 1px solid #EDEDEF;

  h6 {
    font-weight: 600;
    font-size: 20px;
    line-height: 150%;
    color: #2E5AAC;
    max-width: 112px;
  }

  span {
    font-weight: 600;
    font-size: 18px;
    line-height: 150%;
    text-align: right;
    color: #6B6C7E;
  }

  label {
    font-weight: normal;
    font-size: 12px;
    line-height: 150%;
    color: #6B6C7E;
  }

  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  div + div {
    margin-top:30px;
  }

  div > div {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    text-align: right;

    label {
      font-size: 10px;
      line-height: 150%;
      text-align: right;
      color: #A7A9BC;
    }

    span {
      font-weight: 600;
      font-size: 20px;
      line-height: 150%;
      text-align: right;
      color: #287D3C;
    }
  }
`

export const PlanPeriodValue = styled.div`
  padding: 24px;
  border-bottom: 1px solid #EDEDEF;
  display: flex;
  flex-direction: column;

  h6 {
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    color: #393A4A;
    align-self: center;
  }

  div > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  div {
    display: flex;
    justify-content: space-between;
    align-items: center;

    strong {
      font-weight: bold;
      font-size: 20px;
      line-height: 150%;
      margin-top: 16px;
      color: #287D3C;
    }

    span {
      font-weight: 600;
      font-size: 20px;
      line-height: 150%;
      margin-top: 16px;
      color: #2E5AAC;
    }

    label {
      font-weight: normal;
      font-size: 12px;
      line-height: 150%;
      color: #A7A9BC;
    }
  }

  button {
      background: #3089C3;
      border-radius: 4px;
      outline: none;
      border: none;
      color: white;
      height: 40px;
      width: 50%;
      max-width: 140px;
      align-self: center;
      cursor: pointer;
      margin-top: 24px;
  }
`