import React from 'react'
import { BeneficiaryCard, CardGrid, InfoContainer } from './style'
import { Grid } from '@material-ui/core'

export default function ClientBeneficiaryCard({registered, avaliable}) {
  return (
    <BeneficiaryCard elevation={0}>
      <h4>Beneficiários</h4>
      <CardGrid container>
        <Grid item md={6} xs={6}>
          <InfoContainer>
            <strong>{registered}</strong>
            <p>Beneficiários cadastrados</p>
          </InfoContainer>
        </Grid>
        <Grid item md={6} xs={6}>
          <InfoContainer>
            <strong>{avaliable}</strong>
            <p>Vagas de beneficiários disponíveis</p>
          </InfoContainer>
        </Grid>
      </CardGrid>
    </BeneficiaryCard>
  )
}
