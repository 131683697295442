import { Box } from '@material-ui/core';
import styled from 'styled-components';
import Group from '../../assets/icons/Group.png'
import {Colors} from '../../globalStyle'

export const MaterialBox = styled(Box)`
  h2 {
    padding: 0;
    margin: 0;
    margin-top: 40px;
  }

  p{
    margin-top:4px;
    margin-bottom: 24px;
    font-size:14px;
    color: ${Colors.darkGrey}
  }
`

export const Title = styled.h1`
  color: ${Colors.blue};
  font-weight: 600;
  font-size: 32px;
  line-height: 48px;
  margin: 0;
  margin-top: 40px;
  text-align:center;
  padding: 0;
`

export const Image = styled.img`
  background-image: url(${Group});
  width: 172px;
  height: 185px;
  margin-top:40px;
`

export const Button = styled.button`
  width: 100%;
  height: 45px;
  border-radius: 4px;
  max-width: 328px;
  outline: none;
  border: none;
  background-color: ${ props => props.variant === "signup" ? Colors.blue : "transparent" };
  font-size: 16px;
  color: ${ props => props.variant === "signup" ? "#FFFFFF" : Colors.darkGrey };
  font-weight: ${ props => props.variant === "signup" ? "600" : "500" };
  cursor:pointer;
  & + & {
    margin-top:20px
  }

`