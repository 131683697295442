import React, { useState } from 'react';
import { TextField, Tabs } from '@material-ui/core';

import Main from 'components/main';
import { TabPanel, FullTab } from 'components/tabs';
import { store } from 'store';
import Search from 'components/search';
import { Options } from './style';
import Financial from './financial';
import Attendance from './attendance';

export default function Dashboard() {
  const [value, setValue] = useState(0);

  const { profile } = store.getState().user

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Main maxWidth='xl'>
      <Tabs
        indicatorColor='primary'
        textColor='primary'
        aria-label='disabled tabs example'
        onChange={handleChange}
        value={value}
      >
        <FullTab label='Atendimento' />
        {profile === "GESTOR" &&
          <FullTab label='Financeiro' />
        }
      </Tabs>
      <Options container>
        <Search label='Pesquise por um médico' />
        <form>
          <label> De </label>
          <TextField type='date' variant='filled' />
          <label> Até </label>
          <TextField type='date' variant='filled' />
        </form>
      </Options>
      <TabPanel value={value} index={0}>
        <Attendance />
      </TabPanel>
      {profile === "GESTOR" &&
        <TabPanel value={value} index={1}>
          <Financial />
        </TabPanel>
      }
    </Main>
  );
}
