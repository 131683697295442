import { Container, Grid, Hidden } from "@material-ui/core";
import { ArrowBackIos } from "@material-ui/icons";
import FinishIcon from "assets/icons/finish.png";
import BgImage from "assets/images/bg_virtua.png";
import cep from "cep-promise";
import ConditionalCarousel from "components/conditionalCarousel";
import { Input, InputMask } from "components/inputs";
import SignupSteps from "components/signupSteps";
import { HelperText } from "../step/style";
import { useFormik } from "formik";
import { Colors } from "globalStyle";
import * as moment from "moment-timezone";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import api from "services/api";
import { cnpjValidator, cpfValidator } from "utils/input-validators";
import { cepMask, cnpjMask, cpfMask, dateMask, phoneMask } from "utils/masks";
import useDebounce from "utils/useDebounce";
import * as yup from "yup";
import StepInput from "../step/index";
import {
  Button,
  Form,
  DesktopButton,
  DesktopButtons,
  FinishContainer,
  Header,
} from "./style";
import { any } from "prop-types";
import { store } from "store";
import WhatsappIcon from "assets/icons/whatsapp.png";

export default function ClientSignupForm() {
  const { profile } = store.getState().user;

  const companyAccount = profile === "GESTOR" || profile === "OPERADOR";

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (
        formik.values.password.length < 8 ||
        formik.values.passwordConfirmation !== formik.values.password
      )
        return;

      const formUser = { ...formik.values };
      formUser.telephone = formUser.telephone.replace(/\D+/g, "");
      formUser.cpfcnpj = formUser.cpfcnpj.replace(/\D+/g, "");
      formUser.cep = formUser.cep.replace(/\D+/g, "");

      if (formUser.complement) {
        formUser.complement =
          formUser.complement.charAt(0).toUpperCase() +
          formUser.complement.slice(1);
      }
      if (formUser.birthDate) {
        const formatedDate = moment(formUser.birthDate, "DD/MM/YYYY", true);
        formUser.birthDate = formatedDate.format("YYYY/MM/DD");
      }

      const formData = new FormData();
      Object.keys(formik.values).map((key) =>
        formData.append(key, formUser[key])
      );

      postUser(formData);
      carousel.slideNext();
    } catch (err) {
      toast.error(err.response.data.message);
    }
  };

  const postUser = useDebounce(
    async (formData) => await api.post("/user", formData),
    3000
  );

  const formik = useFormik({
    initialValues: {
      type: companyAccount ? "PJ" : "PF",
      cpfcnpj: "",
      name: "",
      email: "",
      birthDate: "",
      telephone: "",
      genre: "",
      cep: "",
      number: "",
      profile: "CLIENTE",
      complement: "",
      profile_pic: null,
      password: "",
      passwordConfirmation: "",
      neighborhood: "",
      city: "",
      state: "",
      publicplace: "",
    },
    validateOnMount: true,
    validationSchema,
    onSubmit: handleSubmit,
  });

  const isValid = (field) => {
    return true || (formik.touched[field] && !formik.errors[field]);
  };

  const [addressError, setAddressError] = useState();
  const history = useHistory();

  const [carousel, setCarousel] = useState({
    slideNext: () => any,
    slidePrev: () => any,
  });

  const handleCepChange = (e) => {
    formik.setFieldValue("cep", e.target.value);
    if (e.target.value.length === 9) {
      searchCep(e.target.value, true);
    }
  };

  const handleFileChange = (e) => {
    formik.setFieldValue("profile_pic", e.target.files[0]);
  };

  const searchCep = async (cepNumber, noNext) => {
    try {
      const cepRes = await cep(cepNumber ? cepNumber : formik.values.cep);
      formik.setValues({
        ...formik.values,
        cep: cepNumber ? cepNumber : formik.values.cep,
        city: cepRes.city,
        neighborhood: cepRes.neighborhood,
        state: cepRes.state,
        publicplace: cepRes.street,
      });
      if (!noNext) carousel.slideNext();
    } catch (err) {
      toast.error("CEP inválido");
    }
  };

  const validateDate = (date) => {
    return moment(date, "DD/MM/YYYY", true).isValid();
  };

  const validatePassword = (text) => {
    const regex = new RegExp(
      "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[.,#?!@$%^&*-]).{8,}$"
    );
    return regex.test(text) && text === formik.values.passwordConfirmation;
  };

  // const validatePasswordText = (text) => {
  //   const regex = new RegExp(
  //     "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[.,#?!@$%^&*-]).{8,}$"
  //   );
  //   if (!regex.test(text))
  //     return "Deve conter ao menos uma letra maiúscula, uma letra minúscula, um número e um caractere especial";
  //   if ((text === formik.values.passwordConfirmation) === false) {
  //     return false;
  //   }
  //   return true;
  // };

  const validateEmail = (text) => {
    const re = new RegExp(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    return re.test(text.toLowerCase());
  };

  const validateCpfCnpj = async (document) => {
    const doc = formik.values.type === "PJ" ? "CNPJ" : "CPF";
    const usercpfCnpj = await api.get(
      "/user/validate-unique?cpfcnpj=" + document?.replace(/\D+/g, "")
    );
    usercpfCnpj.data
      ? toast.error(`O ${doc} inserido já pertence a um usuário do Virtua Care`)
      : carousel.slideNext();
  };

  const validateUniqueEmail = async (email) => {
    const userEmail = await api.get("/user/validate-unique?email=" + email);
    userEmail.data
      ? toast.error("O Email inserido já pertence a um usuário do Virtua Care")
      : carousel.slideNext(formik.values.type === "PJ" ? true : false);
  };

  const validateAddress = (addressParams) => {
    for (const key in addressParams) {
      if (!addressParams[key]) {
        setAddressError("Preencha todos os campos obrigatórios de endereço");
        return false;
      }
    }
    carousel.slideNext();
    setTimeout(() => {
      setAddressError("");
    }, 100);
    return true;
  };

  const handleSlidePrevMobile = () => {
    if (formik.values.type === "PJ") {
      if (carousel.activeIndex === 5 || carousel.activeIndex === 7) {
        return true;
      }
    }
  };

  return (
    <>
      <Hidden mdUp>
        <Container maxWidth="xl">
          <Header>
            <ArrowBackIos
              style={{
                fill: Colors.darkGrey,
                fontSize: "16px",
                cursor: "pointer",
              }}
              onClick={
                !carousel.activeIndex
                  ? () => history.push("/login")
                  : () => carousel.slidePrev(handleSlidePrevMobile())
              }
            />
          </Header>
        </Container>
      </Hidden>
      <Form onSubmit={handleSubmit}>
        <Grid container>
          <Grid item md={6} xs={12} style={{ margin: "auto" }}>
            <ConditionalCarousel
              setState={setCarousel}
              isValid={isValid}
              initialPage={formik.values.type === "PJ" ? 1 : 0}
            >
              <SignupSteps
                title="Tipo da conta"
                desc={"Como deseja abrir sua conta"}
              >
                <StepInput
                  name="type"
                  id="type"
                  type="radio"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.type}
                  backAction={() => history.push("/login")}
                  buttonAction={() => carousel.slideNext()}
                  radioOptions={[
                    {
                      value: "PF",
                      label: "Para mim, minha família ou meus amigos",
                    },
                  ]}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "center",
                      fontSize: "12px",
                      color: "#393a4a",
                      margin: 0,
                    }}
                  >
                    <p>
                      Para cadastro de empresas, entrar em contato com o
                      comercial
                    </p>
                    <a
                      href={`
                        https://api.whatsapp.com/send?phone=5511993210752&text=Ol%C3%A1%2C%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20o%20Virtua%20Care%20EMPRESAS.`}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ width: "40px" }}
                    >
                      <img
                        src={WhatsappIcon}
                        alt="Whatsapp"
                        style={{ width: "35px", margin: "5px 0" }}
                      />
                    </a>
                  </div>
                </StepInput>
              </SignupSteps>

              <SignupSteps
                title={`Insira ${
                  formik.values.type === "PJ" ? " o CNPJ" : " seu CPF"
                }`}
                desc="Somente números"
              >
                <StepInput
                  type="mask"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.cpfcnpj}
                  backAction={() => carousel.slidePrev()}
                  mask={formik.values.type === "PJ" ? cnpjMask : cpfMask}
                  name="cpfcnpj"
                  id="cpfcnpj"
                  pattern="[0-9]*"
                  inputMode="numeric"
                  buttonAction={() => validateCpfCnpj(formik.values.cpfcnpj)}
                  verification={
                    formik.values.type === "PJ" ? cnpjValidator : cpfValidator
                  }
                  verificationText={`Insira um ${
                    formik.values.type === "PJ" ? "CNPJ" : "CPF"
                  } válido`}
                />
              </SignupSteps>

              <SignupSteps
                title={`Insira ${
                  formik.values.type === "PJ"
                    ? " o nome da empresa"
                    : "seu nome"
                }`}
                desc={`Digite ${
                  formik.values.type === "PJ"
                    ? " o nome da empresa"
                    : "seu nome completo"
                }`}
              >
                <StepInput
                  backAction={() => carousel.slidePrev()}
                  buttonAction={() => carousel.slideNext()}
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                  name="name"
                  id="name"
                />
              </SignupSteps>

              <SignupSteps title="Email" desc="Insira seu Email">
                <StepInput
                  backAction={() => carousel.slidePrev()}
                  buttonAction={() =>
                    validateUniqueEmail(formik.values.email?.toLowerCase())
                  }
                  type="email"
                  onChange={(e) => {
                    e.target.value = e.target.value.toLowerCase();
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  name="email"
                  id="email"
                  inputMode="email"
                  verification={validateEmail}
                  verificationText={"Insira um email válido"}
                />
              </SignupSteps>

              {formik.values.type === "PF" && (
                <SignupSteps
                  title="Data de Nascimento"
                  desc="Insira sua data de nascimento"
                >
                  <StepInput
                    backAction={() => carousel.slidePrev()}
                    buttonAction={() => carousel.slideNext()}
                    type="mask"
                    inputMode="numeric"
                    mask={dateMask}
                    placeholder="dd/mm/aaaa"
                    verification={() => validateDate(formik.values.birthDate)}
                    verificationText="Insira uma data válida"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.birthDate}
                    name="birthDate"
                    id="birthDate"
                  />
                </SignupSteps>
              )}

              <SignupSteps title="Celular" desc="Somente números">
                <StepInput
                  backAction={() =>
                    carousel.slidePrev(
                      formik.values.type === "PJ" ? true : false
                    )
                  }
                  buttonAction={() =>
                    carousel.slideNext(
                      formik.values.type === "PJ" ? true : false
                    )
                  }
                  type="mask"
                  mask={phoneMask}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.telephone}
                  verification={() => {
                    const digits = formik.values.telephone.replace(/\D+/g, "");
                    return digits.length === 11;
                  }}
                  verificationText={"Insira um telefone válido"}
                  name="telephone"
                  id="telephone"
                  inputMode="tel"
                  pattern="[0-9]*"
                />
              </SignupSteps>

              {formik.values.type === "PF" && (
                <SignupSteps title="Gênero" desc="Selecione seu gênero">
                  <StepInput
                    name="genre"
                    id="genre"
                    type="radio"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.genre}
                    backAction={() => carousel.slidePrev()}
                    buttonAction={() => carousel.slideNext()}
                    radioOptions={[
                      { value: "Masculino", label: "Masculino" },
                      { value: "Feminino", label: "Feminino" },
                    ]}
                  />
                </SignupSteps>
              )}

              <SignupSteps title="Insira seu CEP" desc="Somente números">
                <StepInput
                  backAction={() =>
                    carousel.slidePrev(
                      formik.values.type === "PJ" ? true : false
                    )
                  }
                  type="mask"
                  mask={cepMask}
                  id="cep"
                  inputMode="numeric"
                  onChange={handleCepChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.cep}
                  buttonAction={searchCep}
                  name="cep"
                />
              </SignupSteps>

              <SignupSteps
                title="Insira o número"
                desc="Insira o número do seu endereço"
              >
                <StepInput
                  backAction={() => carousel.slidePrev()}
                  buttonAction={() => carousel.slideNext()}
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.number}
                  name="number"
                  id="number"
                />
              </SignupSteps>

              <SignupSteps
                title="Complemento"
                desc="Insira o complemento do seu endereço"
              >
                <StepInput
                  backAction={() => carousel.slidePrev()}
                  type="text"
                  buttonAction={() => carousel.slideNext()}
                  isOptional
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.complement}
                  name="complement"
                  id="complement"
                />
              </SignupSteps>

              <SignupSteps
                width="98%"
                title="Endereço"
                desc="Verifique suas informações de endereço"
              >
                <Grid container>
                  <Grid item md={6}>
                    <InputMask
                      label="CEP"
                      width="98%"
                      inputMode="numeric"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.cep}
                      type="text"
                      mask={cepMask}
                      name="cep"
                      id="cepConfirmation"
                    />
                  </Grid>
                  <Grid item md={6}>
                    <Input
                      label="Estado"
                      width="98%"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.state}
                      type="text"
                      name="state"
                      id="stateConfirmation"
                    />
                  </Grid>
                  <Grid item md={12}>
                    <Input
                      label="Logradouro"
                      width="98%"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.publicplace}
                      type="text"
                      name="publicplace"
                      id="publicplace"
                    />
                  </Grid>
                  <Grid item md={6}>
                    <Input
                      label="Número"
                      width="98%"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.number}
                      type="text"
                      id="numberConfirmation"
                      name="number"
                    />
                  </Grid>
                  <Grid item md={6}>
                    <Input
                      label="Complemento"
                      width="98%"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.complement}
                      type="text"
                      id="complementConfirmation"
                      name="complement"
                    />
                  </Grid>
                  <Grid item md={6}>
                    <Input
                      label="Bairro"
                      width="98%"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.neighborhood}
                      type="text"
                      name="neighborhood"
                      id="neighborhood"
                    />
                  </Grid>
                  <Grid item md={6}>
                    <Input
                      label="Cidade"
                      width="98%"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.city}
                      type="text"
                      name="city"
                      id="city"
                    />
                  </Grid>
                  {addressError && (
                    <HelperText
                      style={{
                        textAlign: "center",
                        width: "100%",
                        paddingTop: "14px",
                      }}
                    >
                      {addressError}
                    </HelperText>
                  )}
                  <Hidden mdUp>
                    <Button
                      type="button"
                      onClick={() =>
                        validateAddress({
                          city: formik.values.city,
                          neighborhood: formik.values.neighborhood,
                          state: formik.values.state,
                          publicplace: formik.values.publicplace,
                          cep: formik.values.cep,
                          number: formik.values.number,
                        })
                      }
                    >
                      Continuar
                    </Button>
                  </Hidden>
                  <Hidden smDown>
                    <DesktopButtons>
                      <DesktopButton
                        back
                        type="button"
                        onClick={() => carousel.slidePrev()}
                      >
                        Voltar
                      </DesktopButton>
                      <DesktopButton
                        next
                        type="button"
                        onClick={() =>
                          validateAddress({
                            city: formik.values.city,
                            neighborhood: formik.values.neighborhood,
                            state: formik.values.state,
                            publicplace: formik.values.publicplace,
                            cep: formik.values.cep,
                            number: formik.values.number,
                          })
                        }
                      >
                        Avançar
                      </DesktopButton>
                    </DesktopButtons>
                  </Hidden>
                </Grid>
              </SignupSteps>

              <SignupSteps
                title="Foto de perfil"
                className="file-input"
                desc="Insira sua foto de perfil"
              >
                <StepInput
                  backAction={() => carousel.slidePrev()}
                  type="img"
                  buttonAction={() => carousel.slideNext()}
                  onChange={handleFileChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.profile_pic}
                ></StepInput>
              </SignupSteps>

              <SignupSteps
                title="Insira sua senha"
                desc="Deve conter ao menos uma letra maiúscula, 
                  uma letra minúscula, um número e um caractere especial"
              >
                <StepInput
                  backAction={() => carousel.slidePrev()}
                  type="password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                  name="password"
                  id="password"
                  verification={validatePassword}
                  showPattern
                  verificationText={() =>
                    formik.values.password ===
                    formik.values.passwordConfirmation
                  }
                  buttonText="Finalizar"
                  buttonAction={() => {}}
                  submit
                />
              </SignupSteps>

              <SignupSteps title="Finalizado" titleColor={Colors.blue}>
                <FinishContainer>
                  <img src={FinishIcon} alt="finish" />
                  <h3>Verifique seu e-mail</h3>
                  <p>Verifique seu email para a ativação da conta</p>
                </FinishContainer>
              </SignupSteps>
            </ConditionalCarousel>
          </Grid>
          <Hidden smDown>
            <Grid
              item
              md={6}
              style={{
                background: `url(${BgImage})`,
                backgroundSize: "cover",
                backgroundPosition: "right",
                height: "100vh",
              }}
            ></Grid>
          </Hidden>
        </Grid>
      </Form>
    </>
  );
}

const validationSchema = yup.object().shape({
  type: yup.string().required(),
  cpfcnpj: yup.string().required(),
  name: yup.string().required(),
  email: yup.string().email().required(),
  birthDate: yup
    .date()
    // .format("DD/MM/YYYY", true)
    .required(),
  telephone: yup
    .string()
    .matches(/^(?:\+)[0-9]{2}\s?[0-9]{2}\s?[0-9]{9}$/, "Telefone inválido")
    .required(),
  genre: yup.string().required(),
  cep: yup.string().required(),
  number: yup.string().required(),
  complement: yup.string(),
  profile_pic: yup.mixed().required(),
  password: yup
    .string()
    .matches(
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[.,#?!@$%^&*-]).{8,}$/,
      "Deve conter ao menos uma letra maiúscula, uma letra minúscula, um número e um caractere especial"
    )
    .required(),
  passwordConfirmation: yup.string().required(),
  neighborhood: yup.string().required(),
  city: yup.string().required(),
  state: yup.string().required(),
});
