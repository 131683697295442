import React, { useState } from 'react'
import { PaymentCard, CardGrid } from './style'
import { Grid } from '@material-ui/core'
import PaymentTypeModal from '../paymentTypeModal'

export default function ClientPaymentInfoCard({payment_method, holder, last_digits}) {
  const [openModal, setOpenModal] = useState(false)

  return (
    <>
    <PaymentCard elevation={0}>
      <h4>Informações de pagamento</h4>
      <CardGrid container>
          <Grid className='first-info container' item md={3} xs={6}>
            <div>
              <h6>Forma de pagamento</h6>
              <p>{payment_method === "boleto" ? "Boleto" : "Cartão de crédito"}</p>
            </div>
          </Grid>
          <Grid className='container' item md={3} xs={6}>
            { last_digits &&
              <div>
                <h6>Cartão</h6>
                <p>Terminado em {last_digits}</p>
              </div>
            }
          </Grid>
          <Grid className='container' item md={3} xs={6}>
            {
              holder &&
              <div>
                <h6>Titular do cartão</h6>
                <p>{holder}</p>
            </div>
            }
          </Grid>
          <Grid item md={3} xs={6}>
            <button onClick={()=>setOpenModal(true)} type="button">Alterar</button>
          </Grid>
      </CardGrid>
    </PaymentCard>
    <PaymentTypeModal actual_payment_method={payment_method} handleClose={()=>setOpenModal(false)} open={openModal}/>
    </>
  )
}
