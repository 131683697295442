import React, { useEffect, useState } from "react";
import Main from "components/main";

import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  TableFooter,
  TablePagination,
} from "@material-ui/core";

import { Card, CardHeader, CardNumber } from "components/card";
import Search from "components/search";
import { Options, QueueRow } from "./style";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
// import { useHistory } from 'react-router-dom';
import {
  formatDateTime,
  dateTimeDifference,
  formatCpfCnpj,
  formatPhone,
  formatDate,
} from "../../helper";
import api from "services/api";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { formatNameForSearch } from "../../utils/input-validators";

export default function Queue() {
  const [queue, setQueue] = useState([]);
  const [data, setData] = useState([]);
  const [queueDetails, setQueueDetails] = useState(null);
  const [queueInfo, setQueueInfo] = useState(null);
  //PAGINAÇÃO
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const history = useHistory();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  //FIM PAGINAÇÃO
  useEffect(() => {
    async function getQueue() {
      try {
        const response = await api.get("/queue/");
        setQueue(response.data);
        setData(response.data);
      } catch (err) {
        toast.warning("Verificar integração com Vida Connect");
      }
    }
    getQueue();

    async function getQueueDetails() {
      const details = await api.get("medical-appointment/details");
      setQueueDetails(details.data);
    }
    getQueueDetails();

    async function getQueueInfo() {
      const details = await api.get("/queue/info");
      setQueueInfo(details.data);
    }
    getQueueInfo();

    const queueInterval = setInterval(() => {
      getQueue();
    }, 60000);
    const queueDetailsInterval = setInterval(() => {
      getQueueDetails();
    }, 60000);
    const queueInfoInterval = setInterval(() => {
      getQueueInfo();
    }, 60000);

    return () => {
      clearInterval(queueInterval);
      clearInterval(queueDetailsInterval);
      clearInterval(queueInfoInterval);
    };
  }, []);

  const handleSearch = (e) => {
    const { value } = e.target;
    const doc = value.replace(/[^\d]+/g, ""); // Remove special caracteres

    let search =
      doc &&
      queue.filter(
        (obj) =>
          obj.patient.user.cpf === doc || obj.patient.user.cpf.startsWith(doc)
      );

    if (search.length === 0)
      search = queue.filter((obj) =>
        formatNameForSearch(obj.patient.user.name).includes(
          formatNameForSearch(value)
        )
      );

    setData(search);
  };

  return (
    <Main maxWidth="xl">
      <Grid container className="mt-3">
        <Card>
          <CardHeader label="Pacientes na fila" />
          <CardNumber color="darkGrey"> {queue.length} </CardNumber>
        </Card>
        <Card>
          <CardHeader label="TMA" />
          <CardNumber color="blue">
            {" "}
            {queueDetails
              ? `${Math.round(queueDetails.duration_time_avg)} min`
              : 0}{" "}
          </CardNumber>
        </Card>
        <Card>
          <CardHeader label="TME" />
          <CardNumber color="green">
            {" "}
            {queueDetails ? `${Math.round(queueInfo?.tme)} min` : 0}{" "}
          </CardNumber>
        </Card>
      </Grid>
      <Options container>
        <Search onChange={handleSearch} label="Nome do paciente ou CPF" />
      </Options>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center" width="200">
                Nome do paciente
              </TableCell>
              <TableCell align="center" width="200">
                CPF
              </TableCell>
              <TableCell align="center" width="200">
                E-mail
              </TableCell>
              <TableCell align="center" width="200">
                Telefone
              </TableCell>
              <TableCell align="center" width="200">
                Data
              </TableCell>
              <TableCell align="center" width="200">
                Horário de entrada
              </TableCell>
              <TableCell align="center" width="200">
                Tempo de espera
              </TableCell>
              <TableCell align="center" width="50">
                Detalhes
              </TableCell>
              <TableCell align="center" width="50"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((queue) => (
                <QueueRow key={queue.id} preference={queue.preference}>
                  <TableCell align="center">
                    {queue.patient.user.name}
                  </TableCell>
                  <TableCell align="center">
                    {formatCpfCnpj(queue.patient.user.cpf)}
                  </TableCell>
                  <TableCell align="center">
                    {queue.patient.user.email}
                  </TableCell>
                  <TableCell align="center">
                    {formatPhone(queue.patient.user.phone)}
                  </TableCell>
                  <TableCell align="center">
                    {formatDate(queue.createdAt)}
                  </TableCell>
                  <TableCell align="center">
                    {formatDateTime(queue.createdAt)}
                  </TableCell>
                  <TableCell align="center">
                    {dateTimeDifference(queue.createdAt)}
                  </TableCell>
                  <TableCell align="center">
                    <Button
                      variant="outlined"
                      onClick={() =>
                        history.push(`/triagem/${queue.triageId}`, {
                          queueId: queue.id,
                        })
                      }
                    >
                      Triagem
                    </Button>
                  </TableCell>
                  <TableCell align="center">
                    {queue.preference && (
                      <ErrorOutlineIcon
                        style={{ color: "#DA1414", fontSize: "14px" }}
                      />
                    )}
                  </TableCell>
                </QueueRow>
              ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                colSpan={7}
                count={queue.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { "aria-label": "Itens por página" },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Main>
  );
}
