import styled from 'styled-components';
import { Colors } from 'globalStyle';

export const Container = styled.div`
  position: fixed;
  z-index: 1300;
  right: 0px;
  bottom: 0px;
  top: 0px;
  left: 0px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #00000047;
  display: ${(props) => (props.show ? 'flex' : 'none')};
`;

export const ModalForm = styled.form`
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 10px;
`;

export const Body = styled.div`
  align-content: center;
  max-height: 70vh;
  overflow: auto;
  min-width: 40vw;
  min-height: 60px;
  display: flex;
  flex-direction: column;
  padding: 1em;
  span {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    color: #272833; 
    margin-top: 22px; 
    margin-bottom: 19px; 
  }

  @media(max-width: 960px) {
    min-width: 100vw;
  }
`;

export const Header = styled.div`
  font-family: 'Montserrat';
  border-bottom: 1px solid #e7e7ed;
  height: 68px;
  padding-left: 24px;
  display: flex;
  align-items: center;

  h2 {
    font-size: 18px;
    font-weight: 600;
    color: #272833;
  }
  button {
    margin-left: auto;
    margin-right: 20px;
    background-color: transparent;
    border: none;
  }
`;

export const Footer = styled.div`
  background-color: ${Colors.bgGrey};
  border-radius: 0 0 10px 10px;
  padding: 22px 1em 21px 0px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  height: ${(props) => (props.height ? props.height : 'auto')};
  button {
    margin-right: 1em;
  }
`;
