import styled from "styled-components";
import { Colors } from "globalStyle";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 35px;
`;

export const Row = styled.div`
  display: flex;
  gap: 15px;
`;

export const AutoCompleteWrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
`;

export const AutoCompleteOptions = styled.div`
    position: absolute;
    background-color: #d1d1d1;
    left: 10px;
    top: 69px;
    width: 360px;
    padding: 10px 20px;
    cursor: pointer;
`;

export const Button = styled.button`
  height: 45px;
  margin: 25px 0 0 10px;
  padding: 0 15px;
  color: white;
  font-size: 16px;
  font-weight: 600;
  outline: none;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  background-color: ${Colors.blue};
`;
