import React, { useEffect, useState } from 'react';

import Main from 'components/main';
import { Card, Grid } from '@material-ui/core';
import ClientPlanCard from 'components/client/plan/card';
import api from 'services/api';
import ClientPaymentInfoCard from 'components/client/payment/infoCard';
import ClientBeneficiaryCard from 'components/client/payment/beneficiaryCard';
import PaymentHistoryTable from 'components/client/payment/paymentHistoryTable';
import PlanDetailsModal from 'components/client/plan/planDetailsModal';
import AvaliablePlansModal from 'components/client/plan/avaliablePlansModal';

export default function Payments() {

  const [userPlan, setUserPlan] = useState({})
  const [beneficiaries, setBeneficiaries] = useState([])
  const [paymentInfo, setPaymentInfo] = useState({})
  const [plans, setUserPlans] = useState([])
  const [open, setOpen] = useState({details: false, avaliable: false})
  const [payments, setPayments] = useState([])
  const [clientData, setClientData] = useState(null)

  useEffect(()=> {
    const getUserPlan = async () => {
      try {
        const response = await api.get('/user-plan')
        setUserPlan(response.data)

        const client = await api.get('/user/clients/self')
        setClientData(client.data)
        setPayments(client.data.payments ? client.data.payments : [])

        if(!response.data) {
          setUserPlan(client.data.plan ? client.data.plan : {})
        } else {
          setUserPlan(response.data)
        }

        const getBeneficiaries = await api.get('beneficiary/owner/')
        setBeneficiaries(getBeneficiaries.data)

        const getPaymentInfo = await api.get('payment-gateway/user-plan/'+client.data.plan.gateway_signature_id)
        setPaymentInfo(getPaymentInfo.data)

        let responsePlan = null
        if(client.data.type==="PJ") {
          responsePlan = await api.get('/dynamic-plan')
        } else {
          responsePlan = await api.get('/plan')
        }
        setUserPlans(responsePlan.data.filter(
          val=>{
            const today = new Date()
            const start = new Date(val.start_date)
            const end = new Date(val.expired_date)

            return val._id!==response.data.plan_id &&
            start <= today && today <= end &&
            val.status === "ACTIVE"
          }
        ))
      } catch (error) {
        console.log(error)
      }
    }
    getUserPlan()
  }, [])

  const getBeneficiariesLength = (beneficiaryList = []) => {
    const holders = beneficiaryList.length;
    const dependents = beneficiaryList
      .reduce((a,b) => a + (b.dependents ? b.dependents.length : 0), 0)

    return holders + dependents
  }
  
  return (
    <>
    <Main maxWidth="xl">
      <Grid container spacing={2}>
        <Grid item md={12}>
          <Card xl style={{paddingLeft:"40px", paddingRight:"40px", marginTop:"12px"}}>
            <h4>Informações de pagamento</h4>
            {userPlan._id &&
              <ClientPlanCard
                name={userPlan.name}
                hiredAt={userPlan.createdAt}
                validity={userPlan.expiredAt}
                recurrence={userPlan.period}
                mensality={parseFloat(userPlan.value * (userPlan.number_users || 0)).toFixed(2).replace('.',',')}
                openCompareModal={()=>setOpen({details: true, avaliable: false})}
                nextPaymentDate={paymentInfo.current_period_end}
                expirationDateForCancellationCase={clientData?.expirationDateForCancellationCase}
              />
            }
            {!userPlan._id &&
              <h3 style={{textAlign:"center"}}>Contrate um plano para ver as informações aqui</h3>
            }
          </Card>
        </Grid>
        <Grid item md={8}>
          { userPlan._id &&
            <ClientPaymentInfoCard
              holder={paymentInfo.holder}
              last_digits={paymentInfo.last_digits}
              payment_method={paymentInfo.payment_method}
            />
          }
        </Grid>
        <Grid item md={4}>
          { userPlan._id &&
            <ClientBeneficiaryCard
              registered={getBeneficiariesLength(beneficiaries)}
              avaliable={userPlan.number_users - getBeneficiariesLength(beneficiaries)}
            /> 
          }
        </Grid>
        <Grid item xs={12}>
          <h4>Histórico de pagamentos</h4>
          <PaymentHistoryTable data={payments}/>
        </Grid>
      </Grid>
    </Main>
    <PlanDetailsModal
      open={open.details}
      handleClose={()=>setOpen({...open, details:false})}
      name={userPlan.name}
      mensality={userPlan.value ? userPlan.value : 0}
      period={userPlan.period}
      plan={userPlan.plan_id ? userPlan.plan_id : userPlan.dynamic_plan_id}
      beneficiaries={userPlan.number_users}
      onSubmit={(e)=>{
        e.preventDefault()
        setOpen({details: false, avaliable: true})
      }}
    />
    <AvaliablePlansModal
      open={open.avaliable}
      handleClose={()=>setOpen({...open, avaliable:false})}
      name={userPlan.name}
      plans={[...plans]}
    />
    </>
  );
}
