import styled from 'styled-components' ;
import { Grid } from '@material-ui/core'; 

export const Options = styled(Grid)`
  margin-top: 2em;
  margin-bottom: 2em;
  justify-content: space-between;
  align-content: center;
  align-items:center; 
  form { 
    display: flex; 
    flex-direction: row; 
    align-items:center; 
    align-content: center;
    background-color: none; 
  }
  form .MuiFilledInput-input { 
    padding: 0; 
    margin-right: 24px;
  }
  form .MuiFilledInput-root { 
    background-color: transparent; 
  }
  label { 
    margin-right: 10px; 
  }
  button {
    text-transform: none;
    font-size: 16px;
  }
  input { 
    background-color: white; 
    height: 32px;

  }
`;
