import React, { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Grid, Stepper, Step, StepLabel, Button, Box } from "@material-ui/core";
import jwt_decode from "jwt-decode";

import {
  Input,
  ImgInput,
  InputGroup,
  InputGroupHeader,
  InputGroupBody,
  Form,
  InputMask,
} from "components/inputs";
import { cnpjMask, phoneMask } from "../../../utils/masks";
import Header from "components/header";
import { FixedCarousel } from "components/fixedCarousel";
import { Containers, Contract, TermsBox } from "./style";

import api from "services/api";
import { formatCpfCnpj } from "helper";

import { useDispatch } from "react-redux";

import PublishIcon from "@material-ui/icons/Publish";
import { toast } from "react-toastify";

export default function DoctorCreate() {
  const { hash } = useParams();
  const slider1 = useRef();
  const history = useHistory();
  const [sliderState, setSliderState] = useState({
    nav1: null,
    activeSlide: 0,
  });
  const [term, setTerm] = useState({});
  const [docs, setDocs] = useState([]);
  const [user, setUser] = useState({
    name: "",
    email: "",
    cpfcnpj: "",
    telephone: "",
    speciality: "",
    crm: "",
    cnpj: "",
    account: "",
    bank: "",
    ag: "",
  });

  try {
    jwt_decode(hash);
  } catch (err) {
    alert("error");
    window.location.href = "/login";
  }
  const { _id } = jwt_decode(hash);
  const dispatch = useDispatch();
  dispatch({ type: "@theme/BG_WHITE" });
  useEffect(() => {
    async function getUser() {
      const response = await api.get(`/user/${_id}`);
      if (response.status !== 200) {
        alert("usuário nao cadastrado");
        window.location.href = "/";
      }
      if (response.data.status !== "PRE_REGISTER") {
        alert("usuário já cadastrado");
        window.location.href = "/";
      }
      const id = response.data._id;
      delete response.data._id;
      setUser({ userId: id, profile: "MEDICO", ...response.data });
    }
    getUser();

    const getTerm = async () => {
      const term = await api.get("terms?type=SERVICE");
      setTerm(term.data);
    };

    getTerm();
  }, [_id]);

  const handleChange = (event) => {
    setUser({ ...user, [event.target.name]: event.target.value });
  };

  const handleDocs = (event) => {
    setDocs({ ...docs, [event.target.name]: event.target.files[0] });
  };

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();

      const formUser = Object.assign({}, user);
      formUser.telephone = formUser.telephone.replace(/\D+/g, "");
      formUser.cnpj = formUser.cnpj.replace(/\D+/g, "");

      const formData = new FormData();
      Object.keys(user).map((key) => formData.append(key, formUser[key]));
      Object.keys(docs).map((key) => formData.append(key, docs[key]));

      const response = await api.post("/doctor", formData);

      if (response.status === 201) {
        toast.success("Médico cadastrado com sucesso");
        api.post("/log", {
          userId: _id,
          action: "TERMS_ACCEPT",
          payload: {
            term: {
              url: "https://storage.googleapis.com/" + term.term,
              type: term.type,
            },
          },
        });
        history.push("/medicos");
      } else {
        slider1.current.slickGoTo(0);
        toast.error(
          "Verifique se todos os campos foram preenchidos corretamente"
        );
      }
    } catch (err) {
      slider1.current.slickGoTo(0);
      toast.error(
        "Verifique se todos os campos foram preenchidos corretamente"
      );
    }
  };

  const nextSlider = () => {
    slider1.current.slickNext();
    dispatch({ type: "@theme/BG_GREY" });
  };

  const previousSlider = () => {
    slider1.current.slickPrev();
    dispatch({ type: "@theme/BG_WHITE" });
  };
  return (
    <>
      <Header onlyLogo />
      <Grid
        style={{ background: "#F5F5F5" }}
        alignItems="center"
        container
        justifyContent="space-between"
        direction="row"
      >
        <Grid item xs={2}></Grid>
        <Grid item xs={3}>
          <Stepper
            style={{ background: "#F5F5F5" }}
            activeStep={sliderState.activeSlide}
          >
            <Step key="1">
              <StepLabel></StepLabel>
            </Step>
            <Step key="2">
              <StepLabel></StepLabel>
            </Step>
          </Stepper>
        </Grid>
        <Grid item xs={2}>
          {sliderState.activeSlide === 0 && (
            <Button onClick={nextSlider} variant="outlined">
              Avançar
            </Button>
          )}
        </Grid>
      </Grid>
      <Containers maxWidth="xl">
        <Form width="100%" action="/doctor" onSubmit={handleSubmit}>
          <FixedCarousel
            ref={(slider) => (slider1.current = slider)}
            sliderState={sliderState}
            setSliderState={setSliderState}
          >
            <Grid container direction="row">
              <Grid item xs={8}>
                <InputGroup>
                  <InputGroupHeader>
                    <h2>Foto de perfil</h2>
                    <span> Adicione sua foto de perfil </span>
                  </InputGroupHeader>
                  <ImgInput
                    className="file-container"
                    onChange={handleDocs}
                    name="profile_pic"
                  />
                </InputGroup>
                <InputGroup>
                  <InputGroupHeader>
                    <h2>Dados Pessoais</h2>
                  </InputGroupHeader>
                  <Input
                    name="cpf"
                    placeholder="CPF"
                    label="CPF"
                    inputMode="numeric"
                    width="190px"
                    value={
                      user && user.cpfcnpj ? formatCpfCnpj(user.cpfcnpj) : ""
                    }
                    disabled
                  />
                  <Input
                    name="name"
                    placeholder="Nome"
                    label="Nome"
                    width="550px"
                    value={user && user.name ? user.name : ""}
                    disabled
                  />
                  <Input
                    name="email"
                    inputMode="email"
                    placeholder="Email"
                    label="Email"
                    value={user && user.email ? user.email : ""}
                    disabled
                  />
                  <InputMask
                    placeholder="Telefone"
                    label="Telefone"
                    name="telephone"
                    inputMode="tel"
                    width="170px"
                    onChange={handleChange}
                    mask={phoneMask}
                  />
                </InputGroup>
                <InputGroup>
                  <InputGroupHeader>
                    <h2>Profissão</h2>
                  </InputGroupHeader>
                  <Input
                    name="speciality"
                    placeholder="Profissão"
                    label="Profissão"
                    onChange={handleChange}
                  />
                  <InputGroupBody direction="row">
                    <Input
                      name="crm"
                      placeholder="CRM"
                      label="CRM"
                      width="108px"
                      onChange={handleChange}
                    />
                    <Input
                      name="crm_state"
                      placeholder="SP"
                      label="UF"
                      width="60px"
                      left={10}
                      max-length="2"
                      onChange={handleChange}
                    />
                  </InputGroupBody>
                </InputGroup>
                {/* <InputGroup>
                  <InputGroupHeader>
                    <h2>Dados Bancários</h2>
                    <span> Informe os seus dados bancários para pagamento dos serviços prestados.</span>
                  </InputGroupHeader>
                  <InputGroupBody direction="row">
                    <Input name="bank" placeholder='Banco' label='Banco'  onChange={handleChange} />
                    <Input name="ag" left={10} placeholder='Agência' label='Agência' width="117px" onChange={handleChange}/>
                    <Input name="account" left={10} placeholder='Conta' label='Conta' width="117px" onChange={handleChange}/>
                  </InputGroupBody>
                </InputGroup> */}
                <InputGroup>
                  <InputGroupHeader>
                    <h2>Vínculo contratual</h2>
                  </InputGroupHeader>
                  <InputGroupBody>
                    <InputMask
                      name="cnpj"
                      placeholder="CNPJ"
                      label="CNPJ"
                      onChange={handleChange}
                      mask={cnpjMask}
                    />
                    <Input
                      name="social"
                      placeholder="Razão social"
                      label="Razão social"
                      onChange={handleChange}
                    />
                  </InputGroupBody>
                </InputGroup>
                <InputGroup>
                  <InputGroupHeader>
                    <h2>Anexos</h2>
                  </InputGroupHeader>
                  <InputGroupBody>
                    <Input
                      name="identity_attachment"
                      type="file"
                      placeholder="doc.pdf"
                      label="1. Identidade profissional (Frente e verso)"
                      onChange={handleDocs}
                    />
                    <Input
                      name="ficha_attachment"
                      type="file"
                      placeholder="doc.pdf"
                      label="2. Ficha cadastral"
                      onChange={handleDocs}
                    />
                    <Input
                      name="crm_attachment"
                      type="file"
                      placeholder="doc.pdf"
                      label="3. CRM"
                      onChange={handleDocs}
                    />
                  </InputGroupBody>
                </InputGroup>
                <Box marginTop="1em" display="flex">
                <Button color="primary" variant="contained" onClick={nextSlider}>
                  Avançar
                </Button>
              </Box>
              </Grid>
            </Grid>
            <Grid container direction="row">
              <Grid item xs={12}>
                <TermsBox>
                  <h3>Termos de uso e Política de Privacidade - Virtua Care</h3>
                  <Box overflow="visible" padding="16px 0">
                    <iframe
                      title="terms"
                      style={{ width: "100%", height: "400px" }}
                      src={
                        term.term
                          ? "https://storage.googleapis.com/" + term.term
                          : ""
                      }
                    />
                  </Box>
                </TermsBox>
              </Grid>
              <Grid item xs={12}>
                <Contract>
                  <h4>Contrato</h4>
                  <label htmlFor="contract">
                    {docs.contract_attachment
                      ? "Contrato selecionado"
                      : "Selecionar Arquivo"}
                    {!docs.contract_attachment && <PublishIcon />}
                  </label>
                  <input
                    id="contract"
                    name="contract_attachment"
                    type="file"
                    onChange={handleDocs}
                  />
                </Contract>
              </Grid>
              <Box marginTop="1em" display="flex" justifyContent="flex-end">
                <Button
                  style={{ marginRight: "30px" }}
                  onClick={previousSlider}
                  variant="outlined"
                >
                  Voltar
                </Button>
                <Button color="primary" type="submit" variant="outlined">
                  Aceitar
                </Button>
              </Box>
            </Grid>
          </FixedCarousel>
        </Form>
      </Containers>
      <footer />
    </>
  );
}
