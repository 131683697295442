import styled from 'styled-components'
import Modal from '../../../modal';

export const PlanModal = styled(Modal)`
  h6 {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 150%;
    color: #6B6C7E;
  }
  min-width: 50vw;
`

export const PlanOverview = styled.div`
  background: #F7F8F9;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 140px;
  padding: 16px;
  margin-bottom: 32px;

  p, h6 {
    margin: 0;
    padding: 0;
  }

  h6 {
    font-weight: normal;
    font-size: 12px;
    line-height: 150%;
    color: #6B6C7E;
  }

  p {
    font-weight: 600;
    font-size: 24px;
    line-height: 150%;
    color: #2E5AAC;
  }

  div {
    width: 40%;
    min-height: 108px;
    background:white;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
`

export const PlanDetails = styled.div`
  background: #F7F8F9;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 24px;

  p, h6, span {
    margin: 0;
    padding: 0;
  }

  div {
    display: flex;
    justify-content: space-between;
    padding: 18px 40px;

    p {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 150%;
    }

    span {
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 150%;
      color: #2E5AAC;
    }
  }
`