import Modal from 'components/modal'
import { Colors } from 'globalStyle'
import styled from 'styled-components'

export const PlanModal = styled(Modal)`
  min-width: 50vw;
`

export const PlanCard = styled.div`
  height: 92px !important;
  min-height: 92px;
  width: 100%;
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #F1F2F5;
  min-width: 682px;
  overflow-x: auto;
  overflow-y: hidden;
`

export const PlanCardItem = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-left: 24px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  label, span {
    margin: 0;
    padding: 0;
  }

  label {
    font-weight: normal;
    font-size: 12px;
    line-height: 150%;
    color: #6B6C7E;
    margin-bottom: 4px;
  }
  
  span {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 150%;
    color: ${props=>props.color ? props.color : Colors.darkGrey}
  }
`