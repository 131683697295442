import styled from "styled-components";

export const Containers = styled.div`
  position: absolute;
  top: 159px;
  padding: 0 60px;
  display: flex !important;
  flex-direction: row !important;
  width: 100%;
`;

export const DataHeader = styled.div`
  h2 {
    margin: 0;
    padding: 0;
    font-size: 20px;
  }
`;

export const FormBody = styled.div`
  margin-top: 1em;
  margin-bottom: 2em;
`;
