import styled from "styled-components";
import { Container } from "@material-ui/core";

export const Navbar = styled.div`
  width: 100%;
  height: 62px;

  display: flex;
  align-items: center;

  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;

  background-color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
  nav {
    width: 100%;
  }
`;

export const HeaderContainer = styled(Container)`
  @media (max-width: 960px) {
    div {
      justify-content: space-between;
    }
  }
`;

export const Content = styled.div`
  display: flex;
`;

export const Logo = styled.div`
  a {
    text-decoration: none;
  }
  h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #3089c3;
  }
`;

export const NavItem = styled.div`
  display: flex;
  align-items: center;
  padding-left: 3em;
  a {
    text-decoration: none;
    color: #6b6c7e;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
  }
  a:hover {
  }
  .is-active {
    color: #3089c3;
  }
`;

export const MobileMenu = styled.div`
  width: 100vw;
  height: 100%;

  display: flex;
  align-items: center;
  flex-direction: column;

  position: absolute;
  top: 0;
  left: ${(props) => (!props.show ? "-100vw" : "0")};
  z-index: 9999;
  transition: 0.2s;
  overflow-y: auto;

  padding: 0;
  /* height: calc(100% - 63px); */
  background-color: white;

  div {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 5% 0;
    font-size: 20px;
    line-height: 150%;
  }

  span {
    margin-bottom: 50px;
    cursor: pointer;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    text-align: center;
    color: #da1414;
  }

  @media (min-width: 960px) {
    opacity: 0;
  }
`;
