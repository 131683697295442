const defaultPlanValidator = () => {
  return {
    name: {
      error: false,
      helperText: 'Campo Obrigatório',
    },
    number_users: {
      error: false,
      helperText: 'Campo Obrigatório',
    },
    min_users: {
      error: false,
      helperText: 'Campo Obrigatório',
    },
    max_users: {
      error: false,
      helperText: 'Campo Obrigatório',
    },
    per_user: {
      error: false,
      helperText: 'Campo Obrigatório',
    },
    mensality: {
      error: false,
      helperText: 'Campo Obrigatório',
    },
    anuality: {
      error: false,
      helperText: 'Campo Obrigatório',
    },
    semestral: {
      error: false,
      helperText: 'Campo Obrigatório',
    },
    trimestral: {
      error: false,
      helperText: 'Campo Obrigatório',
    },
    start_date: {
      error: false,
      helperText: 'Campo Obrigatório',
    },
    expired_date: {
      error: false,
      helperText: 'Campo Obrigatório',
    },
  };
};
export default defaultPlanValidator;

export const inputValidations = (form) => {
  const error = defaultPlanValidator();
  let haveError = false;

  if (!form.name || form.name === '') {
    error.name = { error: true, helperText: 'Campo Obrigatório' };
    haveError = true;
  }

  if ((Number(form.number_users) === 0 || form.number_users === '') && form.type==="PF") {
    error.number_users = {
      error: true,
      helperText: 'O plano precisa ter ao menos 1 beneficiário',
    };
    haveError = true;
  }

  if ((Number(form.min_users) <= 0 || form.min_users === '') && form.type==="PJ") {
    error.min_users = {
      error: true,
      helperText: 'O plano precisa ter ao menos 1 beneficiário mínimo',
    };
    haveError = true;
  }

  if (form.mensality === '' || !form.mensality) {
    error.mensality = {
      error: true,
      helperText: 'Coloque um valor, se não houver desconto coloque 0',
    };
    haveError = true;
  }

  if (form.mensality === '') {
    error.mensality = {
      error: true,
      helperText: 'O valor da mensalidade precisa ser maior que 0',
    };
    haveError = true;
  }


  if (form.anuality === '' || !form.anuality) {
    error.anuality = {
      error: true,
      helperText: 'Coloque um valor, se não houver desconto coloque 0',
    };
    haveError = true;
  }

  if (form.semestral === '' || !form.semestral) {
    error.semestral = {
      error: true,
      helperText: 'Coloque um valor, se não houver desconto coloque 0',
    };
    haveError = true;
  }


  if (form.trimestral === '' || !form.trimestral) {
    error.trimestral = {
      error: true,
      helperText: 'Coloque um valor, se não houver desconto coloque 0',
    };
    haveError = true;
  }


  if (!form.start_date || form.start_date === '' || form.start_date === ' ') {
    error.start_date = { error: true, helperText: 'Campo obrigatório' };
    haveError = true;
  }
  if (!form.expired_date || form.expired_date === '' || form.expired_date === ' ' ) {
    error.expired_date = { error: true, helperText: 'Campo obrigatório' };
    haveError = true;
  }

  if (form.expired_date === form.start_date || form.expired_date < form.start_date) { 
    error.expired_date = { error : true, helperText: "A data final tem que ser maior que a data inicial"}; 
    haveError = true; 
  }

  if (haveError) return error;

  return true;
};
