import produce from "immer";

const INITIAL_STATE = {
  access_token: null,
  bgWhite: false,
  rows_per_page: 10,
};

export default function auth(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case "@auth/LOGIN": {
        break;
      }
      case "@auth/LOGIN_SUCCESS": {
        draft.access_token = action.payload.token;
        break;
      }
      case "@theme/BG_WHITE": {
        draft.bgWhite = true;
        break;
      }
      case "@theme/BG_GREY": {
        draft.bgWhite = false;
        break;
      }
      case "@user/LOGOUT": {
        draft.access_token = null;
        document.location.href = "/login";
        break;
      }
      case "@pagination/ROWS_PER_PAGE": {
        draft.rows_per_page = action.payload.rows_per_page;
        break;
      }
      default:
        return state;
    }
  });
}
