import { Grid } from '@material-ui/core'
import styled from 'styled-components'

export const Form = styled.form`
  margin-top: 1em;
`

export const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

export const InputContainer = styled(Grid)`
  display: flex;
  flex-wrap: wrap;

  button {
    border-radius: 50%;
    height: 40px;
    width: 40px;
    align-self: flex-end;
  }

  input, select {
    margin-top: 0;
    max-width: 100%;
    width: 100%;
  }

  div.file-input{
    width: 64px;
    height: 64px;

    img{
      width: 64px;
      height: 64px;
      object-fit: cover;
    }

    label{
      margin: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
`

export const DependentTitle = styled.div`
  display: flex;
  align-items: center;
  button {
    margin-left: 1em;
  }
`

export const AddButtonContainer = styled.div`
  width: 100%;
  margin-top: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
`