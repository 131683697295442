import React from "react";
import { Container } from "@material-ui/core";
import { TriageDetail } from "./style";

export default function TriageContainer({
  symptoms,
  drugs,
  exams,
  allergies,
  diseases,
}) {
  function download(uri, name) {
    if (!window.ActiveXObject) {
      var save = document.createElement("a");
      save.href = uri;
      save.target = "_blank";
      save.download = name || "unknown";

      var evt = new MouseEvent("click", {
        view: window,
        bubbles: true,
        cancelable: false,
      });
      save.dispatchEvent(evt);

      (window.URL || window.webkitURL).revokeObjectURL(save.href);
    } else if (!!window.ActiveXObject && document.execCommand) {
      var _window = window.open(uri, "_blank");
      _window.document.close();
      _window.document.execCommand("SaveAs", true, name || uri);
      _window.close();
    }
  }

  return (
    <Container 
      maxWidth="xl" 
      style={{
        "position": "absolute",
        "top": "159px"
      }}
    >
      <h3
        style={{
          fontWeight: 500,
          fontSize: "20px",
          lineHeight: "24px",
        }}
      >
        Triagem
      </h3>

      <TriageDetail>
        <h5>Sintomas do paciente</h5>
        <div>
          {symptoms
            ?.split(",")
            .filter((val) => !!val)
            .map((symptom, index) => (
              <p key={index}>{symptom}</p>
            ))}
        </div>
      </TriageDetail>

      <TriageDetail>
        <h5>Medicamentos de uso contínuo</h5>
        <div>
          {drugs
            ?.split(",")
            .filter((val) => !!val)
            .map((drug, index) => (
              <p key={index}>{drug}</p>
            ))}
        </div>
      </TriageDetail>

      <TriageDetail>
        <h5>Alergia de medicamentos</h5>
        <div>
          {allergies
            ?.split(",")
            .filter((val) => !!val)
            .map((drug, index) => (
              <p key={index}>{drug}</p>
            ))}
        </div>
      </TriageDetail>

      <TriageDetail>
        <h5>Doenças crônicas</h5>
        <div>
          {diseases
            ?.split(",")
            .filter((val) => !!val)
            .map((drug, index) => (
              <p key={index}>{drug}</p>
            ))}
        </div>
      </TriageDetail>

      <TriageDetail exam>
        <h5>Resultado de exames, fotos de lesões (clique para baixar):</h5>
        <div>
          {exams?.map((exam, index) => (
            <p key={index} onClick={() => download(exam.uri, "attachment")}>
              Anexo {index + 1}
            </p>
          ))}
        </div>
      </TriageDetail>
    </Container>
  );
}
