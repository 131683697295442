import React from "react";
import { ColorizedStatus } from "./style";
import { statusAlias } from "helper";

export default function Status(props) {
  return (
    <div
      className="align-center"
      onClick={props.onClick}
      style={props.onClick && { cursor: "pointer" }}
    >
      <ColorizedStatus status={props.status}>
        <span>{statusAlias(props.status)}</span>
      </ColorizedStatus>
    </div>
  );
}
