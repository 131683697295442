import React, { useEffect, useState } from "react";
import Main from "components/main";
import { toast } from "react-toastify";

import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  TableFooter,
  TablePagination,
  FormControlLabel,
  withStyles,
  Checkbox,
} from "@material-ui/core";

import { Card, CardHeader, CardNumber } from "components/card";
import { Options } from "./style";

import Search from "components/search";
import Modal, { ModalBody, ModalFooter, ModalHeader } from "components/modal";
import { formatCpfCnpj, formatPhone } from "../../helper";
import { cpfMask } from "../../utils/masks";
import {
  cpfValidator,
  formatNameForSearch,
} from "../../utils/input-validators";

import { useHistory } from "react-router-dom";
import { Input, InputMask } from "components/inputs";
import api from "services/api";
import Status from "components/status";
import { green } from "@material-ui/core/colors";

export default function Doctors(id) {
  const [open, setOpen] = useState(false);
  const [doctors, setDoctors] = useState([]);
  const [data, setData] = useState([]);
  const [form, setForm] = useState({ cpfcnpj: "", email: "" });
  const [onlineDoctors, setOnlineDoctors] = useState(0);

  const GreenCheckbox = withStyles({
    root: {
      color: green[400],
      "&$checked": {
        color: green[600],
      },
    },
    checked: {},
  })((props) => <Checkbox color="default" {...props} />);

  //PAGINAÇÃO
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  //FIM PAGINAÇÃO

  useEffect(() => {
    async function getDoctors() {
      const response = await api.get("/doctor");
      const responseData = response.data.filter(
        (elem, index, self) =>
          self.findIndex((t) => {
            return t.user.cpfcnpj === elem.user.cpfcnpj;
          }) === index
      );
      setDoctors(responseData);
      setData(responseData);
    }
    async function getOnlineDoctors() {
      const response = await api.get("/doctor/online");
      setOnlineDoctors(response.data);
    }
    getDoctors();
    getOnlineDoctors();
  }, []);

  const history = useHistory();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const formChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSearch = (e) => {
    const { value } = e.target;
    const doc = value.replace(/[^\d]+/g, ""); // Remove special caracteres.

    let search =
      doc &&
      doctors.filter(
        (obj) => obj.user.cpfcnpj === doc || obj.user.cpfcnpj.startsWith(doc)
      );

    if (search.length === 0)
      search = doctors.filter((obj) =>
        obj.user.name
          ? formatNameForSearch(obj.user.name).includes(
              formatNameForSearch(value)
            )
          : false
      );

    setData(search);
    setPage(0);
  };

  const changeDoctorStatus = async (doctor) => {
    try {
      const user = await api.put(`/user/change-status/${doctor.user._id}`);
      toast.success("Status do médico alterado com sucesso");
      setData(
        [...data],
        (data.find((doctorData) => doctor._id === doctorData._id).user.status =
          user.data.status)
      );
    } catch (err) {
      toast.error(err.response.data.message);
    }
  };

  async function handleSubmit(event) {
    event.preventDefault();
    try {
      if (!cpfValidator(form.cpfcnpj)) return;
      const formatedCpf = form.cpfcnpj.replace(/\D+/g, "");
      const formatedForm = Object.assign({}, form, { cpfcnpj: formatedCpf });
      await api.post(
        event.target.action.split(window.location.origin)[1],
        formatedForm
      );
      toast.success(
        "Médico cadastrado com sucesso, email enviado para que o mesmo finalize o cadastro"
      );
      handleClose();
    } catch (err) {
      toast.error(err.response.data.message);
    }
  }
  return (
    <Main maxWidth="xl">
      <Grid container className="mt-3">
        <Card>
          <CardHeader label="Médicos cadastrados" />
          <CardNumber color="darkGrey"> {doctors.length} </CardNumber>
        </Card>
        <Card>
          <CardHeader label="Médicos ativos" />
          <CardNumber color="blue">
            {" "}
            {
              doctors.filter((doctor) => doctor.user.status === "ACTIVE").length
            }{" "}
          </CardNumber>
        </Card>
        <Card>
          <CardHeader label="Médicos online" />
          <CardNumber color="green"> {onlineDoctors} </CardNumber>
        </Card>
      </Grid>
      <Options container>
        <Search label="Médico ou CPF" onChange={handleSearch} />
        <Button variant="outlined" onClick={handleOpen}>
          Novo Médico
        </Button>
        <Modal show={open} onSubmit={handleSubmit} action="/doctor/pre">
          <ModalHeader close={handleClose}> Cadastrar Médicos </ModalHeader>
          <ModalBody>
            <Input
              required
              name="name"
              placeholder="Médico"
              label="Nome"
              width="100%"
              onChange={formChange}
              left="0"
            />
            <InputMask
              required
              name="cpfcnpj"
              value={form.cpfcnpj}
              mask={cpfMask}
              placeholder="CPF"
              label="CPF"
              inputMode="numeric"
              width="190px"
              onChange={formChange}
              onBlur={() => {}}
              validate={cpfValidator}
              validateText={"CPF inválido"}
            />
            <Input
              name="email"
              value={form.email.toLowerCase()}
              inputMode="email"
              required
              placeholder="Email"
              label="Email"
              width="100%"
              type="email"
              onChange={(e) => {
                e.target.value = e.target.value.toLowerCase();
                formChange(e);
              }}
              left="0"
            />
          </ModalBody>
          <ModalFooter cancel={handleClose} next="Enviar" />
        </Modal>
      </Options>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center" width="200">
                Nome do médico
              </TableCell>
              <TableCell align="center" width="200">
                CPF
              </TableCell>
              <TableCell align="center" width="200">
                CRM/UF
              </TableCell>
              <TableCell align="center" width="200">
                Telefone
              </TableCell>
              <TableCell align="center" width="200">
                Status
              </TableCell>
              <TableCell align="center" width="200">
                Opções
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((doctor) => (
                <TableRow key={doctor._id}>
                  <TableCell align="center">{doctor.user.name}</TableCell>
                  <TableCell align="center">
                    {formatCpfCnpj(doctor.user.cpfcnpj)}
                  </TableCell>
                  <TableCell align="center">
                    {doctor.crm}/{doctor.crm_state ? doctor.crm_state : "SP"}
                  </TableCell>
                  <TableCell align="center">
                    {formatPhone(doctor.user.telephone)}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Status status={doctor.user.status} />
                    <FormControlLabel
                      control={
                        <GreenCheckbox
                          checked={doctor.user.status === "ACTIVE"}
                          name="checkStatus"
                          onChange={() => changeDoctorStatus(doctor)}
                        />
                      }
                    />
                  </TableCell>
                  <TableCell align="center">
                    <Button
                      variant="outlined"
                      onClick={() => history.push(`/medicos/${doctor._id}`)}
                    >
                      Detalhes
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                count={doctors.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { "aria-label": "Itens por página" },
                  native: true,
                }}
                onChangePage={handleChangePage}
                onPageChange={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Main>
  );
}
